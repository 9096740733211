var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h1', [_vm._v("Parents")]), _vm._m(0), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('user-items-primary')], 1), _c('v-col', {
    staticClass: "grey",
    attrs: {
      "cols": "auto"
    }
  }, [_c('user-items-secondary')], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Styles")]), _c('h2', [_vm._v("Icon")]), _vm._m(1), _c('br'), _c('u-user-items-icon'), _c('br'), _c('h2', [_vm._v("Text")]), _vm._m(2), _c('br'), _c('u-user-items-text'), _c('br'), _c('h2', [_vm._v("Icon Text")]), _vm._m(3), _c('br'), _c('u-user-items-icon-text'), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Color")]), _vm._m(4), _c('br'), _c('div', {
    staticClass: "grey pa-20px"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-user-items-icon', {
    staticClass: "user-items--light"
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-user-items-text', {
    staticClass: "user-items--light"
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-user-items-icon-text', {
    staticClass: "user-items--light"
  })], 1)], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Boolean")]), _c('h2', [_vm._v("Icon")]), _vm._m(5), _vm._m(6), _c('br'), _c('h2', [_vm._v("Text")]), _vm._m(7), _vm._m(8)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("user-items-{parents}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-user-items-icon")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-user-items-text")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-user-items-icon-text")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("user-items--light")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("icon")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("<user-items icon></user-items>")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("text")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("<user-items text></user-items>")])]);

}]

export { render, staticRenderFns }