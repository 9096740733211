<template>
    <div class="v-rating-wrap">
        <v-rating v-bind="$attrs" half-increments class="rating-color">
            <template v-slot:item="props">
                <span v-if="props.isFilled" @click="props.click">
                    <u-icon variant="round" type="button">star</u-icon>
                </span>
                <span v-else-if="props.isHalfFilled" class="v-rating__half-filled" @click="props.click">
                    <u-icon variant="round" type="button" class="v-rating__filled">star</u-icon>
                    <u-icon variant="round" type="button"><span class="grey--text text--lighten-3">star</span></u-icon>
                </span>
                <span v-else @click="props.click">
                    <u-icon variant="round" type="button"><span class="grey--text text--lighten-3">star</span></u-icon>
                </span>
            </template>
        </v-rating>
        <span v-if="this.$slots['default']" class="v-rating-wrap__txt"><slot /></span>
    </div>
</template>

<script>
import UIcon from "@/components/publish/styles/icons/u-icon.vue";

export default {
    props: {},
    components: {
        UIcon,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
