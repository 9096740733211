<template>
    <div>
        <v-tabs v-model="tab" grow class="mb-30px">
            <v-tab>Footer Default</v-tab>
        </v-tabs>

        <v-window v-model="tab">
            <v-window-item><FootersDefault /></v-window-item>
        </v-window>
    </div>
</template>

<script>
import FootersDefault from "./footers-default.vue";

export default {
    props: {},
    components: {
        FootersDefault,
    },
    data() {
        return {
            tab: null,
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
