<template>
    <app-primary>
        <template #contentsHead>
            <sub-head-primary itemTitle="가이드" tabActive="예시페이지" bg="/images/test.jpg">
                <template #title>가이드</template>
            </sub-head-primary>
        </template>

        <page-section-primary titWrapClass="text-center" class="page-section--first">
            <template #tit> 섹션 타이틀 </template>

            <v-row align="center" justify="center">
                <v-col cols="auto">
                    <btn-primary to="/guide" class="v-btn--width-fixed">Guide</btn-primary>
                </v-col>
            </v-row>

            컨텐츠영역
        </page-section-primary>

        <page-section-secondary titWrapClass="text-center" class="grey lighten-5">
            <template #tit> 섹션 타이틀 </template>

            컨텐츠영역
        </page-section-secondary>

        <page-section-primary titWrapClass="text-center" class="page-section--last primary">
            <template #tit> 섹션 타이틀 </template>

            컨텐츠영역
        </page-section-primary>
    </app-primary>
</template>

<script>
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import PageSectionSecondary from "@/sets/parents/pages/page-section-secondary.vue";
import BtnPrimary from "@/components/publish/parents/buttons/btn-primary.vue";

export default {
    props: {},
    components: {
        AppPrimary,
        SubHeadPrimary,
        PageSectionPrimary,
        PageSectionSecondary,
        BtnPrimary,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
