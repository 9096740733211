<template>
    <div>
        <!-- Parents -->
        <h1>Parents</h1>
        <p><u>textarea-{parents}</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <textarea-primary v-model="forms.test" label="Textare Tertiary" class="v-input--density-compact" />
            </v-col>
            <v-col cols="4" md="2">
                <textarea-secondary v-model="forms.test" placeholder="Textare Secondary" class="v-input--density-default" />
            </v-col>
            <v-col cols="4" md="2">
                <textarea-tertiary v-model="forms.test" label="Textare Tertiary" />
            </v-col>
            <v-col cols="4" md="2">
                <textarea-quarternary v-model="forms.test" placeholder="Textare Quarternary" />
            </v-col>
        </v-row>
        <!-- // Parents -->

        <v-divider class="my-30px" />

        <!-- Styles -->
        <h1>Styles</h1>
        <!-- S: Underlined -->
        <h2>Underlined</h2>
        <p><u>u-textarea-underlined</u></p>
        <br />
        <v-row align="center">
            <v-col cols="4" md="2">
                <u-textarea-underlined label="Underlined Label"></u-textarea-underlined>
            </v-col>
            <v-col cols="4" md="2">
                <u-textarea-underlined placeholder="Underlined Placeholder"></u-textarea-underlined>
            </v-col>
        </v-row>
        <!-- E: Underlined -->
        <br />

        <!-- S: Outlined -->
        <h2>Outlined</h2>
        <p><u>u-textarea-outlined-{}</u></p>
        <br />
        <v-row align="center">
            <v-col cols="4" md="2">
                <u-textarea-outlined-tile label="Outlined Tile Label"></u-textarea-outlined-tile>
            </v-col>
            <v-col cols="4" md="2">
                <u-textarea-outlined-rounded label="Outlined Rounded Label"></u-textarea-outlined-rounded>
            </v-col>
            <v-col cols="4" md="2">
                <u-textarea-outlined-tile label="Outlined Tile Label Inner" class="v-text-field--label-inner"></u-textarea-outlined-tile>
            </v-col>
            <v-col cols="4" md="2">
                <u-textarea-outlined-rounded label="Outlined Rounded Label Inner" class="v-text-field--label-inner"></u-textarea-outlined-rounded>
            </v-col>
            <v-col cols="4" md="2">
                <u-textarea-outlined-tile placeholder="Outlined Tile Placeholder"></u-textarea-outlined-tile>
            </v-col>
            <v-col cols="4" md="2">
                <u-textarea-outlined-rounded placeholder="Outlined Rounded Placeholder"></u-textarea-outlined-rounded>
            </v-col>
        </v-row>
        <!-- E: Outlined -->
        <br />

        <!-- S: Filled -->
        <h2>Filled</h2>
        <p><u>u-textarea-filled-{}</u></p>
        <br />
        <v-row align="center">
            <v-col cols="4" md="2">
                <u-textarea-filled-tile label="Filled Tile Label"></u-textarea-filled-tile>
            </v-col>
            <v-col cols="4" md="2">
                <u-textarea-filled-rounded label="Filled Rounded Label"></u-textarea-filled-rounded>
            </v-col>
            <v-col cols="4" md="2">
                <u-textarea-filled-tile placeholder="Filled Tile Placeholder"></u-textarea-filled-tile>
            </v-col>
            <v-col cols="4" md="2">
                <u-textarea-filled-rounded placeholder="Filled Rounded Placeholder"></u-textarea-filled-rounded>
            </v-col>
        </v-row>
        <!-- E: Filled -->
        <br />

        <!-- S: Solo -->
        <h2>Solo</h2>
        <p><u>u-textarea-solo-{}</u></p>
        <br />
        <v-row align="center">
            <v-col cols="4" md="2">
                <u-textarea-solo-tile placeholder="Solo Tile Placeholder"></u-textarea-solo-tile>
            </v-col>
            <v-col cols="4" md="2">
                <u-textarea-solo-rounded placeholder="Solo Rounded Placeholder"></u-textarea-solo-rounded>
            </v-col>
        </v-row>
        <!-- E: Solo -->
        <!-- // Styles -->

        <v-divider class="my-30px" />

        <!-- Density -->
        <h1>Density</h1>
        <h3>{density} : compact, 기본-comfortable, default</h3>
        <p><u>class="v-input--density-{density}"</u></p>
        <br />
        <!-- S: Underlined -->
        <h2>Underlined</h2>
        <v-row align="center">
            <v-col cols="4" md="2">
                <v-textarea v-bind="{ ...attrs_input_common, ...$attrs }" label="Label" class="v-input--density-compact"></v-textarea>
            </v-col>
            <v-col cols="4" md="2">
                <v-textarea v-bind="{ ...attrs_input_common, ...$attrs }" label="Label"></v-textarea>
            </v-col>
            <v-col cols="4" md="2">
                <v-textarea v-bind="{ ...attrs_input_common, ...$attrs }" label="Label" class="v-input--density-default"></v-textarea>
            </v-col>
            <v-col cols="4" md="2">
                <v-textarea v-bind="{ ...attrs_input_common, ...$attrs }" placeholder="Placeholder" class="v-input--density-compact"></v-textarea>
            </v-col>
            <v-col cols="4" md="2">
                <v-textarea v-bind="{ ...attrs_input_common, ...$attrs }" placeholder="Placeholder"></v-textarea>
            </v-col>
            <v-col cols="4" md="2">
                <v-textarea v-bind="{ ...attrs_input_common, ...$attrs }" placeholder="Placeholder" class="v-input--density-default"></v-textarea>
            </v-col>
        </v-row>
        <!-- E: Underlined -->
        <br />

        <!-- S: Outlined -->
        <h2>Outlined</h2>
        <v-row align="center">
            <v-col cols="4" md="2">
                <v-textarea v-bind="{ ...attrs_input_common, ...$attrs }" outlined label="Label" class="v-input--density-compact"></v-textarea>
            </v-col>
            <v-col cols="4" md="2">
                <v-textarea v-bind="{ ...attrs_input_common, ...$attrs }" outlined label="Label"></v-textarea>
            </v-col>
            <v-col cols="4" md="2">
                <v-textarea v-bind="{ ...attrs_input_common, ...$attrs }" outlined label="Label" class="v-input--density-default"></v-textarea>
            </v-col>
            <v-col cols="4" md="2">
                <v-textarea v-bind="{ ...attrs_input_common, ...$attrs }" outlined placeholder="Placeholder" class="v-input--density-compact"></v-textarea>
            </v-col>
            <v-col cols="4" md="2">
                <v-textarea v-bind="{ ...attrs_input_common, ...$attrs }" outlined placeholder="Placeholder"></v-textarea>
            </v-col>
            <v-col cols="4" md="2">
                <v-textarea v-bind="{ ...attrs_input_common, ...$attrs }" outlined placeholder="Placeholder" class="v-input--density-default"></v-textarea>
            </v-col>
        </v-row>
        <!-- E: Outlined -->
        <br />

        <!-- S: Label Inner -->
        <h2>Label Inner</h2>
        <v-row align="center">
            <v-col cols="4" md="2">
                <v-textarea v-bind="{ ...attrs_input_common, ...$attrs }" outlined label="Label" class="v-input--density-compact v-text-field--label-inner"></v-textarea>
            </v-col>
            <v-col cols="4" md="2">
                <v-textarea v-bind="{ ...attrs_input_common, ...$attrs }" outlined label="Label" class="v-text-field--label-inner"></v-textarea>
            </v-col>
            <v-col cols="4" md="2">
                <v-textarea v-bind="{ ...attrs_input_common, ...$attrs }" outlined label="Label" class="v-input--density-default v-text-field--label-inner"></v-textarea>
            </v-col>
            <v-col cols="4" md="2">
                <v-textarea v-bind="{ ...attrs_input_common, ...$attrs }" outlined placeholder="Placeholder" class="v-input--density-compact v-text-field--label-inner"></v-textarea>
            </v-col>
            <v-col cols="4" md="2">
                <v-textarea v-bind="{ ...attrs_input_common, ...$attrs }" outlined placeholder="Placeholder" class="v-text-field--label-inner"></v-textarea>
            </v-col>
            <v-col cols="4" md="2">
                <v-textarea v-bind="{ ...attrs_input_common, ...$attrs }" outlined placeholder="Placeholder" class="v-input--density-default v-text-field--label-inner"></v-textarea>
            </v-col>
        </v-row>
        <!-- E: Label Inner -->
        <br />

        <!-- S: Filled -->
        <h2>Filled</h2>
        <v-row align="center">
            <v-col cols="4" md="2">
                <v-textarea v-bind="{ ...attrs_input_common, ...$attrs }" filled label="Label" class="v-input--density-compact"></v-textarea>
            </v-col>
            <v-col cols="4" md="2">
                <v-textarea v-bind="{ ...attrs_input_common, ...$attrs }" filled label="Label"></v-textarea>
            </v-col>
            <v-col cols="4" md="2">
                <v-textarea v-bind="{ ...attrs_input_common, ...$attrs }" filled label="Label" class="v-input--density-default"></v-textarea>
            </v-col>
            <v-col cols="4" md="2">
                <v-textarea v-bind="{ ...attrs_input_common, ...$attrs }" filled placeholder="Placeholder" class="v-input--density-compact"></v-textarea>
            </v-col>
            <v-col cols="4" md="2">
                <v-textarea v-bind="{ ...attrs_input_common, ...$attrs }" filled placeholder="Placeholder"></v-textarea>
            </v-col>
            <v-col cols="4" md="2">
                <v-textarea v-bind="{ ...attrs_input_common, ...$attrs }" filled placeholder="Placeholder" class="v-input--density-default"></v-textarea>
            </v-col>
        </v-row>
        <!-- E: Filled -->
        <br />

        <!-- S: Solo -->
        <h2>Solo</h2>
        <v-row align="center">
            <v-col cols="4" md="2">
                <v-textarea v-bind="{ ...attrs_input_common, ...$attrs }" solo placeholder="Placeholder" class="v-input--density-compact"></v-textarea>
            </v-col>
            <v-col cols="4" md="2">
                <v-textarea v-bind="{ ...attrs_input_common, ...$attrs }" solo placeholder="Placeholder"></v-textarea>
            </v-col>
            <v-col cols="4" md="2">
                <v-textarea v-bind="{ ...attrs_input_common, ...$attrs }" solo placeholder="Placeholder" class="v-input--density-default"></v-textarea>
            </v-col>
        </v-row>
        <!-- E: Solo -->
        <!-- // Density -->

        <v-divider class="my-30px" />

        <!-- Width -->
        <h1>Width</h1>
        <p><u>w-{breakpoint}-{size}</u></p>
        <p><u>v-input--width-fixed</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <v-textarea v-bind="{ ...attrs_input_common, ...$attrs }" label="Custom Width" class="min-w-140px min-w-md-200px"></v-textarea>
            </v-col>
            <v-col cols="12">
                <v-textarea v-bind="{ ...attrs_input_common, ...$attrs }" label="default block"></v-textarea>
            </v-col>
            <v-col cols="auto">
                <v-textarea v-bind="{ ...attrs_input_common, ...$attrs }" label="Width Fixed"  class="v-input--density-compact v-input--width-fixed"></v-textarea>
            </v-col>
            <v-col cols="auto">
                <v-textarea v-bind="{ ...attrs_input_common, ...$attrs }" label="Width Fixed" class="v-input--width-fixed"></v-textarea>
            </v-col>
            <v-col cols="auto">
                <v-textarea v-bind="{ ...attrs_input_common, ...$attrs }" label="Width Fixed"  class="v-input--density-default v-input--width-fixed"></v-textarea>
            </v-col>
        </v-row>
        <!-- // Width -->

        <v-divider class="my-30px" />

        <!-- State -->
        <h1>State</h1>
        <!-- S: Disabled -->
        <h2>Disabled</h2>
        <p><u>disabled</u></p>
        <br />
        <v-row align="center">
            <v-col cols="6" md="3">
                <v-textarea v-bind="{ ...attrs_input_common, ...$attrs }" disabled label="Label"></v-textarea>
            </v-col>
            <v-col cols="6" md="3">
                <v-textarea v-bind="{ ...attrs_input_common, ...$attrs }" disabled placeholder="placeholder"></v-textarea>
            </v-col>
        </v-row>
        <!-- E: Disabled -->
        <br />

        <!-- S: Readonly -->
        <h2>Readonly</h2>
        <p><u>readonly</u></p>
        <br />
        <v-row align="center">
            <v-col cols="6" md="3">
                <v-textarea v-bind="{ ...attrs_input_common, ...$attrs }" readonly label="Label" value="readonly"></v-textarea>
            </v-col>
            <v-col cols="6" md="3">
                <v-textarea v-bind="{ ...attrs_input_common, ...$attrs }" readonly placeholder="placeholder"></v-textarea>
            </v-col>
        </v-row>
        <!-- E: Readonly -->
        <!-- // State -->

        <v-divider class="my-30px" />

        <!-- Variant -->
        <h1>Variant</h1>
        <h3>{variant} : (기본-underlined), outlined, label-inner(outlined), filled, solo</h3>
        <p>v-text-field--label-inner은 클래스로 입력해주세요. label-inner은 outlined속성과 같이 사용합니다.</p>
        <p>solo는 label 스타일이 없습니다.</p>
        <p><u>{variant}</u></p>
        <p><u>class="v-text-field--label-inner"</u></p>
        <br />
        <v-row align="center">
            <v-col cols="6" md="3">
                <v-textarea v-bind="{ ...attrs_input_common, ...$attrs }" label="Label"></v-textarea>
            </v-col>
            <v-col cols="6" md="3">
                <v-textarea v-bind="{ ...attrs_input_common, ...$attrs }" placeholder="Placeholder"></v-textarea>
            </v-col>
            <v-col cols="6" md="3">
                <v-textarea v-bind="{ ...attrs_input_common, ...$attrs }" outlined label="Label"></v-textarea>
            </v-col>
            <v-col cols="6" md="3">
                <v-textarea v-bind="{ ...attrs_input_common, ...$attrs }" outlined placeholder="Placeholder"></v-textarea>
            </v-col>
            <v-col cols="6" md="3">
                <v-textarea v-bind="{ ...attrs_input_common, ...$attrs }" outlined label="Label Inner" class="v-text-field--label-inner"></v-textarea>
            </v-col>
            <v-col cols="6" md="3">
                <v-textarea v-bind="{ ...attrs_input_common, ...$attrs }" outlined placeholder="Placeholder" class="v-text-field--label-inner"></v-textarea>
            </v-col>
            <v-col cols="6" md="3">
                <v-textarea v-bind="{ ...attrs_input_common, ...$attrs }" filled label="Label"></v-textarea>
            </v-col>
            <v-col cols="6" md="3">
                <v-textarea v-bind="{ ...attrs_input_common, ...$attrs }" filled placeholder="Placeholder"></v-textarea>
            </v-col>
            <v-col cols="6" md="3">
                <v-textarea v-bind="{ ...attrs_input_common, ...$attrs }" solo placeholder="Placeholder"></v-textarea>
            </v-col>
        </v-row>
        <!-- // Variant -->

        <v-divider class="my-30px" />

        <!-- Rounded -->
        <h1>Rounded</h1>
        <h3>{rounded} : (기본), 0</h3>
        <p><u>class="rounded-{rounded}"</u></p>
        <br />
        <v-row align="center">
            <v-col cols="6" md="2">
                <v-textarea v-bind="{ ...attrs_input_common, ...$attrs }" outlined label="Label"></v-textarea>
            </v-col>
            <v-col cols="6" md="2">
                <v-textarea v-bind="{ ...attrs_input_common, ...$attrs }" outlined label="Label" class="rounded-0"></v-textarea>
            </v-col>
            <v-col cols="6" md="2">
                <v-textarea v-bind="{ ...attrs_input_common, ...$attrs }" filled label="Default"></v-textarea>
            </v-col>
            <v-col cols="6" md="2">
                <v-textarea v-bind="{ ...attrs_input_common, ...$attrs }" filled label="Tile" class="rounded-0"></v-textarea>
            </v-col>
            <v-col cols="6" md="2">
                <v-textarea v-bind="{ ...attrs_input_common, ...$attrs }" outlined label="Default Label Inner" class="v-text-field--label-inner"></v-textarea>
            </v-col>
            <v-col cols="6" md="2">
                <v-textarea v-bind="{ ...attrs_input_common, ...$attrs }" outlined label="Tile Label Inner" class="rounded-0 v-text-field--label-inner"></v-textarea>
            </v-col>
        </v-row>
        <!-- // Rounded -->

        <v-divider class="my-30px" />

        <!-- Icon -->
        <h1>Icon</h1>
        <h3>{direction} : prepend, prepend-inner, append, append-outer, clear-icon</h3>
        <p>vue에서 렌더링 하는데 소모하는 시간이 있어 되도록이면 icon=""을 사용하지 않고 template v-slot으로 아이콘을 추가합니다.</p>
        <p>이벤트가 있는 아이콘은 template v-slot으로 사용하면 이벤트가 작동되지 않아 {direction}=""형태로 수정합니다. 이때 아이콘은 mdi아이콘만 사용할 수 있습니다.</p>
        <p><u>#{direction}</u></p>
        <p><u>{direction}=""</u></p>
        <br />
        <v-row align="center">
            <v-col cols="6" md="3">
                <v-textarea v-bind="{ ...attrs_input_common, ...$attrs }" outlined placeholder="Prepend Icon">
                    <template #prepend><u-icon>attach_file</u-icon></template>
                </v-textarea>
            </v-col>
            <v-col cols="6" md="3">
                <v-textarea v-bind="{ ...attrs_input_common, ...$attrs }" outlined placeholder="Prepend Inner Icon">
                    <template #prepend-inner><u-icon>attach_file</u-icon></template>
                </v-textarea>
            </v-col>
            <v-col cols="6" md="3">
                <v-textarea v-bind="{ ...attrs_input_common, ...$attrs }" outlined placeholder="Append Outer Icon">
                    <template #append-outer><u-icon>attach_file</u-icon></template>
                </v-textarea>
            </v-col>
            <v-col cols="6" md="3">
                <v-textarea v-bind="{ ...attrs_input_common, ...$attrs }" outlined placeholder="Append Icon">
                    <template #append><u-icon>attach_file</u-icon></template>
                </v-textarea>
            </v-col>
            <v-col cols="6" md="3">
                <v-textarea v-bind="{ ...attrs_input_common, ...$attrs }" outlined clearable placeholder="Prepend Icon Clearable">
                    <template #prepend><u-icon>attach_file</u-icon></template>
                </v-textarea>
            </v-col>
            <v-col cols="6" md="3">
                <v-textarea v-bind="{ ...attrs_input_common, ...$attrs }" outlined clearable placeholder="Prepend Inner Icon Clearable">
                    <template #prepend-inner><u-icon>attach_file</u-icon></template>
                </v-textarea>
            </v-col>
            <v-col cols="6" md="3">
                <v-textarea v-bind="{ ...attrs_input_common, ...$attrs }" outlined clearable placeholder="Append Outer Icon Clearable">
                    <template #append-outer><u-icon>attach_file</u-icon></template>
                </v-textarea>
            </v-col>
            <v-col cols="6" md="3">
                <v-textarea v-bind="{ ...attrs_input_common, ...$attrs }" outlined clearable placeholder="Append Icon Clearable">
                    <template #append><u-icon>attach_file</u-icon></template>
                </v-textarea>
            </v-col>
            <v-col cols="6" md="3">
                <v-textarea v-bind="{ ...attrs_input_common, ...$attrs }" outlined clearable clear-icon="mdi-delete" placeholder="Prepend Append Clearable Icon">
                    <template #prepend><u-icon>attach_file</u-icon></template>
                    <template #append-outer><u-icon>check</u-icon></template>
                </v-textarea>
            </v-col>
            <v-col cols="6" md="3">
                <v-textarea v-bind="{ ...attrs_input_common, ...$attrs }" outlined clearable clear-icon="mdi-delete" placeholder="Prepend Append Clearable Icon">
                    <template #prepend-inner><u-icon>attach_file</u-icon></template>
                    <template #append><u-icon>check</u-icon></template>
                </v-textarea>
            </v-col>
        </v-row>
        <!-- // Icon -->

        <v-divider class="my-30px" />

        <!-- Supporting Text -->
        <h1>Supporting Text</h1>
        <!-- S: Hide details -->
        <h2>Hide details</h2>
        <h3>{hide-details} : auto, hide</h3>
        <p><u>hide-details="{hide-details}"</u></p>
        <br />
        <v-row align="center">
            <v-col cols="6" md="3">
                <v-textarea v-bind="{ ...attrs_input_common, ...$attrs }" hint="Hint" placeholder="Hide Details Auto"></v-textarea>
            </v-col>
            <v-col cols="6" md="3">
                <v-textarea v-bind="{ ...attrs_input_common, ...$attrs }" hide-details="hide" hint="Hint" placeholder="Hide Details Hide"></v-textarea>
            </v-col>
        </v-row>
        <!-- E: Hide details -->
        <br />

        <!-- S: Hint, Messages -->
        <h2>Hint, Messages</h2>
        <p><u>hint=""</u></p>
        <p><u>messages=""</u></p>
        <br />
        <v-row align="center">
            <v-col cols="6" md="3">
                <v-textarea v-bind="{ ...attrs_input_common, ...$attrs }" hint="Hint" placeholder="Hint"></v-textarea>
            </v-col>
            <v-col cols="6" md="3">
                <v-textarea v-bind="{ ...attrs_input_common, ...$attrs }" messages="Messages" placeholder="Messages"></v-textarea>
            </v-col>
        </v-row>
        <!-- E: Hint, Messages -->
        <br />

        <!-- S: Persistence -->
        <h2>Persistence</h2>
        <h3>{persistent} : hint, counter, clear, placeholder</h3>
        <p><u>persistent-{persistent}</u></p>
        <p>persistent-hint - 항상 힌트가 보입니다.</p>
        <p>persistent-counter - 항상 입력 문자 길이 요소를 표시합니다.</p>
        <p>persistent-clear - 값이 있을 때 입력 지우기 아이콘을 항상 표시합니다.</p>
        <p>persistent-placeholder - 항상 placeholder를 표시하고 label이 자동으로 올라갑니다.</p>
        <br />
        <v-row align="center">
            <v-col cols="6" md="3">
                <v-textarea v-bind="{ ...attrs_input_common, ...$attrs }" persistent-hint hint="Hint" placeholder="persistent-hint"></v-textarea>
            </v-col>
            <v-col cols="6" md="3">
                <v-textarea v-bind="{ ...attrs_input_common, ...$attrs }" counter persistent-counter placeholder="persistent-counter"></v-textarea>
            </v-col>
            <v-col cols="6" md="3">
                <v-textarea v-bind="{ ...attrs_input_common, ...$attrs }" clearable persistent-clear placeholder="persistent-clear"></v-textarea>
            </v-col>
            <v-col cols="6" md="3">
                <v-textarea v-bind="{ ...attrs_input_common, ...$attrs }" persistent-placeholder label="Label" placeholder="placeholder-placeholder"></v-textarea>
            </v-col>
        </v-row>
        <!-- E: Persistence -->
        <!-- // Supporting Text -->
    </div>
</template>

<script>
import { attrs_input_common } from "@/assets/variables";

import TextareaPrimary from "@/components/publish/parents/forms/textarea-primary.vue";
import TextareaSecondary from "@/components/publish/parents/forms/textarea-secondary.vue";
import TextareaTertiary from "@/components/publish/parents/forms/textarea-tertiary.vue";
import TextareaQuarternary from "@/components/publish/parents/forms/textarea-quarternary.vue";

import UTextareaOutlinedTile from "@/components/publish/styles/forms/u-textarea-outlined-tile.vue";
import UTextareaOutlinedRounded from "@/components/publish/styles/forms/u-textarea-outlined-rounded.vue";
import UTextareaFilledTile from "@/components/publish/styles/forms/u-textarea-filled-tile.vue";
import UTextareaFilledRounded from "@/components/publish/styles/forms/u-textarea-filled-rounded.vue";
import UTextareaSoloTile from "@/components/publish/styles/forms/u-textarea-solo-tile.vue";
import UTextareaSoloRounded from "@/components/publish/styles/forms/u-textarea-solo-rounded.vue";
import UTextareaUnderlined from "@/components/publish/styles/forms/u-textarea-underlined.vue";

import UIcon from "@/components/publish/styles/icons/u-icon.vue";

export default {
    props: {},
    components: {
        TextareaPrimary,
        TextareaSecondary,
        TextareaTertiary,
        TextareaQuarternary,

        UTextareaOutlinedTile,
        UTextareaOutlinedRounded,
        UTextareaFilledTile,
        UTextareaFilledRounded,
        UTextareaSoloTile,
        UTextareaSoloRounded,
        UTextareaUnderlined,

        UIcon,
    },
    data() {
        return {
            attrs_input_common,

            forms: {
                test: null,
            },
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
