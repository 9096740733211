<template>
    <swiper-pagination v-bind="$attrs" class="swiper-pagination--fraction-divider"><slot /></swiper-pagination>
</template>

<script>
import SwiperPagination from "@/components/publish/styles/swipers/swiper-pagination.vue";

export default {
    props: {},
    components: { SwiperPagination },
    data() {
        return {
            // 스와이퍼 옵션
            // swiperOptions: {
            //     pagination: {
            //         el: ".swiper-pagination",
            //         type: "custom",
            //         renderCustom: function (swiper, current, total) {
            //             function numberAppend(n) {
            //                 return n < 10 ? "0" + n.toString() : n.toString();
            //             }
            //             return '<span class="swiper-pagination-current">' + numberAppend(current) + '</span><span class="swiper-pagination__decoration"></span><span class="swiper-pagination-total">' + numberAppend(total) + "</span>";
            //         },
            //     },
            // },
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.swiper-pagination--fraction-divider {
    font-size: 0;
    line-height: 1;
    color: rgb(var(--v-theme-grey-darken-4));
    :deep() {
        .swiper-pagination {
            &-current,
            &-total {
                font-size: 1.8rem;
                font-weight: 700;
                color: currentColor;
            }
            &-total,
            &__decoration {
                opacity: 0.2;
            }
            &-current {
                padding-right: 12px;
            }
            &-total {
                padding-left: 12px;
            }
            &__decoration {
                display: block;
                width: 1px;
                height: 10px;
                background-color: currentColor;
            }
        }
    }

    &.swiper-pagination--light {
        :deep() {
            .swiper-pagination {
                &-total,
                &__decoration {
                    opacity: 0.4;
                }
            }
        }
    }
}
</style>
