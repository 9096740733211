var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h1', [_vm._v("Parents")]), _vm._m(0), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('scrolldown-primary')], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('scrolldown-secondary')], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('scrolldown-tertiary')], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('scrolldown-quarternary')], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Styles")]), _c('h2', [_vm._v("Double Arrow")]), _vm._m(1), _vm._m(2), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-scrolldown-double-arrow')], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-scrolldown-double-arrow', {
    attrs: {
      "reverse": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "pa-20px grey darken-4"
  }, [_c('u-scrolldown-double-arrow', {
    staticClass: "scrolldown--light"
  })], 1)]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "pa-20px grey darken-4"
  }, [_c('u-scrolldown-double-arrow', {
    staticClass: "scrolldown--light",
    attrs: {
      "reverse": ""
    }
  })], 1)])], 1), _c('br'), _c('h2', [_vm._v("Arrow")]), _vm._m(3), _vm._m(4), _vm._m(5), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-scrolldown-arrow')], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-scrolldown-arrow', {
    attrs: {
      "reverse": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "pa-20px grey darken-4"
  }, [_c('u-scrolldown-arrow', {
    staticClass: "scrolldown--light"
  })], 1)]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "pa-20px grey darken-4"
  }, [_c('u-scrolldown-arrow', {
    staticClass: "scrolldown--light",
    attrs: {
      "reverse": ""
    }
  })], 1)]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-scrolldown-arrow', {
    scopedSlots: _vm._u([{
      key: "arrow",
      fn: function () {
        return [_c('icon-arrow-rounded', {
          staticClass: "grey--text",
          attrs: {
            "size": "small",
            "direction": "down"
          }
        })];
      },
      proxy: true
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-scrolldown-arrow', {
    attrs: {
      "reverse": ""
    },
    scopedSlots: _vm._u([{
      key: "arrow",
      fn: function () {
        return [_c('icon-arrow-rounded', {
          staticClass: "grey--text",
          attrs: {
            "size": "small",
            "direction": "down"
          }
        })];
      },
      proxy: true
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "pa-20px grey darken-4"
  }, [_c('u-scrolldown-arrow', {
    staticClass: "scrolldown--light",
    scopedSlots: _vm._u([{
      key: "arrow",
      fn: function () {
        return [_c('icon-arrow-rounded', {
          attrs: {
            "size": "small",
            "direction": "down"
          }
        })];
      },
      proxy: true
    }])
  })], 1)]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "pa-20px grey darken-4"
  }, [_c('u-scrolldown-arrow', {
    staticClass: "scrolldown--light",
    attrs: {
      "reverse": ""
    },
    scopedSlots: _vm._u([{
      key: "arrow",
      fn: function () {
        return [_c('icon-arrow-rounded', {
          attrs: {
            "size": "small",
            "direction": "down"
          }
        })];
      },
      proxy: true
    }])
  })], 1)])], 1), _c('br'), _c('h2', [_vm._v("Filled")]), _vm._m(6), _vm._m(7), _vm._m(8), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-scrolldown-filled')], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "pa-20px grey darken-4"
  }, [_c('u-scrolldown-filled', {
    staticClass: "scrolldown--light"
  })], 1)]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-scrolldown-filled', {
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "pa-20px grey darken-4"
  }, [_c('u-scrolldown-filled', {
    staticClass: "scrolldown--light",
    attrs: {
      "vertical": ""
    }
  })], 1)]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-scrolldown-filled', {
    attrs: {
      "verticalWide": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "pa-20px grey darken-4"
  }, [_c('u-scrolldown-filled', {
    staticClass: "scrolldown--light",
    attrs: {
      "verticalWide": ""
    }
  })], 1)])], 1), _c('br'), _c('h2', [_vm._v("Mouse")]), _vm._m(9), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-scrolldown-mouse')], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "pa-20px grey darken-4"
  }, [_c('u-scrolldown-mouse', {
    staticClass: "scrolldown--light"
  })], 1)]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-scrolldown-mouse', {
    attrs: {
      "arrow": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "pa-20px grey darken-4"
  }, [_c('u-scrolldown-mouse', {
    staticClass: "scrolldown--light",
    attrs: {
      "arrow": ""
    }
  })], 1)])], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Color")]), _vm._m(10), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "pa-20px grey darken-4"
  }, [_c('u-scrolldown-arrow', {
    staticClass: "scrolldown--light"
  })], 1)])], 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("scrolldown-{parents}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-scrolldown-double-arrow")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("reverse")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-scrolldown-arrow")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("reverse")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("#arrow")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-scrolldown-filled")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("vertical")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("verticalWide")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-scrolldown-mouse")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("scrolldown--light")])]);

}]

export { render, staticRenderFns }