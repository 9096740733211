<template>
    <div><!-- Parents -->
        <h1>Parents</h1>
        <p><u>btn-icon-{parents}</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <btn-icon-primary color="secondary"><u-icon>favorite</u-icon></btn-icon-primary>
            </v-col>
            <v-col cols="auto">
                <btn-icon-secondary x-large><u-icon>favorite</u-icon></btn-icon-secondary>
            </v-col>
            <v-col cols="auto">
                <btn-icon-tertiary color="primary"><u-icon>favorite</u-icon></btn-icon-tertiary>
            </v-col>
            <v-col cols="auto">
                <btn-icon-quarternary x-small><u-icon>favorite</u-icon></btn-icon-quarternary>
            </v-col>
        </v-row>
        <!-- // Parents -->

        <v-divider class="my-30px" />

        <!-- Styles -->
        <h1>Styles</h1>
        <h2>Flat(Fab)</h2>
        <p><u>u-btn-icon-flat-{}</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <u-btn-icon-flat-tile><u-icon>favorite</u-icon></u-btn-icon-flat-tile>
            </v-col>
            <v-col cols="auto">
                <u-btn-icon-flat-rounded><u-icon>favorite</u-icon></u-btn-icon-flat-rounded>
            </v-col>
            <v-col cols="auto">
                <u-btn-icon-flat-circle><u-icon>favorite</u-icon></u-btn-icon-flat-circle>
            </v-col>
        </v-row>
        <!-- E: Flat -->
        <br />

        <!-- S: Outlined -->
        <h2>Outlined</h2>
        <p><u>u-btn-icon-outlined-{}</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <u-btn-icon-outlined-tile><u-icon>favorite</u-icon></u-btn-icon-outlined-tile>
            </v-col>
            <v-col cols="auto">
                <u-btn-icon-outlined-rounded><u-icon>favorite</u-icon></u-btn-icon-outlined-rounded>
            </v-col>
            <v-col cols="auto">
                <u-btn-icon-outlined-circle><u-icon>favorite</u-icon></u-btn-icon-outlined-circle>
            </v-col>
        </v-row>
        <!-- E: Outlined -->
        <!-- // Styles -->

        <v-divider class="my-30px" />

        <!-- Variant -->
        <h1>Variant</h1>
        <h3>{variant} : (기본-icon), fab, elevation, outlined, text, text-fit, text-underline</h3>
        <p><u>{variant}</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <v-btn icon color="primary"><u-icon>favorite</u-icon></v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn fab color="primary"><u-icon>favorite</u-icon></v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn icon elevation="10" color="primary"><u-icon>favorite</u-icon></v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn icon outlined color="primary"><u-icon>favorite</u-icon></v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn icon text color="primary"><u-icon>favorite</u-icon></v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn icon text color="primary" class="v-btn--text-fit"><u-icon>favorite</u-icon></v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn icon text color="primary" class="v-btn--text-underline"><u-icon>favorite</u-icon></v-btn>
            </v-col>
        </v-row>
        <!-- // Variant -->
    </div>
</template>

<script>
import BtnIconPrimary from "@/components/publish/parents/buttons/btn-icon-primary.vue";
import BtnIconSecondary from "@/components/publish/parents/buttons/btn-icon-secondary.vue";
import BtnIconTertiary from "@/components/publish/parents/buttons/btn-icon-tertiary.vue";
import BtnIconQuarternary from "@/components/publish/parents/buttons/btn-icon-quarternary.vue";

import UBtnIconFlatTile from "@/components/publish/styles/buttons/u-btn-icon-flat-tile.vue";
import UBtnIconFlatRounded from "@/components/publish/styles/buttons/u-btn-icon-flat-rounded.vue";
import UBtnIconFlatCircle from "@/components/publish/styles/buttons/u-btn-icon-flat-circle.vue";
import UBtnIconOutlinedTile from "@/components/publish/styles/buttons/u-btn-icon-outlined-tile.vue";
import UBtnIconOutlinedRounded from "@/components/publish/styles/buttons/u-btn-icon-outlined-rounded.vue";
import UBtnIconOutlinedCircle from "@/components/publish/styles/buttons/u-btn-icon-outlined-circle.vue";

import UIcon from "@/components/publish/styles/icons/u-icon.vue";

export default {
    props: {},
    components: {
        BtnIconPrimary,
        BtnIconSecondary,
        BtnIconTertiary,
        BtnIconQuarternary,

        UBtnIconFlatTile,
        UBtnIconFlatRounded,
        UBtnIconFlatCircle,
        UBtnIconOutlinedTile,
        UBtnIconOutlinedRounded,
        UBtnIconOutlinedCircle,

        UIcon,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
