var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h1', [_vm._v("Parents")]), _vm._m(0), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('dialog-primary', {
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "color": "primary"
          }
        }, 'v-btn', attrs, false), on), [_vm._v("Dialog Primary")])];
      }
    }, {
      key: "tit",
      fn: function () {
        return [_vm._v("제목을입력해주세요")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.dialogPrimary,
      callback: function ($$v) {
        _vm.dialogPrimary = $$v;
      },
      expression: "dialogPrimary"
    }
  }, [_vm._v(" 내용을입력해주세요 ")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('dialog-secondary', {
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref2) {
        var attrs = _ref2.attrs,
          on = _ref2.on;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "color": "primary"
          }
        }, 'v-btn', attrs, false), on), [_vm._v("Dialog Secondary")])];
      }
    }, {
      key: "tit",
      fn: function () {
        return [_vm._v("제목을입력해주세요")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.dialogSecondary,
      callback: function ($$v) {
        _vm.dialogSecondary = $$v;
      },
      expression: "dialogSecondary"
    }
  }, [_vm._v(" 내용을입력해주세요 ")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('dialog-tertiary', {
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref3) {
        var attrs = _ref3.attrs,
          on = _ref3.on;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "color": "primary"
          }
        }, 'v-btn', attrs, false), on), [_vm._v("Dialog Tertiary")])];
      }
    }, {
      key: "tit",
      fn: function () {
        return [_vm._v("제목을입력해주세요")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.dialogTertiary,
      callback: function ($$v) {
        _vm.dialogTertiary = $$v;
      },
      expression: "dialogTertiary"
    }
  }, [_vm._v(" 내용을입력해주세요 ")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('dialog-quarternary', {
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref4) {
        var attrs = _ref4.attrs,
          on = _ref4.on;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "color": "primary"
          }
        }, 'v-btn', attrs, false), on), [_vm._v("Dialog Quarternary")])];
      }
    }, {
      key: "tit",
      fn: function () {
        return [_vm._v("제목을입력해주세요")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.dialogQuarternary,
      callback: function ($$v) {
        _vm.dialogQuarternary = $$v;
      },
      expression: "dialogQuarternary"
    }
  }, [_vm._v(" 내용을입력해주세요 ")])], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Styles")]), _c('h2', [_vm._v("Flat")]), _vm._m(1), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-dialog-flat-tile', {
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref5) {
        var attrs = _ref5.attrs,
          on = _ref5.on;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "color": "primary"
          }
        }, 'v-btn', attrs, false), on), [_vm._v("Open Dialog Tile")])];
      }
    }, {
      key: "tit",
      fn: function () {
        return [_vm._v("제목을입력해주세요")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.dialog3,
      callback: function ($$v) {
        _vm.dialog3 = $$v;
      },
      expression: "dialog3"
    }
  }, [_vm._v(" 내용을입력해주세요 ")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-dialog-flat-rounded', {
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref6) {
        var attrs = _ref6.attrs,
          on = _ref6.on;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "color": "primary"
          }
        }, 'v-btn', attrs, false), on), [_vm._v("Open Dialog Rounded")])];
      }
    }, {
      key: "tit",
      fn: function () {
        return [_vm._v("제목을입력해주세요")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.dialog4,
      callback: function ($$v) {
        _vm.dialog4 = $$v;
      },
      expression: "dialog4"
    }
  }, [_vm._v(" 내용을입력해주세요 ")])], 1)], 1), _c('br'), _c('h2', [_vm._v("Color Bg")]), _vm._m(2), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-dialog-color-bg-tile', {
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref7) {
        var attrs = _ref7.attrs,
          on = _ref7.on;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "color": "primary"
          }
        }, 'v-btn', attrs, false), on), [_vm._v("Open Dialog Tile")])];
      }
    }, {
      key: "tit",
      fn: function () {
        return [_vm._v("제목을입력해주세요")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.dialog5,
      callback: function ($$v) {
        _vm.dialog5 = $$v;
      },
      expression: "dialog5"
    }
  }, [_vm._v(" 내용을입력해주세요 ")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-dialog-color-bg-rounded', {
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref8) {
        var attrs = _ref8.attrs,
          on = _ref8.on;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "color": "primary"
          }
        }, 'v-btn', attrs, false), on), [_vm._v("Open Dialog Rounded")])];
      }
    }, {
      key: "tit",
      fn: function () {
        return [_vm._v("제목을입력해주세요")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.dialog6,
      callback: function ($$v) {
        _vm.dialog6 = $$v;
      },
      expression: "dialog6"
    }
  }, [_vm._v(" 내용을입력해주세요 ")])], 1)], 1), _c('br'), _c('h2', [_vm._v("Underline")]), _vm._m(3), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-dialog-underline-tile', {
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref9) {
        var attrs = _ref9.attrs,
          on = _ref9.on;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "color": "primary"
          }
        }, 'v-btn', attrs, false), on), [_vm._v("Open Dialog Tile")])];
      }
    }, {
      key: "tit",
      fn: function () {
        return [_vm._v("제목을입력해주세요")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.dialog7,
      callback: function ($$v) {
        _vm.dialog7 = $$v;
      },
      expression: "dialog7"
    }
  }, [_vm._v(" 내용을입력해주세요 ")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-dialog-underline-rounded', {
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref10) {
        var attrs = _ref10.attrs,
          on = _ref10.on;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "color": "primary"
          }
        }, 'v-btn', attrs, false), on), [_vm._v("Open Dialog Rounded")])];
      }
    }, {
      key: "tit",
      fn: function () {
        return [_vm._v("제목을입력해주세요")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.dialog8,
      callback: function ($$v) {
        _vm.dialog8 = $$v;
      },
      expression: "dialog8"
    }
  }, [_vm._v(" 내용을입력해주세요 ")])], 1)], 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("dialog-{parents}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-dialog-flat-{}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-dialog-color-bg-{}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-dialog-underline-{}")])]);

}]

export { render, staticRenderFns }