var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-pagination-outlined-rounded', _vm._b({}, 'u-pagination-outlined-rounded', _vm.$attrs, false));

}
var staticRenderFns = []

export { render, staticRenderFns }