<template>
    <div>
        <!-- Parents -->
        <h1>Parents</h1>
        <p><u>logo-{parents}</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <logo-primary to="/guide" title="가이드">LOGO</logo-primary>
            </v-col>
            <v-col cols="auto">
                <logo-secondary>LOGO</logo-secondary>
            </v-col>
            <v-col cols="auto" class="grey">
                <logo-tertiary>LOGO</logo-tertiary>
            </v-col>
            <v-col cols="auto">
                <logo-quarternary>LOGO</logo-quarternary>
            </v-col>
        </v-row>
        <!-- // Parents -->

        <v-divider class="my-30px" />

        <!-- Color -->
        <h1>Color</h1>
        <p><u>logo--{light|dark}</u></p>
        <br />
        <v-row>
            <v-col cols="auto" class="grey">
                <logo class="logo--light"></logo>
            </v-col>
            <v-col cols="auto">
                <logo class="logo--dark"></logo>
            </v-col>
        </v-row>
        <!-- // Color -->
    </div>
</template>

<script>
import LogoPrimary from "@/components/publish/parents/headers/logo-primary.vue";
import LogoSecondary from "@/components/publish/parents/headers/logo-secondary.vue";
import LogoTertiary from "@/components/publish/parents/headers/logo-tertiary.vue";
import LogoQuarternary from "@/components/publish/parents/headers/logo-quarternary.vue";

import Logo from "@/components/publish/styles/headers/logo.vue";

export default {
    props: {},
    components: {
        LogoPrimary,
        LogoSecondary,
        LogoTertiary,
        LogoQuarternary,

        Logo,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
