<template>
    <v-divider v-bind="$attrs" vertical class="h-20px secondary"></v-divider>
</template>

<script>
export default {
    props: {},
    components: {},
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
