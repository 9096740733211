<template>
    <u-pagination-text-underline v-bind="$attrs"></u-pagination-text-underline>
</template>

<script>
import UPaginationTextUnderline from "@/components/publish/styles/paginations/u-pagination-text-underline.vue";

export default {
    props: {},
    components: {
        UPaginationTextUnderline,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
