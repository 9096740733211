<template>
    <div>
        <!-- Parents -->
        <h1>Parents</h1>
        <p><u>header-search-{parents}</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <header-search-primary class="primary--text" />
            </v-col>
            <v-col cols="auto" class="grey">
                <header-search-secondary />
            </v-col>
        </v-row>
        <!-- // Parents -->

        <v-divider class="my-30px" />

        <!-- Styles -->
        <h1>Styles</h1>
        <!-- S: Input -->
        <h2>Input</h2>
        <p><u>u-header-search-outlined-{pill|tile}</u></p>
        <p><u>u-header-search-underlined</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <u-header-search-outlined-pill></u-header-search-outlined-pill>
            </v-col>
            <v-col cols="auto">
                <u-header-search-outlined-tile></u-header-search-outlined-tile>
            </v-col>
            <v-col cols="auto">
                <u-header-search-underlined></u-header-search-underlined>
            </v-col>
        </v-row>
        <!-- E: Input -->
        <br />

        <!-- S: Button -->
        <h2>Button</h2>
        <p><u>u-header-search-btn</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <u-header-search-btn></u-header-search-btn>
            </v-col>
        </v-row>
        <!-- E: Button -->
        <!-- // Styles -->

        <v-divider class="my-30px" />

        <!-- Color -->
        <h1>Color</h1>
        <p><u>header-search--light</u></p>
        <br />
        <div class="grey pa-20px">
            <v-row align="center">
                <v-col cols="auto">
                    <u-header-search-outlined-pill class="header-search--light"></u-header-search-outlined-pill>
                </v-col>
                <v-col cols="auto">
                    <u-header-search-outlined-tile class="header-search--light"></u-header-search-outlined-tile>
                </v-col>
                <v-col cols="auto">
                    <u-header-search-underlined class="header-search--light"></u-header-search-underlined>
                </v-col>
                <v-col cols="auto" class="grey">
                    <u-header-search-btn class="header-search--light"></u-header-search-btn>
                </v-col>
            </v-row>
        </div>
        <!-- // Color -->

        <v-divider class="my-30px" />

        <!-- Type -->
        <h1>Type</h1>
        <!-- S: Input -->
        <h2>Input</h2>
        <p><u>&lt;header-search&gt;&lt;/header-search&gt;</u></p>
        <!-- E: Input -->
        <br />

        <!-- S: Button -->
        <h2>Button</h2>
        <p><u>type="button"</u></p>
        <p><u>&lt;header-search type="button"&gt;&lt;/header-search&gt;</u></p>
        <!-- E: Button -->
        <!-- // Type -->
    </div>
</template>

<script>
import HeaderSearchPrimary from "@/components/publish/parents/headers/header-search-primary.vue";
import HeaderSearchSecondary from "@/components/publish/parents/headers/header-search-secondary.vue";

import UHeaderSearchOutlinedPill from "@/components/publish/styles/headers/u-header-search-outlined-pill.vue";
import UHeaderSearchOutlinedTile from "@/components/publish/styles/headers/u-header-search-outlined-tile.vue";
import UHeaderSearchUnderlined from "@/components/publish/styles/headers/u-header-search-underlined.vue";
import UHeaderSearchBtn from "@/components/publish/styles/headers/u-header-search-btn.vue";

import UIcon from "@/components/publish/styles/icons/u-icon.vue";

export default {
    props: {},
    components: {
        HeaderSearchPrimary,
        HeaderSearchSecondary,

        UHeaderSearchOutlinedPill,
        UHeaderSearchOutlinedTile,
        UHeaderSearchUnderlined,
        UHeaderSearchBtn,

        UIcon,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
