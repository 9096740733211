var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-scrolldown-mouse', _vm._b({
    attrs: {
      "arrow": ""
    },
    scopedSlots: _vm._u([{
      key: "arrow",
      fn: function () {
        return [_c('icon-arrow-rounded', {
          attrs: {
            "size": "small",
            "direction": "down"
          }
        })];
      },
      proxy: true
    }])
  }, 'u-scrolldown-mouse', _vm.$attrs, false));

}
var staticRenderFns = []

export { render, staticRenderFns }