var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "v-rating-wrap"
  }, [_c('v-rating', _vm._b({
    staticClass: "rating-color",
    attrs: {
      "half-increments": ""
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function (props) {
        return [props.isFilled ? _c('span', {
          on: {
            "click": props.click
          }
        }, [_c('u-icon', {
          attrs: {
            "variant": "round",
            "type": "button"
          }
        }, [_vm._v("star")])], 1) : props.isHalfFilled ? _c('span', {
          staticClass: "v-rating__half-filled",
          on: {
            "click": props.click
          }
        }, [_c('u-icon', {
          staticClass: "v-rating__filled",
          attrs: {
            "variant": "round",
            "type": "button"
          }
        }, [_vm._v("star")]), _c('u-icon', {
          attrs: {
            "variant": "round",
            "type": "button"
          }
        }, [_c('span', {
          staticClass: "grey--text text--lighten-3"
        }, [_vm._v("star")])])], 1) : _c('span', {
          on: {
            "click": props.click
          }
        }, [_c('u-icon', {
          attrs: {
            "variant": "round",
            "type": "button"
          }
        }, [_c('span', {
          staticClass: "grey--text text--lighten-3"
        }, [_vm._v("star")])])], 1)];
      }
    }])
  }, 'v-rating', _vm.$attrs, false)), this.$slots['default'] ? _c('span', {
    staticClass: "v-rating-wrap__txt"
  }, [_vm._t("default")], 2) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }