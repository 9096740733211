<template>
    <v-checkbox v-model="modelValue" v-bind="{ ...attrs_controls_common, ...$attrs }" color="secondary" :value="value" :inputValue="inputValue" @change="$emit('change', modelValue)">
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </v-checkbox>
</template>

<script>
import { attrs_controls_common } from "@/assets/variables";

export default {
    model: {
        prop: "inputValue",
        event: "change",
    },
    props: {
        inputValue: { type: [String, Boolean, Number, Array] },
        value: { type: [String, Boolean, Number, Array] }, // 인라인 value prop
    },
    components: {},
    data() {
        return {
            attrs_controls_common,

            modelValue: this.$props.inputValue,
        };
    },
    watch: {
        inputValue() {
            this.modelValue = this.inputValue;
        },
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
