var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', _vm._b({
    staticClass: "header-search"
  }, 'div', _vm.$attrs, false), [_vm.type != 'button' ? _c(_vm.InputTag, {
    tag: "component",
    staticClass: "header-search__input",
    attrs: {
      "placeholder": _vm.Placeholder
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.search.apply(null, arguments);
      }
    },
    model: {
      value: _vm.searchKeyword,
      callback: function ($$v) {
        _vm.searchKeyword = $$v;
      },
      expression: "searchKeyword"
    }
  }) : _vm._e(), _c('v-btn', {
    staticClass: "header-search__btn v-btn--none-active-bg",
    attrs: {
      "icon": "",
      "variant": "text"
    },
    on: {
      "click": _vm.search
    }
  }, [_c('icon-search')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }