<template>
    <div>
        <!-- Parents -->
        <h1>Parents</h1>
        <p><u>language-items-{parents}</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <language-items-primary id="language-items-primary" />
            </v-col>
            <v-col cols="auto" class="grey">
                <language-items-secondary />
            </v-col>
        </v-row>
        <!-- // Parents -->

        <v-divider class="my-30px" />

        <!-- Styles -->
        <h1>Styles</h1>
        <!-- S: Icon -->
        <h2>Icon</h2>
        <p><u>u-language-items-icon</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <u-language-items-icon :type="'menu'" id="language-items-icon"></u-language-items-icon>
            </v-col>
        </v-row>
        <!-- E: Icon -->
        <br />

        <!-- S: Text -->
        <h2>Text</h2>
        <p><u>u-language-items-text</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <u-language-items-text :type="'menu'" id="language-items-text"></u-language-items-text>
            </v-col>
            <v-col cols="auto">
                <u-language-items-text :type="'list'"></u-language-items-text>
            </v-col>
            <v-col cols="auto">
                <u-language-items-text :type="'select'"></u-language-items-text>
            </v-col>
        </v-row>
        <!-- E: Text -->
        <br />

        <!-- S: Icon Text -->
        <h2>Icon Text</h2>
        <p><u>u-language-items-icon-text</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <u-language-items-icon-text :type="'menu'" id="language-items-icon-text"></u-language-items-icon-text>
            </v-col>
            <v-col cols="auto">
                <u-language-items-icon-text :type="'list'"></u-language-items-icon-text>
            </v-col>
        </v-row>
        <!-- E: Icon Text -->
        <!-- // Styles -->

        <v-divider class="my-30px" />

        <!-- Color -->
        <h1>Color</h1>
        <p><u>language-items--light</u></p>
        <br />
        <div class="grey pa-20px">
            <v-row align="center">
                <v-col cols="auto">
                    <u-language-items-icon :type="'menu'" id="language-items-icon-light" class="language-items--light"></u-language-items-icon>
                </v-col>
                <v-col cols="auto">
                    <u-language-items-text :type="'list'" class="language-items--light"></u-language-items-text>
                </v-col>
                <v-col cols="auto">
                    <u-language-items-icon-text :type="'list'" class="language-items--light"></u-language-items-icon-text>
                </v-col>
            </v-row>
        </div>
        <!-- // Color -->

        <v-divider class="my-30px" />

        <!-- Type -->
        <h1>Type</h1>
        <!-- S: Menu -->
        <h2>Menu</h2>
        <p><u>:type="'menu'" id=""</u></p>
        <p><u>&lt;language-items :type="'menu'" id=""&gt;&lt;/language-items&gt;</u></p>
        <!-- E: Menu -->
        <br />

        <!-- S: List -->
        <h2>List</h2>
        <p><u>:type="'list'"</u></p>
        <p><u>&lt;language-items :type="'list'"&gt;&lt;/language-items&gt;</u></p>
        <!-- E: List -->
        <br />

        <!-- S: Select -->
        <h2>Select</h2>
        <p><u>:type="'select'"</u></p>
        <p><u>&lt;language-items :type="'select'"&gt;&lt;/language-items&gt;</u></p>
        <!-- E: Select -->
        <!-- // Type -->

        <v-divider class="my-30px" />

        <!-- Boolean -->
        <h1>Boolean</h1>
        <!-- S: Icon -->
        <h2>Icon</h2>
        <p><u>icon</u></p>
        <p><u>&lt;language-items icon&gt;&lt;/language-items&gt;</u></p>
        <!-- E: Icon -->
        <br />

        <!-- S: Text -->
        <h2>Text</h2>
        <p><u>text</u></p>
        <p><u>&lt;language-items text&gt;&lt;/language-items&gt;</u></p>
        <!-- E: Text -->
        <!-- // Boolean -->
    </div>
</template>

<script>
import LanguageItemsPrimary from "@/components/publish/parents/headers/language-items-primary.vue";
import LanguageItemsSecondary from "@/components/publish/parents/headers/language-items-secondary.vue";

import ULanguageItemsIcon from "@/components/publish/styles/headers/u-language-items-icon.vue";
import ULanguageItemsText from "@/components/publish/styles/headers/u-language-items-text.vue";
import ULanguageItemsIconText from "@/components/publish/styles/headers/u-language-items-icon-text.vue";

import UIcon from "@/components/publish/styles/icons/u-icon.vue";

export default {
    props: {},
    components: {
        LanguageItemsPrimary,
        LanguageItemsSecondary,

        ULanguageItemsIcon,
        ULanguageItemsText,
        ULanguageItemsIconText,

        UIcon,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
