<template>
    <div>
        <!-- Parents -->
        <h1>Parents</h1>
        <p><u>btn-{parents}</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <btn-text-primary @click="clickTest">버튼</btn-text-primary>
            </v-col>
            <v-col cols="auto">
                <btn-text-primary2 @click="clickTest">버튼</btn-text-primary2>
            </v-col>
            <v-col cols="auto">
                <btn-text-primary3 @click="clickTest">버튼</btn-text-primary3>
            </v-col>
            <v-col cols="auto">
                <btn-text-secondary @click="clickTest">버튼</btn-text-secondary>
            </v-col>
            <v-col cols="auto">
                <btn-text-secondary2 @click="clickTest">버튼</btn-text-secondary2>
            </v-col>
            <v-col cols="auto">
                <btn-text-secondary3 @click="clickTest">버튼</btn-text-secondary3>
            </v-col>
            <v-col cols="auto">
                <btn-text-tertiary @click="clickTest">버튼</btn-text-tertiary>
            </v-col>
            <v-col cols="auto">
                <btn-text-tertiary2 @click="clickTest">버튼</btn-text-tertiary2>
            </v-col>
            <v-col cols="auto">
                <btn-text-tertiary3 @click="clickTest">버튼</btn-text-tertiary3>
            </v-col>
            <v-col cols="auto">
                <btn-text-quarternary @click="clickTest">버튼</btn-text-quarternary>
            </v-col>
            <v-col cols="auto">
                <btn-text-quarternary2 @click="clickTest">버튼</btn-text-quarternary2>
            </v-col>
            <v-col cols="auto">
                <btn-text-quarternary3 @click="clickTest">버튼</btn-text-quarternary3>
            </v-col>
        </v-row>
        <!-- // Parents -->

        <v-divider class="my-30px" />

        <!-- Styles -->
        <h1>Styles</h1>
        <!-- S: Text -->
        <h2>Text</h2>
        <p><u>u-btn-text-{}</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <u-btn-text-tile>Text Tile</u-btn-text-tile>
            </v-col>
            <v-col cols="auto">
                <u-btn-text-rounded>Text Rounded</u-btn-text-rounded>
            </v-col>
            <v-col cols="auto">
                <u-btn-text-pill>Text Pill</u-btn-text-pill>
            </v-col>
        </v-row>
        <!-- E: Text -->
        <br />

        <!-- S: Text Custom -->
        <h2>Text Custom</h2>
        <p><u>u-btn-text-fit</u></p>
        <p><u>u-btn-text-underline</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <u-btn-text-fit>Text Fit</u-btn-text-fit>
            </v-col>
            <v-col cols="auto">
                <u-btn-text-underline>Text Underline</u-btn-text-underline>
            </v-col>
        </v-row>
        <!-- E: Text Custom -->
        <!-- // Styles -->

        <v-divider class="my-30px" />

        <!-- Variant -->
        <h1>Variant</h1>
        <h3>{variant} : text, text-fit, text-underline</h3>
        <p><u>{variant}</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <v-btn text color="primary">Text</v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn text color="primary" class="v-btn--text-fit">Text Fit</v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn text color="primary" class="v-btn--text-underline">Text underline</v-btn>
            </v-col>
        </v-row>
        <!-- // Variant -->
    </div>
</template>

<script>
import BtnTextPrimary from "@/components/publish/parents/buttons/btn-text-primary.vue";
import BtnTextPrimary2 from "@/components/publish/parents/buttons/btn-text-primary2.vue";
import BtnTextPrimary3 from "@/components/publish/parents/buttons/btn-text-primary3.vue";
import BtnTextSecondary from "@/components/publish/parents/buttons/btn-text-secondary.vue";
import BtnTextSecondary2 from "@/components/publish/parents/buttons/btn-text-secondary2.vue";
import BtnTextSecondary3 from "@/components/publish/parents/buttons/btn-text-secondary3.vue";
import BtnTextTertiary from "@/components/publish/parents/buttons/btn-text-tertiary.vue";
import BtnTextTertiary2 from "@/components/publish/parents/buttons/btn-text-tertiary2.vue";
import BtnTextTertiary3 from "@/components/publish/parents/buttons/btn-text-tertiary3.vue";
import BtnTextQuarternary from "@/components/publish/parents/buttons/btn-text-quarternary.vue";
import BtnTextQuarternary2 from "@/components/publish/parents/buttons/btn-text-quarternary2.vue";
import BtnTextQuarternary3 from "@/components/publish/parents/buttons/btn-text-quarternary3.vue";

import UBtnTextTile from "@/components/publish/styles/buttons/u-btn-text-tile.vue";
import UBtnTextRounded from "@/components/publish/styles/buttons/u-btn-text-rounded.vue";
import UBtnTextPill from "@/components/publish/styles/buttons/u-btn-text-pill.vue";
import UBtnTextFit from "@/components/publish/styles/buttons/u-btn-text-fit.vue";
import UBtnTextUnderline from "@/components/publish/styles/buttons/u-btn-text-underline.vue";

import UIcon from "@/components/publish/styles/icons/u-icon.vue";

export default {
    props: {},
    components: {
        BtnTextPrimary,
        BtnTextPrimary2,
        BtnTextPrimary3,
        BtnTextSecondary,
        BtnTextSecondary2,
        BtnTextSecondary3,
        BtnTextTertiary,
        BtnTextTertiary2,
        BtnTextTertiary3,
        BtnTextQuarternary,
        BtnTextQuarternary2,
        BtnTextQuarternary3,

        UBtnTextTile,
        UBtnTextRounded,
        UBtnTextPill,
        UBtnTextFit,
        UBtnTextUnderline,

        UIcon,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {
        clickTest() {
            alert("TEST");
        },
    },
};
</script>

<style lang="scss" scoped></style>
