<template>
    <div>
        <!-- Parents -->
        <h1>Parents</h1>
        <p><u>table-{parents}</u></p>
        <p><u>th-{parents}</u></p>
        <p><u>td-{parents}</u></p>
        <br />
        <v-row align="center">
            <v-col cols="12" md="6">
                <table-primary :items="items" :headers="headers">
                    <template v-slot:body="{ items }">
                        <tbody>
                            <tr v-for="header in headers" :key="header.text">
                                <th-primary :style="'width: ' + header.width">{{ header.text }}</th-primary>
                                <td-primary v-for="item in items" :key="item.text">{{ item[header.value] }}</td-primary>
                            </tr>
                        </tbody>
                    </template>
                </table-primary>
            </v-col>
            <v-col cols="12" md="6">
                <table-secondary :items="items" :headers="headers">
                    <template v-slot:header="{ props: { headers } }">
                        <thead>
                            <tr>
                                <th-secondary v-for="head in headers" :key="head.text" :style="'width:' + head.width">{{ head.text }}</th-secondary>
                            </tr>
                        </thead>
                    </template>
                    <template v-slot:body="{ items, headers }">
                        <tbody>
                            <tr v-for="item in items" :key="item.text">
                                <td-secondary v-for="header in headers" :key="header.text">{{ item[header.value] }}</td-secondary>
                            </tr>
                        </tbody>
                    </template>
                </table-secondary>
            </v-col>
            <v-col cols="12" md="6">
                <table-tertiary :items="items" :headers="headers">
                    <template v-slot:body="{ items }">
                        <tbody>
                            <tr v-for="header in headers" :key="header.text">
                                <th-tertiary :style="'width: ' + header.width">{{ header.text }}</th-tertiary>
                                <td-tertiary v-for="item in items" :key="item.text">{{ item[header.value] }}</td-tertiary>
                            </tr>
                        </tbody>
                    </template>
                </table-tertiary>
            </v-col>
            <v-col cols="12" md="6">
                <table-quarternary :items="items" :headers="headers">
                    <template v-slot:header="{ props: { headers } }">
                        <thead>
                            <tr>
                                <th-quarternary v-for="head in headers" :key="head.text" :style="'width:' + head.width">{{ head.text }}</th-quarternary>
                            </tr>
                        </thead>
                    </template>
                    <template v-slot:body="{ items, headers }">
                        <tbody>
                            <tr v-for="item in items" :key="item.text">
                                <td-quarternary v-for="header in headers" :key="header.text">{{ item[header.value] }}</td-quarternary>
                            </tr>
                        </tbody>
                    </template>
                </table-quarternary>
            </v-col>
        </v-row>
        <!-- // Parents -->

        <v-divider class="my-30px" />

        <!-- Styles -->
        <h1>Styles</h1>
        <!-- S: Line -->
        <h2>Line</h2>
        <p><u>u-table-line</u></p>
        <br />
        <v-row align="center">
            <v-col cols="12" md="4">
                <u-table-line :items="items" :headers="headers">
                    <template v-slot:body="{ items }">
                        <tbody>
                            <tr v-for="header in headers" :key="header.text">
                                <th :style="'width: ' + header.width">{{ header.text }}</th>
                                <td v-for="item in items" :key="item.text">{{ item[header.value] }}</td>
                            </tr>
                        </tbody>
                    </template>
                </u-table-line>
            </v-col>
            <v-col cols="12" md="4">
                <u-table-line :items="items" :headers="headers">
                    <template v-slot:header="{ props: { headers } }">
                        <thead>
                            <tr>
                                <th v-for="head in headers" :key="head.text" :style="'width:' + head.width">{{ head.text }}</th>
                            </tr>
                        </thead>
                    </template>
                    <template v-slot:body="{ items, headers }">
                        <tbody>
                            <tr v-for="item in items" :key="item.text">
                                <td v-for="header in headers" :key="header.text">{{ item[header.value] }}</td>
                            </tr>
                        </tbody>
                    </template>
                </u-table-line>
            </v-col>
            <v-col cols="12" md="4">
                <u-table-line :items="items" :headers="headers" class="v-data-table--border">
                    <template v-slot:header="{ props: { headers } }">
                        <thead>
                            <tr>
                                <th v-for="head in headers" :key="head.text" :style="'width:' + head.width">{{ head.text }}</th>
                            </tr>
                        </thead>
                    </template>
                    <template v-slot:body="{ items, headers }">
                        <tbody>
                            <tr v-for="item in items" :key="item.text">
                                <td v-for="header in headers" :key="header.text">{{ item[header.value] }}</td>
                            </tr>
                        </tbody>
                    </template>
                </u-table-line>
            </v-col>
        </v-row>
        <!-- E: Line -->
        <br />

        <!-- S: Background -->
        <h2>Background</h2>
        <p><u>u-table-line</u></p>
        <br />
        <v-row align="center">
            <v-col cols="12" md="4">
                <u-table-line :items="items" :headers="headers">
                    <template v-slot:body="{ items }">
                        <tbody>
                            <tr v-for="header in headers" :key="header.text">
                                <th :style="'width: ' + header.width" class="grey lighten-5">{{ header.text }}</th>
                                <td v-for="item in items" :key="item.text">{{ item[header.value] }}</td>
                            </tr>
                        </tbody>
                    </template>
                </u-table-line>
            </v-col>
            <v-col cols="12" md="4">
                <u-table-line :items="items" :headers="headers">
                    <template v-slot:header="{ props: { headers } }">
                        <thead>
                            <tr>
                                <th v-for="head in headers" :key="head.text" :style="'width:' + head.width" class="grey lighten-5">{{ head.text }}</th>
                            </tr>
                        </thead>
                    </template>
                    <template v-slot:body="{ items, headers }">
                        <tbody>
                            <tr v-for="item in items" :key="item.text">
                                <td v-for="header in headers" :key="header.text">{{ item[header.value] }}</td>
                            </tr>
                        </tbody>
                    </template>
                </u-table-line>
            </v-col>
            <v-col cols="12" md="4">
                <u-table-line :items="items" :headers="headers" class="v-data-table--border">
                    <template v-slot:header="{ props: { headers } }">
                        <thead>
                            <tr>
                                <th v-for="head in headers" :key="head.text" :style="'width:' + head.width" class="grey lighten-5">{{ head.text }}</th>
                            </tr>
                        </thead>
                    </template>
                    <template v-slot:body="{ items, headers }">
                        <tbody>
                            <tr v-for="item in items" :key="item.text">
                                <td v-for="header in headers" :key="header.text">{{ item[header.value] }}</td>
                            </tr>
                        </tbody>
                    </template>
                </u-table-line>
            </v-col>
        </v-row>
        <!-- E: Background -->
        <br />

        <!-- S: Color Bg -->
        <h2>Color Bg</h2>
        <p><u>u-table-color-bg</u></p>
        <p><u>class="v-data-table__color-bg"</u></p>
        <br />
        <v-row align="center">
            <v-col cols="12" md="4">
                <u-table-color-bg :items="items" :headers="headers" class="v-data-table--line border-1">
                    <template v-slot:body="{ items }">
                        <tbody>
                            <tr v-for="header in headers" :key="header.text">
                                <th :style="'width: ' + header.width" class="v-data-table__color-bg grey white--text">{{ header.text }}</th>
                                <td v-for="item in items" :key="item.text">{{ item[header.value] }}</td>
                            </tr>
                        </tbody>
                    </template>
                </u-table-color-bg>
            </v-col>
            <v-col cols="12" md="4">
                <u-table-color-bg :items="items" :headers="headers">
                    <template v-slot:header="{ props: { headers } }">
                        <thead>
                            <tr>
                                <th v-for="head in headers" :key="head.text" :style="'width:' + head.width" class="v-data-table__color-bg grey white--text">{{ head.text }}</th>
                            </tr>
                        </thead>
                    </template>
                    <template v-slot:body="{ items, headers }">
                        <tbody>
                            <tr v-for="item in items" :key="item.text">
                                <td v-for="header in headers" :key="header.text">{{ item[header.value] }}</td>
                            </tr>
                        </tbody>
                    </template>
                </u-table-color-bg>
            </v-col>
            <v-col cols="12" md="4">
                <u-table-color-bg :items="items" :headers="headers" class="v-data-table--border">
                    <template v-slot:header="{ props: { headers } }">
                        <thead>
                            <tr>
                                <th v-for="head in headers" :key="head.text" :style="'width:' + head.width" class="v-data-table__color-bg grey white--text">{{ head.text }}</th>
                            </tr>
                        </thead>
                    </template>
                    <template v-slot:body="{ items, headers }">
                        <tbody>
                            <tr v-for="item in items" :key="item.text">
                                <td v-for="header in headers" :key="header.text">{{ item[header.value] }}</td>
                            </tr>
                        </tbody>
                    </template>
                </u-table-color-bg>
            </v-col>
        </v-row>
        <!-- E: Color Bg -->
        <!-- // Styles -->

        <v-divider class="my-30px" />

        <!-- Density -->
        <h1>Density</h1>
        <h3>{density} : compact, comfortable, (기본-default)</h3>
        <p><u>class="v-data-table--density-{density}"</u></p>
        <br />
        <v-row align="center">
            <v-col cols="12">
                <v-data-table :items="items" :headers="headers" hide-default-header hide-default-footer disable-sort disable-pagination class="v-data-table--density-compact"> </v-data-table>
            </v-col>
            <v-col cols="12">
                <v-data-table :items="items" :headers="headers" hide-default-header hide-default-footer disable-sort disable-pagination class="v-data-table--density-comfortable"> </v-data-table>
            </v-col>
            <v-col cols="12">
                <v-data-table :items="items" :headers="headers" hide-default-header hide-default-footer disable-sort disable-pagination> </v-data-table>
            </v-col>
        </v-row>
        <!-- // Density -->
    </div>
</template>

<script>
import GuideApp from "./guide-app.vue";

import TablePrimary from "@/components/publish/parents/tables/table-primary.vue";
import TableSecondary from "@/components/publish/parents/tables/table-secondary.vue";
import TableTertiary from "@/components/publish/parents/tables/table-tertiary.vue";
import TableQuarternary from "@/components/publish/parents/tables/table-quarternary.vue";
import ThPrimary from "@/components/publish/parents/tables/th-primary.vue";
import ThSecondary from "@/components/publish/parents/tables/th-secondary.vue";
import ThTertiary from "@/components/publish/parents/tables/th-tertiary.vue";
import ThQuarternary from "@/components/publish/parents/tables/th-quarternary.vue";
import TdPrimary from "@/components/publish/parents/tables/td-primary.vue";
import TdSecondary from "@/components/publish/parents/tables/td-secondary.vue";
import TdTertiary from "@/components/publish/parents/tables/td-tertiary.vue";
import TdQuarternary from "@/components/publish/parents/tables/td-quarternary.vue";

import UTableLine from "@/components/publish/styles/tables/u-table-line.vue";
import UTableColorBg from "@/components/publish/styles/tables/u-table-color-bg.vue";

export default {
    props: {},
    components: {
        GuideApp,

        TablePrimary,
        TableSecondary,
        TableTertiary,
        TableQuarternary,
        ThPrimary,
        ThSecondary,
        ThTertiary,
        ThQuarternary,
        TdPrimary,
        TdSecondary,
        TdTertiary,
        TdQuarternary,

        UTableLine,
        UTableColorBg,
    },
    data() {
        return {
            headers: [
                { text: "Title1", value: "Title1", width: "10%" },
                { text: "Title2", value: "Title2", width: "15%" },
                { text: "Title3", value: "Title3", width: "20%" },
            ],
            items: [
                { Title1: "Text1-1", Title2: "Text1-2", Title3: "Text1-3" },
                { Title1: "Text2-1", Title2: "Text2-2", Title3: "Text2-3" },
            ],
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
