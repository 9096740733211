<template>
    <v-window-item v-bind="{ ...attrs_window_item_common, ...$attrs }" transition="scroll-x-transition" reverse-transition="scroll-x-transition">
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </v-window-item>
</template>

<script>
import { attrs_window_item_common } from "@/assets/variables";

export default {
    props: {},
    components: {},
    data() {
        return {
            attrs_window_item_common,
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
