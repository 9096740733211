var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-swiper-pagination-line-connected', _vm._b({}, 'u-swiper-pagination-line-connected', _vm.$attrs, false), [_vm._t("default")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }