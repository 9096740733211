var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "v-divider--center"
  }, [_c('span', {
    staticClass: "v-divider__txt"
  }, [_vm._t("default")], 2), _c('v-divider', _vm._b({}, 'v-divider', _vm.$attrs, false))], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }