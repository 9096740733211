<template>
    <div>
        <!-- Parents -->
        <h1>Parents</h1>
        <p><u>tooltip-{parents}</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <tooltip-secondary>
                    <template #activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on"> Tooltip Primary </v-btn>
                    </template>

                    <span>Tooltip Primary</span>
                </tooltip-secondary>
            </v-col>
            <v-col cols="auto">
                <tooltip-secondary>
                    <template #activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on"> Tooltip Secondary </v-btn>
                    </template>

                    <div class="v-tooltip__tit">제목을입력해주세요</div>
                    <p>동해물과 백두산이 마르고 닳도록 하느님이 보우하사 우리나라 만세 무궁화 삼천리 화려 강산 대한 사람 대한으로 길이 보전하세</p>
                </tooltip-secondary>
            </v-col>
            <v-col cols="auto">
                <tooltip-tertiary>
                    <template #activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on"> Tooltip Tertiary </v-btn>
                    </template>

                    <span>Tooltip Tertiary</span>
                </tooltip-tertiary>
            </v-col>
            <v-col cols="auto">
                <tooltip-quarternary>
                    <template #activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on"> Tooltip Quarternary </v-btn>
                    </template>

                    <span>Tooltip Quarternary</span>
                </tooltip-quarternary>
            </v-col>
        </v-row>
        <!-- // Parents -->

        <v-divider class="my-30px" />

        <!-- Styles -->
        <h1>Styles</h1>
        <!-- S: Opacity -->
        <h2>Opacity</h2>
        <p><u>u-tooltip-opacity-{}</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <u-tooltip-opacity-tile>
                    <template #activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on"> Tooltip Opacity Tile </v-btn>
                    </template>

                    <span>Tooltip Opacity Tile</span>
                </u-tooltip-opacity-tile>
            </v-col>
            <v-col cols="auto">
                <u-tooltip-opacity-rounded>
                    <template #activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on"> Tooltip Opacity Rounded </v-btn>
                    </template>

                    <span>Tooltip Opacity Rounded</span>
                </u-tooltip-opacity-rounded>
            </v-col>
            <v-col cols="auto">
                <u-tooltip-opacity-pill>
                    <template #activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on"> Tooltip Opacity Pill </v-btn>
                    </template>

                    <span>Tooltip Opacity Pill</span>
                </u-tooltip-opacity-pill>
            </v-col>
            <v-col cols="auto">
                <u-tooltip-opacity-tile-lg>
                    <template #activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on"> Tooltip Opacity Tile Large </v-btn>
                    </template>

                    <div class="v-tooltip__tit">제목을입력해주세요</div>
                    <p>동해물과 백두산이 마르고 닳도록 하느님이 보우하사 우리나라 만세 무궁화 삼천리 화려 강산 대한 사람 대한으로 길이 보전하세</p>
                </u-tooltip-opacity-tile-lg>
            </v-col>
            <v-col cols="auto">
                <u-tooltip-opacity-rounded-lg>
                    <template #activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on"> Tooltip Opacity Rounded Large </v-btn>
                    </template>

                    <div class="v-tooltip__tit">제목을입력해주세요</div>
                    <p>동해물과 백두산이 마르고 닳도록 하느님이 보우하사 우리나라 만세 무궁화 삼천리 화려 강산 대한 사람 대한으로 길이 보전하세</p>
                </u-tooltip-opacity-rounded-lg>
            </v-col>
        </v-row>
        <!-- E: Opacity -->
        <br />

        <!-- S: Outlined -->
        <h2>Outlined</h2>
        <p><u>u-tooltip-outlined-{}</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <u-tooltip-outlined-tile>
                    <template #activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on"> Tooltip Outlined Tile </v-btn>
                    </template>

                    <span>Tooltip Outlined Tile</span>
                </u-tooltip-outlined-tile>
            </v-col>
            <v-col cols="auto">
                <u-tooltip-outlined-rounded>
                    <template #activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on"> Tooltip Outlined Rounded </v-btn>
                    </template>

                    <span>Tooltip Outlined Rounded</span>
                </u-tooltip-outlined-rounded>
            </v-col>
            <v-col cols="auto">
                <u-tooltip-outlined-pill>
                    <template #activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on"> Tooltip Outlined Pill </v-btn>
                    </template>

                    <span>Tooltip Outlined Pill</span>
                </u-tooltip-outlined-pill>
            </v-col>
            <v-col cols="auto">
                <u-tooltip-outlined-tile-lg>
                    <template #activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on"> Tooltip Outlined Tile Large </v-btn>
                    </template>

                    <div class="v-tooltip__tit">제목을입력해주세요</div>
                    <p>동해물과 백두산이 마르고 닳도록 하느님이 보우하사 우리나라 만세 무궁화 삼천리 화려 강산 대한 사람 대한으로 길이 보전하세</p>
                </u-tooltip-outlined-tile-lg>
            </v-col>
            <v-col cols="auto">
                <u-tooltip-outlined-rounded-lg>
                    <template #activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on"> Tooltip Outlined Rounded Large </v-btn>
                    </template>

                    <div class="v-tooltip__tit">제목을입력해주세요</div>
                    <p>동해물과 백두산이 마르고 닳도록 하느님이 보우하사 우리나라 만세 무궁화 삼천리 화려 강산 대한 사람 대한으로 길이 보전하세</p>
                </u-tooltip-outlined-rounded-lg>
            </v-col>
        </v-row>
        <!-- E: Outlined -->
        <br />

        <!-- S: Elevation -->
        <h2>Elevation</h2>
        <p><u>u-tooltip-elevation-{}</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <u-tooltip-elevation-tile>
                    <template #activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on"> Tooltip Elevation Tile </v-btn>
                    </template>

                    <span>Tooltip Elevation Tile</span>
                </u-tooltip-elevation-tile>
            </v-col>
            <v-col cols="auto">
                <u-tooltip-elevation-rounded>
                    <template #activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on"> Tooltip Elevation Rounded </v-btn>
                    </template>

                    <span>Tooltip Elevation Rounded</span>
                </u-tooltip-elevation-rounded>
            </v-col>
            <v-col cols="auto">
                <u-tooltip-elevation-pill>
                    <template #activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on"> Tooltip Elevation Pill </v-btn>
                    </template>

                    <span>Tooltip Elevation Pill</span>
                </u-tooltip-elevation-pill>
            </v-col>
            <v-col cols="auto">
                <u-tooltip-elevation-tile-lg>
                    <template #activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on"> Tooltip Elevation Tile Large </v-btn>
                    </template>

                    <div class="v-tooltip__tit">제목을입력해주세요</div>
                    <p>동해물과 백두산이 마르고 닳도록 하느님이 보우하사 우리나라 만세 무궁화 삼천리 화려 강산 대한 사람 대한으로 길이 보전하세</p>
                </u-tooltip-elevation-tile-lg>
            </v-col>
            <v-col cols="auto">
                <u-tooltip-elevation-rounded-lg>
                    <template #activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on"> Tooltip Elevation Rounded Large </v-btn>
                    </template>

                    <div class="v-tooltip__tit">제목을입력해주세요</div>
                    <p>동해물과 백두산이 마르고 닳도록 하느님이 보우하사 우리나라 만세 무궁화 삼천리 화려 강산 대한 사람 대한으로 길이 보전하세</p>
                </u-tooltip-elevation-rounded-lg>
            </v-col>
        </v-row>
        <!-- E: Elevation -->
        <!-- // Styles -->

        <v-divider class="my-30px" />

        <!-- Location -->
        <h1>Location</h1>
        <h3>{location} : start, end, top, (기본-bottom)</h3>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on">Tooltip Left</v-btn>
                    </template>

                    <span>Tooltip Left</span>
                </v-tooltip>
            </v-col>
            <v-col cols="auto">
                <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on">Tooltip Right</v-btn>
                    </template>

                    <span>Tooltip Right</span>
                </v-tooltip>
            </v-col>
            <v-col cols="auto">
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on">Tooltip Top</v-btn>
                    </template>

                    <span>Tooltip Top</span>
                </v-tooltip>
            </v-col>
            <v-col cols="auto">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on">Tooltip Bottom</v-btn>
                    </template>

                    <span>Tooltip Bottom</span>
                </v-tooltip>
            </v-col>
        </v-row>
        <!-- // Location -->

        <v-divider class="my-30px" />

        <!-- Size -->
        <h1>Size</h1>
        <p><u>content-class="v-tooltip--lg"</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <v-tooltip bottom content-class="v-tooltip--lg">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on">Size Lg</v-btn>
                    </template>

                    <p>동해물과 백두산이 마르고 닳도록 하느님이 보우하사 우리나라 만세 무궁화 삼천리 화려 강산 대한 사람 대한으로 길이 보전하세</p>
                </v-tooltip>
            </v-col>
        </v-row>
        <!-- // Size -->

        <v-divider class="my-30px" />

        <!-- State -->
        <h1>State</h1>
        <!-- S: Activator -->
        <h2>Activator</h2>
        <p>activator에 마우스오버 시 활성화됩니다.</p>
        <p><u>activator=""</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on">Tooltip Default</v-btn>
                    </template>

                    <span>Tooltip Default</span>
                </v-tooltip>
            </v-col>
            <v-col cols="auto">
                <v-btn id="tooltip-id">Tooltip Id</v-btn>
                <v-tooltip bottom activator="#tooltip-id">Tooltip Id</v-tooltip>
            </v-col>
        </v-row>
        <!-- E: Activator -->
        <!-- // State -->

        <v-divider class="my-30px" />

        <!-- Rounded -->
        <h1>Rounded</h1>
        <h3>{rounded} : (기본), xs, sm, (md), lg, xl, pill, 0</h3>
        <p><u>content-class="rounded-{rounded}"</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <v-tooltip bottom content-class="rounded-2222222222">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on">Rounded Default</v-btn>
                    </template>

                    <span>Rounded Default</span>
                </v-tooltip>
            </v-col>
            <v-col cols="auto">
                <v-tooltip bottom content-class="rounded-xs">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on">Rounded XS</v-btn>
                    </template>

                    <span>Rounded XS</span>
                </v-tooltip>
            </v-col>
            <v-col cols="auto">
                <v-tooltip bottom content-class="rounded-sm">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on">Rounded Sm</v-btn>
                    </template>

                    <span>Rounded Sm</span>
                </v-tooltip>
            </v-col>
            <v-col cols="auto">
                <v-tooltip bottom content-class="rounded">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on">Rounded</v-btn>
                    </template>

                    <span>Rounded</span>
                </v-tooltip>
            </v-col>
            <v-col cols="auto">
                <v-tooltip bottom content-class="rounded-lg">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on">Rounded Lg</v-btn>
                    </template>

                    <span>Rounded Lg</span>
                </v-tooltip>
            </v-col>
            <v-col cols="auto">
                <v-tooltip bottom content-class="rounded-xl">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on">Rounded XL</v-btn>
                    </template>

                    <span>Rounded XL</span>
                </v-tooltip>
            </v-col>
            <v-col cols="auto">
                <v-tooltip bottom content-class="rounded-pill">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on">Rounded Pill</v-btn>
                    </template>

                    <span>Rounded Pill</span>
                </v-tooltip>
            </v-col>
            <v-col cols="auto">
                <v-tooltip bottom content-class="rounded-0">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on">Rounded 0</v-btn>
                    </template>

                    <span>Rounded 0</span>
                </v-tooltip>
            </v-col>
        </v-row>
        <!-- // Rounded -->

        <v-divider class="my-30px" />

        <!-- Color -->
        <h1>Color</h1>
        <!-- S: Text -->
        <h2>Text</h2>
        <p><u>content-class="{color}--text"</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <v-tooltip bottom content-class="primary--text">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on">Text Primary</v-btn>
                    </template>

                    <span>Text Primary</span>
                </v-tooltip>
            </v-col>
            <v-col cols="auto">
                <v-tooltip bottom content-class="secondary--text">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on">Text Secondary</v-btn>
                    </template>

                    <span>Text Secondary</span>
                </v-tooltip>
            </v-col>
        </v-row>
        <!-- E: Text -->
        <br />

        <!-- S: Background -->
        <h2>Background</h2>
        <p><u>content-class="{color}"</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <v-tooltip bottom content-class="white">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on">Background White</v-btn>
                    </template>

                    <span>Background White</span>
                </v-tooltip>
            </v-col>
            <v-col cols="auto">
                <v-tooltip bottom content-class="primary">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on">Background Primary</v-btn>
                    </template>

                    <span>Background Primary</span>
                </v-tooltip>
            </v-col>
        </v-row>
        <!-- E: Background -->
        <br />

        <!-- S: Border -->
        <h2>Border</h2>
        <p><u>content-class="v-tooltip--outlined {color}"</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <v-tooltip bottom content-class="v-tooltip--outlined">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on">Border</v-btn>
                    </template>

                    <span>Border</span>
                </v-tooltip>
            </v-col>
            <v-col cols="auto">
                <v-tooltip bottom content-class="v-tooltip--outlined primary primary--text">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on">Border Primary</v-btn>
                    </template>

                    <span>Border Primary</span>
                </v-tooltip>
            </v-col>
            <v-col cols="auto">
                <v-tooltip bottom content-class="v-tooltip--outlined secondary secondary--text">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on">Border Secondary</v-btn>
                    </template>

                    <span>Border Secondary</span>
                </v-tooltip>
            </v-col>
        </v-row>
        <!-- E: Border -->
        <!-- // Color -->

        <v-divider class="my-30px" />

        <!-- Elevation -->
        <h1>Elevation</h1>
        <h3>{elevation} : 0 ~ 24</h3>
        <p><u>elevation-{elevation}</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <v-tooltip bottom content-class="elevation-2">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on">Elevation 2</v-btn>
                    </template>

                    <span>Elevation 2</span>
                </v-tooltip>
            </v-col>
            <v-col cols="auto">
                <v-tooltip bottom content-class="elevation-10">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on">Elevation 10</v-btn>
                    </template>

                    <span>Elevation 10</span>
                </v-tooltip>
            </v-col>
        </v-row>
        <!-- // Elevation -->

        <v-divider class="my-30px" />

        <!-- Title Text -->
        <h1>Title Text</h1>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on">Title Text</v-btn>
                    </template>

                    <div class="v-tooltip__tit">제목을입력해주세요</div>
                    <p>동해물과 백두산이 마르고 닳도록 하느님이 보우하사 우리나라 만세 무궁화 삼천리 화려 강산 대한 사람 대한으로 길이 보전하세</p>
                </v-tooltip>
            </v-col>
        </v-row>
        <!-- // Title Text -->
    </div>
</template>

<script>
import TooltipPrimary from "@/components/publish/parents/tooltips/tooltip-primary.vue";
import TooltipSecondary from "@/components/publish/parents/tooltips/tooltip-secondary.vue";
import TooltipTertiary from "@/components/publish/parents/tooltips/tooltip-tertiary.vue";
import TooltipQuarternary from "@/components/publish/parents/tooltips/tooltip-quarternary.vue";

import UTooltipOpacityTile from "@/components/publish/styles/tooltips/u-tooltip-opacity-tile.vue";
import UTooltipOpacityRounded from "@/components/publish/styles/tooltips/u-tooltip-opacity-rounded.vue";
import UTooltipOpacityPill from "@/components/publish/styles/tooltips/u-tooltip-opacity-pill.vue";
import UTooltipOpacityTileLg from "@/components/publish/styles/tooltips/u-tooltip-opacity-tile-lg.vue";
import UTooltipOpacityRoundedLg from "@/components/publish/styles/tooltips/u-tooltip-opacity-rounded-lg.vue";
import UTooltipOutlinedTile from "@/components/publish/styles/tooltips/u-tooltip-outlined-tile.vue";
import UTooltipOutlinedRounded from "@/components/publish/styles/tooltips/u-tooltip-outlined-rounded.vue";
import UTooltipOutlinedPill from "@/components/publish/styles/tooltips/u-tooltip-outlined-pill.vue";
import UTooltipOutlinedTileLg from "@/components/publish/styles/tooltips/u-tooltip-outlined-tile-lg.vue";
import UTooltipOutlinedRoundedLg from "@/components/publish/styles/tooltips/u-tooltip-outlined-rounded-lg.vue";
import UTooltipElevationTile from "@/components/publish/styles/tooltips/u-tooltip-elevation-tile.vue";
import UTooltipElevationRounded from "@/components/publish/styles/tooltips/u-tooltip-elevation-rounded.vue";
import UTooltipElevationPill from "@/components/publish/styles/tooltips/u-tooltip-elevation-pill.vue";
import UTooltipElevationTileLg from "@/components/publish/styles/tooltips/u-tooltip-elevation-tile-lg.vue";
import UTooltipElevationRoundedLg from "@/components/publish/styles/tooltips/u-tooltip-elevation-rounded-lg.vue";
export default {
    props: {},
    components: {
        TooltipPrimary,
        TooltipSecondary,
        TooltipTertiary,
        TooltipQuarternary,

        UTooltipOpacityTile,
        UTooltipOpacityRounded,
        UTooltipOpacityPill,
        UTooltipOpacityTileLg,
        UTooltipOpacityRoundedLg,
        UTooltipOutlinedTile,
        UTooltipOutlinedRounded,
        UTooltipOutlinedPill,
        UTooltipOutlinedTileLg,
        UTooltipOutlinedRoundedLg,
        UTooltipElevationTile,
        UTooltipElevationRounded,
        UTooltipElevationPill,
        UTooltipElevationTileLg,
        UTooltipElevationRoundedLg,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
