var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('scrolldown', _vm._b({
    staticClass: "scrolldown--filled",
    class: [_vm.vertical ? 'scrolldown--vertical' : '', _vm.verticalWide ? 'scrolldown--vertical-wide' : '']
  }, 'scrolldown', _vm.$attrs, false), [_vm.vertical ? [_c('div', {
    staticClass: "scrolldown__txt"
  }, [_vm._v("Scroll")]), _c('span', {
    staticClass: "scrolldown__bar"
  })] : _vm.verticalWide ? [_c('div', {
    staticClass: "scrolldown__txt"
  }, [_vm._v("Scroll Down")]), _c('span', {
    staticClass: "scrolldown__bar"
  })] : [_c('span', {
    staticClass: "scrolldown__bar"
  }), _c('div', {
    staticClass: "scrolldown__txt"
  }, [_vm._v("Scroll")])]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }