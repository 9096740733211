<template>
    <u-btn-arrow-outlined-rounded v-bind="$attrs" @click="$emit('click')">
        <icon-arrow-quarternary v-bind="{ direction }" />
    </u-btn-arrow-outlined-rounded>
</template>

<script>
import IconArrowQuarternary from "@/components/publish/parents/icons/icon-arrow-quarternary.vue";
import UBtnArrowOutlinedRounded from "@/components/publish/styles/buttons/u-btn-arrow-outlined-rounded.vue";

export default {
    props: {
        direction: { type: String, default: "left" },
    },
    components: {
        IconArrowQuarternary,
        UBtnArrowOutlinedRounded,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
