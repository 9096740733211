<template>
    <div>
        <!-- Parents -->
        <h1>Parents</h1>
        <p><u>swiper-btn-wrap-{parents}</u></p>
        <p><u>swiper-button-{parents}</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <swiper-btn-wrap-primary>
                    <v-row class="row--xs">
                        <v-col cols="auto">
                            <swiper-button-primary />
                        </v-col>
                        <v-col cols="auto">
                            <swiper-button-primary next />
                        </v-col>
                    </v-row>
                </swiper-btn-wrap-primary>
            </v-col>
            <v-col cols="auto">
                <swiper-btn-wrap-secondary>
                    <v-row class="row--xs">
                        <v-col cols="auto">
                            <swiper-button-secondary />
                        </v-col>
                        <v-col cols="auto">
                            <swiper-button-secondary next />
                        </v-col>
                    </v-row>
                </swiper-btn-wrap-secondary>
            </v-col>
            <v-col cols="auto">
                <swiper-btn-wrap-tertiary>
                    <v-row class="row--xs">
                        <v-col cols="auto">
                            <swiper-button-tertiary />
                        </v-col>
                        <v-col cols="auto">
                            <swiper-button-tertiary next />
                        </v-col>
                    </v-row>
                </swiper-btn-wrap-tertiary>
            </v-col>
            <v-col cols="auto">
                <swiper-btn-wrap-quarternary>
                    <v-row class="row--xs">
                        <v-col cols="auto">
                            <swiper-button-quarternary />
                        </v-col>
                        <v-col cols="auto">
                            <swiper-button-quarternary next />
                        </v-col>
                    </v-row>
                </swiper-btn-wrap-quarternary>
            </v-col>
        </v-row>
        <!-- // Parents -->

        <v-divider class="my-30px" />

        <!-- Align -->
        <h1>Align</h1>
        <p><u>swiper-btn-wrap--bottom</u></p>
        <p><u>swiper-btn-wrap--left</u></p>
        <p><u>swiper-btn-wrap--right</u></p>
        <!-- // Color -->
    </div>
</template>

<script>
import SwiperBtnWrapPrimary from "@/components/publish/parents/swipers/swiper-btn-wrap-primary.vue";
import SwiperBtnWrapSecondary from "@/components/publish/parents/swipers/swiper-btn-wrap-secondary.vue";
import SwiperBtnWrapTertiary from "@/components/publish/parents/swipers/swiper-btn-wrap-tertiary.vue";
import SwiperBtnWrapQuarternary from "@/components/publish/parents/swipers/swiper-btn-wrap-quarternary.vue";
import SwiperButtonPrimary from "@/components/publish/parents/swipers/swiper-button-primary.vue";
import SwiperButtonSecondary from "@/components/publish/parents/swipers/swiper-button-secondary.vue";
import SwiperButtonTertiary from "@/components/publish/parents/swipers/swiper-button-tertiary.vue";
import SwiperButtonQuarternary from "@/components/publish/parents/swipers/swiper-button-quarternary.vue";

export default {
    props: {},
    components: {
        SwiperBtnWrapPrimary,
        SwiperBtnWrapSecondary,
        SwiperBtnWrapTertiary,
        SwiperBtnWrapQuarternary,
        SwiperButtonPrimary,
        SwiperButtonSecondary,
        SwiperButtonTertiary,
        SwiperButtonQuarternary,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
// 가이드 작업을 위해 넣어 놓은 스타일입니다.
.swiper-btn-wrap {
    position: relative !important;
    bottom: auto !important;
    left: auto !important;
    right: auto !important;
}
</style>
