var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('swiper-button', _vm._b({
    attrs: {
      "buttonTag": "btn-arrow-quarternary"
    }
  }, 'swiper-button', _vm.$attrs, false));

}
var staticRenderFns = []

export { render, staticRenderFns }