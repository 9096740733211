<template>
    <swiper-pagination v-bind="$attrs" class="swiper-pagination--text"><slot /></swiper-pagination>
</template>

<script>
import SwiperPagination from "@/components/publish/styles/swipers/swiper-pagination.vue";

export default {
    props: {},
    components: { SwiperPagination },
    data() {
        return {
            // 스와이퍼 옵션
            // swiperOptions: {
            //     pagination: {
            //         el: ".swiper-pagination",
            //         clickable: true,
            //         renderBullet: function (index, className) {
            //             function numberAppend(n) {
            //                 return n < 10 ? "0" + n.toString() : n.toString();
            //             }
            //             return '<span class="' + className + '">' + numberAppend(index + 1) + "</span>";
            //         },
            //     },
            // },
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.swiper-pagination--text {
    color: rgb(var(--v-theme-grey-darken-4));

    :deep() {
        .swiper-pagination {
            &-bullet {
                width: auto;
                height: auto;
                margin: 0 8px;
                font-size: 1.4rem;
                line-height: 1.2;
                font-weight: 700;
                padding-bottom: 6px;
                opacity: 0.2;
                background-color: transparent;
                border: 0;
                border-radius: 0;
                &::after {
                    display: none;
                }
                &::before {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    -webkit-transform: translate(-50%, 0) scaleX(0);
                    -ms-transform: translate(-50%, 0) scaleX(0);
                    transform: translate(-50%, 0) scaleX(0);
                    display: block;
                    width: 100%;
                    height: 2px;
                    background-color: currentColor;
                    opacity: 0;
                    -webkit-transition: all ease-out 0.4s;
                    -ms-transition: all ease-out 0.4s;
                    transition: all ease-out 0.4s;
                }
                &-active {
                    opacity: 1 !important;
                    &::before {
                        -webkit-transform: translate(-50%, 0) scaleX(1);
                        -ms-transform: translate(-50%, 0) scaleX(1);
                        transform: translate(-50%, 0) scaleX(1);
                        opacity: 1 !important;
                    }
                }
            }
        }
    }

    &.swiper-pagination--light {
        :deep() {
            .swiper-pagination {
                &-bullet {
                    opacity: 0.4;
                }
            }
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .swiper-pagination--text {
        :deep() {
            .swiper-pagination {
                &-bullet {
                    margin: 0 12px;
                }
            }
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
</style>
