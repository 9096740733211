var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('swiper-pagination', _vm._b({
    staticClass: "swiper-pagination--around-border"
  }, 'swiper-pagination', _vm.$attrs, false), [_vm._t("default")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }