<template>
    <div>
        <!-- Styles -->
        <h1>Styles</h1>
        <v-row>
            <v-col cols="3">
                <!-- S: Topline -->
                <h2>Topline</h2>
                <p><u>u-lnb-topline</u></p>
                <br />
                <div class="mw-200px">
                    <u-lnb-topline>
                        <li><a href="#">lnb</a></li>
                        <li><a href="#">lnb</a></li>
                        <li><a href="#">lnb</a></li>
                    </u-lnb-topline>
                </div>
                <!-- E: Topline -->
            </v-col>
            <v-col cols="3">
                <!-- S: Topline Border -->
                <h2>Topline Border</h2>
                <p><u>u-lnb-topline-border</u></p>
                <br />
                <div class="mw-200px">
                    <u-lnb-topline-border>
                        <li><a href="#">lnb</a></li>
                        <li><a href="#">lnb</a></li>
                        <li><a href="#">lnb</a></li>
                    </u-lnb-topline-border>
                </div>
                <!-- E: Topline Border -->
            </v-col>
            <v-col cols="3">
                <!-- S: All Filled -->
                <h2>All Filled</h2>
                <p><u>u-lnb-all-filled</u></p>
                <br />
                <div class="mw-200px">
                    <u-lnb-all-filled>
                        <li><a href="#">lnb</a></li>
                        <li><a href="#">lnb</a></li>
                        <li><a href="#">lnb</a></li>
                    </u-lnb-all-filled>
                </div>
                <!-- E: All Filled -->
            </v-col>
            <v-col cols="3">
                <!-- S: All Opacity Black -->
                <h2>All Opacity Black</h2>
                <p><u>u-lnb-all-opacity-black</u></p>
                <br />
                <div class="mw-200px grey">
                    <u-lnb-all-opacity-black>
                        <li><a href="#">lnb</a></li>
                        <li><a href="#">lnb</a></li>
                        <li><a href="#">lnb</a></li>
                    </u-lnb-all-opacity-black>
                </div>
                <!-- E: All Opacity Black -->
            </v-col>
            <v-col cols="3">
                <!-- S: All Border -->
                <h2>All Border</h2>
                <p><u>u-lnb-all-border</u></p>
                <br />
                <div class="mw-200px">
                    <u-lnb-all-border>
                        <li><a href="#">lnb</a></li>
                        <li><a href="#">lnb</a></li>
                        <li><a href="#">lnb</a></li>
                    </u-lnb-all-border>
                </div>
                <!-- E: All Border -->
            </v-col>
        </v-row>
        <!-- // Styles -->
    </div>
</template>

<script>
import ULnbToplineBorder from "@/components/publish/styles/headers/u-lnb-topline-border.vue";
import ULnbTopline from "@/components/publish/styles/headers/u-lnb-topline.vue";
import ULnbAllFilled from "@/components/publish/styles/headers/u-lnb-all-filled.vue";
import ULnbAllOpacityBlack from "@/components/publish/styles/headers/u-lnb-all-opacity-black.vue";
import ULnbAllBorder from "@/components/publish/styles/headers/u-lnb-all-border.vue";

export default {
    props: {},
    components: {
        ULnbToplineBorder,
        ULnbTopline,
        ULnbAllFilled,
        ULnbAllOpacityBlack,
        ULnbAllBorder,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
// 가이드 작업을 위해 넣어 놓은 스타일입니다.
.lnb {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    height: auto;
    opacity: 1;
    visibility: visible;
}
</style>
