<template>
    <svg v-bind="$attrs" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" class="icon-svg icon-search" :class="[color ? color : '', size ? 'icon--size-' + size : '']">
        <g>
            <path
                d="M18,17.08l-3.8-3.8c1.2-1.41,1.92-3.22,1.92-5.21C16.11,3.61,12.51,0,8.06,0S0,3.61,0,8.06s3.61,8.06,8.06,8.06c1.99,0,3.81-.72,5.21-1.92l3.8,3.8,.92-.92ZM1.31,8.06C1.31,4.33,4.33,1.31,8.06,1.31s6.75,3.02,6.75,6.75-3.02,6.75-6.75,6.75S1.31,11.79,1.31,8.06Z"
            />
        </g>
    </svg>
</template>

<script>
export default {
    props: {
        color: { type: String, default: "" },
        size: { type: String, default: "default" },
    },
    components: {},
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
// Size
.icon--size {
    &-xx-small {
        height: 12px;
    }
    &-x-small {
        height: 14px;
    }
    &-small {
        height: 16px;
    }
    &-default {
        height: 18px;
    }
    &-large {
        height: 20px;
    }
    &-x-large {
        height: 22px;
    }
    &-xx-large {
        height: 26px;
    }
}
</style>
