var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-tabs', {
    staticClass: "mb-30px",
    attrs: {
      "grow": ""
    },
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab', [_vm._v("Control")]), _c('v-tab', [_vm._v("Text field")]), _c('v-tab', [_vm._v("Textarea")]), _c('v-tab', [_vm._v("File input")]), _c('v-tab', [_vm._v("Select")])], 1), _c('v-window', {
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-window-item', [_c('FormsControl')], 1), _c('v-window-item', [_c('FormsTextField')], 1), _c('v-window-item', [_c('FormsTextarea')], 1), _c('v-window-item', [_c('FormsFileInput')], 1), _c('v-window-item', [_c('FormsSelect')], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }