var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h1', [_vm._v("Parents")]), _vm._m(0), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('btn-arrow-primary'), _c('btn-arrow-primary', {
    attrs: {
      "direction": "right"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('btn-arrow-secondary', {
    attrs: {
      "x-large": ""
    }
  }), _c('btn-arrow-secondary', {
    attrs: {
      "x-large": "",
      "direction": "right"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('btn-arrow-tertiary', {
    attrs: {
      "color": "primary"
    }
  }), _c('btn-arrow-tertiary', {
    attrs: {
      "color": "primary",
      "direction": "right"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('btn-arrow-quarternary', {
    attrs: {
      "color": "secondary"
    }
  }), _c('btn-arrow-quarternary', {
    attrs: {
      "color": "secondary",
      "direction": "right"
    }
  })], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Styles")]), _vm._m(1), _c('br'), _c('h2', [_vm._v("Outlined")]), _vm._m(2), _c('br'), _c('h3', [_vm._v("icon-chevron")]), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-arrow-outlined-tile', {
    staticClass: "grey lighten-3"
  }, [_c('icon-chevron')], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-arrow-outlined-rounded', {
    staticClass: "grey lighten-3"
  }, [_c('icon-chevron')], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-arrow-outlined-circle', {
    staticClass: "grey lighten-3"
  }, [_c('icon-chevron')], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-arrow-outlined-tile', {
    attrs: {
      "color": "primary"
    }
  }, [_c('icon-chevron')], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-arrow-outlined-rounded', {
    attrs: {
      "color": "primary"
    }
  }, [_c('icon-chevron')], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-arrow-outlined-circle', {
    attrs: {
      "color": "primary"
    }
  }, [_c('icon-chevron')], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-arrow-outlined-tile', {
    staticClass: "v-btn--arrow grey lighten-3",
    attrs: {
      "color": "primary"
    }
  }, [_c('icon-chevron')], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-arrow-outlined-rounded', {
    staticClass: "v-btn--arrow grey lighten-3",
    attrs: {
      "color": "primary"
    }
  }, [_c('icon-chevron')], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-arrow-outlined-circle', {
    staticClass: "v-btn--arrow grey lighten-3",
    attrs: {
      "color": "primary"
    }
  }, [_c('icon-chevron')], 1)], 1)], 1), _c('br'), _c('h3', [_vm._v("icon-arrow")]), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-arrow-outlined-tile', {
    staticClass: "grey lighten-3"
  }, [_c('icon-arrow')], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-arrow-outlined-rounded', {
    staticClass: "grey lighten-3"
  }, [_c('icon-arrow')], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-arrow-outlined-circle', {
    staticClass: "grey lighten-3"
  }, [_c('icon-arrow')], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-arrow-outlined-tile', {
    attrs: {
      "color": "primary"
    }
  }, [_c('icon-arrow')], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-arrow-outlined-rounded', {
    attrs: {
      "color": "primary"
    }
  }, [_c('icon-arrow')], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-arrow-outlined-circle', {
    attrs: {
      "color": "primary"
    }
  }, [_c('icon-arrow')], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-arrow-outlined-tile', {
    staticClass: "v-btn--arrow grey lighten-3",
    attrs: {
      "color": "primary"
    }
  }, [_c('icon-arrow')], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-arrow-outlined-rounded', {
    staticClass: "v-btn--arrow grey lighten-3",
    attrs: {
      "color": "primary"
    }
  }, [_c('icon-arrow')], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-arrow-outlined-circle', {
    staticClass: "v-btn--arrow grey lighten-3",
    attrs: {
      "color": "primary"
    }
  }, [_c('icon-arrow')], 1)], 1)], 1), _c('br'), _c('h3', [_vm._v("icon-arrow-rounded")]), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-arrow-outlined-tile', {
    staticClass: "grey lighten-3"
  }, [_c('icon-arrow-rounded')], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-arrow-outlined-rounded', {
    staticClass: "grey lighten-3"
  }, [_c('icon-arrow-rounded')], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-arrow-outlined-circle', {
    staticClass: "grey lighten-3"
  }, [_c('icon-arrow-rounded')], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-arrow-outlined-tile', {
    attrs: {
      "color": "primary"
    }
  }, [_c('icon-arrow-rounded')], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-arrow-outlined-rounded', {
    attrs: {
      "color": "primary"
    }
  }, [_c('icon-arrow-rounded')], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-arrow-outlined-circle', {
    attrs: {
      "color": "primary"
    }
  }, [_c('icon-arrow-rounded')], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-arrow-outlined-tile', {
    staticClass: "v-btn--arrow grey lighten-3",
    attrs: {
      "color": "primary"
    }
  }, [_c('icon-arrow-rounded')], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-arrow-outlined-rounded', {
    staticClass: "v-btn--arrow grey lighten-3",
    attrs: {
      "color": "primary"
    }
  }, [_c('icon-arrow-rounded')], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-arrow-outlined-circle', {
    staticClass: "v-btn--arrow grey lighten-3",
    attrs: {
      "color": "primary"
    }
  }, [_c('icon-arrow-rounded')], 1)], 1)], 1), _c('br'), _c('h3', [_vm._v("icon-arrow-long")]), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-arrow-outlined-tile', {
    staticClass: "grey lighten-3"
  }, [_c('icon-arrow-long')], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-arrow-outlined-rounded', {
    staticClass: "grey lighten-3"
  }, [_c('icon-arrow-long')], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-arrow-outlined-circle', {
    staticClass: "grey lighten-3"
  }, [_c('icon-arrow-long')], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-arrow-outlined-tile', {
    attrs: {
      "color": "primary"
    }
  }, [_c('icon-arrow-long')], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-arrow-outlined-rounded', {
    attrs: {
      "color": "primary"
    }
  }, [_c('icon-arrow-long')], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-arrow-outlined-circle', {
    attrs: {
      "color": "primary"
    }
  }, [_c('icon-arrow-long')], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-arrow-outlined-tile', {
    staticClass: "v-btn--arrow grey lighten-3",
    attrs: {
      "color": "primary"
    }
  }, [_c('icon-arrow-long')], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-arrow-outlined-rounded', {
    staticClass: "v-btn--arrow grey lighten-3",
    attrs: {
      "color": "primary"
    }
  }, [_c('icon-arrow-long')], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-arrow-outlined-circle', {
    staticClass: "v-btn--arrow grey lighten-3",
    attrs: {
      "color": "primary"
    }
  }, [_c('icon-arrow-long')], 1)], 1)], 1), _c('br'), _c('h3', [_vm._v("icon-arrow-long-rounded")]), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-arrow-outlined-tile', {
    staticClass: "grey lighten-3"
  }, [_c('icon-arrow-long-rounded')], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-arrow-outlined-rounded', {
    staticClass: "grey lighten-3"
  }, [_c('icon-arrow-long-rounded')], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-arrow-outlined-circle', {
    staticClass: "grey lighten-3"
  }, [_c('icon-arrow-long-rounded')], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-arrow-outlined-tile', {
    attrs: {
      "color": "primary"
    }
  }, [_c('icon-arrow-long-rounded')], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-arrow-outlined-rounded', {
    attrs: {
      "color": "primary"
    }
  }, [_c('icon-arrow-long-rounded')], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-arrow-outlined-circle', {
    attrs: {
      "color": "primary"
    }
  }, [_c('icon-arrow-long-rounded')], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-arrow-outlined-tile', {
    staticClass: "v-btn--arrow grey lighten-3",
    attrs: {
      "color": "primary"
    }
  }, [_c('icon-arrow-long-rounded')], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-arrow-outlined-rounded', {
    staticClass: "v-btn--arrow grey lighten-3",
    attrs: {
      "color": "primary"
    }
  }, [_c('icon-arrow-long-rounded')], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-arrow-outlined-circle', {
    staticClass: "v-btn--arrow grey lighten-3",
    attrs: {
      "color": "primary"
    }
  }, [_c('icon-arrow-long-rounded')], 1)], 1)], 1), _c('br'), _c('br'), _c('h2', [_vm._v("Text Fit")]), _vm._m(3), _c('br'), _c('h3', [_vm._v("icon-chevron")]), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-arrow-text-fit', [_c('icon-chevron')], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-arrow-text-fit', {
    attrs: {
      "color": "primary"
    }
  }, [_c('icon-chevron')], 1)], 1)], 1), _c('br'), _c('h3', [_vm._v("icon-arrow")]), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-arrow-text-fit', [_c('icon-arrow')], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-arrow-text-fit', {
    attrs: {
      "color": "primary"
    }
  }, [_c('icon-arrow')], 1)], 1)], 1), _c('br'), _c('h3', [_vm._v("icon-arrow-rounded")]), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-arrow-text-fit', [_c('icon-arrow-rounded')], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-arrow-text-fit', {
    attrs: {
      "color": "primary"
    }
  }, [_c('icon-arrow-rounded')], 1)], 1)], 1), _c('br'), _c('h3', [_vm._v("icon-arrow-long")]), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-arrow-text-fit', [_c('icon-arrow-long')], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-arrow-text-fit', {
    attrs: {
      "color": "primary"
    }
  }, [_c('icon-arrow-long')], 1)], 1)], 1), _c('br'), _c('h3', [_vm._v("icon-arrow-long-rounded")]), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-arrow-text-fit', [_c('icon-arrow-long-rounded')], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-arrow-text-fit', {
    attrs: {
      "color": "primary"
    }
  }, [_c('icon-arrow-long-rounded')], 1)], 1)], 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("btn-arrow-{parents}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("v-btn--arrow")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-btn-arrow-outlined-{}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-btn-arrow-text-fit")])]);

}]

export { render, staticRenderFns }