var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('header-search', {
    staticClass: "header-search--underlined",
    attrs: {
      "InputTag": "u-text-field-underlined"
    }
  }, [_vm._t("default")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }