<template>
    <u-btn-arrow-outlined-circle v-bind="$attrs" @click="$emit('click')">
        <icon-arrow-secondary v-bind="{ direction }" />
    </u-btn-arrow-outlined-circle>
</template>

<script>
import IconArrowSecondary from "@/components/publish/parents/icons/icon-arrow-secondary.vue";
import UBtnArrowOutlinedCircle from "@/components/publish/styles/buttons/u-btn-arrow-outlined-circle.vue";

export default {
    props: {
        direction: { type: String, default: "left" },
    },
    components: {
        IconArrowSecondary,
        UBtnArrowOutlinedCircle,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
