var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h1', [_vm._v("Parents")]), _vm._m(0), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary",
      "id": "menu-primary"
    }
  }, [_vm._v("Menu Primary")]), _c('menu-primary', {
    attrs: {
      "activator": "#menu-primary"
    }
  }, [_c('v-list', [_c('v-list-item', [_c('v-list-item-title', [_vm._v("Menu")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v("Menu")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v("Menu")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v("Menu")])], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary",
      "id": "menu-secondary"
    }
  }, [_vm._v("Menu Secondary")]), _c('menu-secondary', {
    attrs: {
      "activator": "#menu-secondary"
    }
  }, [_c('v-list', [_c('v-list-item', [_c('v-list-item-title', [_vm._v("Menu")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v("Menu")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v("Menu")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v("Menu")])], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary",
      "id": "menu-tertiary"
    }
  }, [_vm._v("Menu Tertiary")]), _c('menu-tertiary', {
    attrs: {
      "activator": "#menu-tertiary"
    }
  }, [_c('v-list', [_c('v-list-item', [_c('v-list-item-title', [_vm._v("Menu")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v("Menu")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v("Menu")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v("Menu")])], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary",
      "id": "menu-quarternary"
    }
  }, [_vm._v("Menu Quarternary")]), _c('menu-quarternary', {
    attrs: {
      "activator": "#menu-quarternary"
    }
  }, [_c('v-list', [_c('v-list-item', [_c('v-list-item-title', [_vm._v("Menu")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v("Menu")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v("Menu")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v("Menu")])], 1)], 1)], 1)], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Default")]), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary",
      "id": "menu-default"
    }
  }, [_vm._v("Menu Default")]), _c('v-menu', {
    attrs: {
      "activator": "#menu-default"
    }
  }, [_c('v-list', [_c('v-list-item', [_c('v-list-item-title', [_vm._v("Menu")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v("Menu")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v("Menu")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v("Menu")])], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary",
      "id": "menu-default-tile"
    }
  }, [_vm._v("Menu Default Tile")]), _c('v-menu', {
    attrs: {
      "activator": "#menu-default-tile",
      "content-class": "rounded-0"
    }
  }, [_c('v-list', [_c('v-list-item', [_c('v-list-item-title', [_vm._v("Menu")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v("Menu")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v("Menu")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v("Menu")])], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("menu-{parents}")])]);

}]

export { render, staticRenderFns }