var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h1', [_vm._v("공통")]), _c('h3', [_vm._v("{breakpoint} : sm"), _c('small', [_vm._v("(min-width:576px)")]), _vm._v(", md"), _c('small', [_vm._v("(min-width:768px)")]), _vm._v(", lg"), _c('small', [_vm._v("(min-width:1024px)")]), _vm._v(", xl"), _c('small', [_vm._v("(min-width:1200px)")])]), _c('h3', [_vm._v("{size} : 2px씩 증가")]), _c('h3', [_vm._v("{parents} : primary(2,3,4...), secondary, tertiary, quarternary")]), _c('h3', [_c('small', [_vm._v("같은 parents는 모양이 같습니다. (ex. btn-primary = btn-primary3) 색상이 추가되면 뒤에 숫자를 붙여서 생성 해주세요. 1에는 숫자를 붙이지 않습니다.")])]), _c('h3', [_vm._v("{color} : vuetify 컬러시스템")])]);

}]

export { render, staticRenderFns }