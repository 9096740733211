var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h1', [_vm._v("Divider Group")]), _vm._m(0), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('u-divider-group', [_c('li', [_vm._v("11111")]), _c('li', [_vm._v("22222")]), _c('li', [_vm._v("33333")])])], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('u-divider-group', {
    staticClass: "divider-group--lg"
  }, [_c('li', [_vm._v("11111")]), _c('li', [_vm._v("22222")]), _c('li', [_vm._v("33333")])])], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Inline Group")]), _vm._m(1), _vm._m(2), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('u-inline-group', [_c('li', [_vm._v("11111")]), _c('li', [_vm._v("22222")]), _c('li', [_vm._v("33333")])])], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('u-inline-group', {
    staticClass: "inline-group--lg"
  }, [_c('li', [_vm._v("11111")]), _c('li', {
    staticClass: "clear"
  }, [_vm._v("22222")]), _c('li', [_vm._v("33333")])])], 1)], 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-divider-group")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-inline-group")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("class=\"clear\"")]), _vm._v("을 넣으면 줄바꿈됩니다.")]);

}]

export { render, staticRenderFns }