<template>
    <v-select v-model="modelValue" v-bind="{ ...attrs_select_common, ...$attrs }" filled class="rounded-pill" @input="$emit('input', modelValue)">
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </v-select>
</template>

<script>
import { attrs_select_common } from "@/assets/variables";

export default {
    props: {
        value: { type: String, default: null },
    },
    components: {},
    data() {
        return {
            attrs_select_common,

            modelValue: this.$props.value,
        };
    },
    watch: {
        value() {
            this.modelValue = this.value;
        },
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
