var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h1', [_vm._v("Parents")]), _vm._m(0), _vm._m(1), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('swiper-btn-wrap-primary', [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('swiper-button-primary')], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('swiper-button-primary', {
    attrs: {
      "next": ""
    }
  })], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('swiper-btn-wrap-secondary', [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('swiper-button-secondary')], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('swiper-button-secondary', {
    attrs: {
      "next": ""
    }
  })], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('swiper-btn-wrap-tertiary', [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('swiper-button-tertiary')], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('swiper-button-tertiary', {
    attrs: {
      "next": ""
    }
  })], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('swiper-btn-wrap-quarternary', [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('swiper-button-quarternary')], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('swiper-button-quarternary', {
    attrs: {
      "next": ""
    }
  })], 1)], 1)], 1)], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Align")]), _vm._m(2), _vm._m(3), _vm._m(4)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("swiper-btn-wrap-{parents}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("swiper-button-{parents}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("swiper-btn-wrap--bottom")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("swiper-btn-wrap--left")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("swiper-btn-wrap--right")])]);

}]

export { render, staticRenderFns }