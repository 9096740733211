var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-pagination', _vm._b({
    staticClass: "v-pagination--text-color"
  }, 'v-pagination', _vm.$attrs, false));

}
var staticRenderFns = []

export { render, staticRenderFns }