<template>
    <div>
        <!-- Parents -->
        <h1>Parents</h1>
        <p><u>tit-{parents}</u></p>
        <br />
        <v-row>
            <v-col cols="12" md="6">
                <tit-primary>제목을입력해주세요</tit-primary>
            </v-col>
            <v-col cols="12" md="6">
                <tit-secondary> 제목을입력해주세요 </tit-secondary>
            </v-col>
            <v-col cols="12" md="6">
                <tit-tertiary> 제목을입력해주세요 </tit-tertiary>
            </v-col>
            <v-col cols="12" md="6">
                <tit-quarternary> 제목을입력해주세요 </tit-quarternary>
            </v-col>
        </v-row>
        <!-- // Parents -->

        <v-divider class="my-30px" />

        <!-- Styles -->
        <h1>Styles</h1>
        <!-- S: Default -->
        <h2>Default</h2>
        <v-row>
            <v-col cols="12" md="6">
                <p><u>u-tit-default</u></p>
                <br />
                <u-tit-default>제목을입력해주세요</u-tit-default>
            </v-col>
        </v-row>
        <!-- E: Default -->
        <br />

        <!-- S: Sub title -->
        <h2>Sub title</h2>
        <v-row>
            <v-col cols="12" md="6">
                <p><u>u-tit-subtit</u></p>
                <br />
                <u-tit-subtit>소제목을입력해주세요</u-tit-subtit>
            </v-col>
        </v-row>
        <!-- E: Sub title -->
        <!-- // Styles -->

        <v-divider class="my-30px" />

        <!-- Size -->
        <h1>Size</h1>
        <h3>{size} : xxs, xs, sm, (기본), lg, xl, xxl</h3>
        <p><u>tit--{size}</u></p>
        <br />
        <tit class="tit--xxs">제목을입력해주세요</tit>
        <tit class="tit--xs">제목을입력해주세요</tit>
        <tit class="tit--sm">제목을입력해주세요</tit>
        <tit>제목을입력해주세요</tit>
        <tit class="tit--lg">제목을입력해주세요</tit>
        <tit class="tit--xl">제목을입력해주세요</tit>
        <tit class="tit--xxl">제목을입력해주세요</tit>
        <!-- // Size -->
    </div>
</template>

<script>
import GuideApp from "./guide-app.vue";

import TitPrimary from "@/components/publish/parents/typography/tit-primary.vue";
import TitSecondary from "@/components/publish/parents/typography/tit-secondary.vue";
import TitTertiary from "@/components/publish/parents/typography/tit-tertiary.vue";
import TitQuarternary from "@/components/publish/parents/typography/tit-quarternary.vue";

import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTitSubtit from "@/components/publish/styles/typography/u-tit-subtit.vue";

import Tit from "@/components/publish/styles/typography/tit.vue";

export default {
    props: {},
    components: {
        GuideApp,

        TitPrimary,
        TitSecondary,
        TitTertiary,
        TitQuarternary,

        UTitDefault,
        UTitSubtit,

        Tit,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
