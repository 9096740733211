var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.next ? _c(_vm.buttonTag, _vm._b({
    tag: "component",
    staticClass: "swiper-button-next",
    attrs: {
      "direction": "right"
    }
  }, 'component', _vm.$attrs, false)) : _c(_vm.buttonTag, _vm._b({
    tag: "component",
    staticClass: "swiper-button-prev"
  }, 'component', _vm.$attrs, false));

}
var staticRenderFns = []

export { render, staticRenderFns }