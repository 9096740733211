var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-radio', _vm._b({
    scopedSlots: _vm._u([{
      key: "label",
      fn: function () {
        return [_c('strong', {
          staticClass: "red--text"
        }, [_vm._v(_vm._s(_vm.label))])];
      },
      proxy: true
    }, _vm._l(_vm.$slots, function (_, slotName) {
      return {
        key: slotName,
        fn: function () {
          return [_vm._t(slotName)];
        },
        proxy: true
      };
    })], null, true)
  }, 'v-radio', _vm.$attrs, false));

}
var staticRenderFns = []

export { render, staticRenderFns }