var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-primary', [_c('v-container', [_c('guide-common'), _c('v-tabs', {
    staticClass: "mt-60px mb-30px",
    attrs: {
      "show-arrows": "",
      "center-active": ""
    },
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab', [_vm._v("Utilities")]), _c('v-tab', [_vm._v("Colors")]), _c('v-tab', [_vm._v("Typography")]), _c('v-tab', [_vm._v("Buttons")]), _c('v-tab', [_vm._v("Chips")]), _c('v-tab', [_vm._v("Icons")]), _c('v-tab', [_vm._v("Forms")]), _c('v-tab', [_vm._v("Tooltips")]), _c('v-tab', [_vm._v("Dividers")]), _c('v-tab', [_vm._v("Scrolldowns")]), _c('v-tab', [_vm._v("Paginations")]), _c('v-tab', [_vm._v("Ratings")]), _c('v-tab', [_vm._v("Swipers")]), _c('v-tab', [_vm._v("Tabs")]), _c('v-tab', [_vm._v("Tables")]), _c('v-tab', [_vm._v("Cards")]), _c('v-tab', [_vm._v("Menus")]), _c('v-tab', [_vm._v("Dialogs")]), _c('v-tab', [_vm._v("Groups")]), _c('v-tab', [_vm._v("Headers")]), _c('v-tab', [_vm._v("Footers")]), _c('v-tab', [_vm._v("SubHeads")])], 1), _c('v-tabs-items', {
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab-item', [_c('Utilities')], 1), _c('v-tab-item', [_c('Colors')], 1), _c('v-tab-item', [_c('Typography')], 1), _c('v-tab-item', [_c('Buttons')], 1), _c('v-tab-item', [_c('Chips')], 1), _c('v-tab-item', [_c('Icons')], 1), _c('v-tab-item', [_c('Forms')], 1), _c('v-tab-item', [_c('Tooltips')], 1), _c('v-tab-item', [_c('Dividers')], 1), _c('v-tab-item', [_c('Scrolldowns')], 1), _c('v-tab-item', [_c('Paginations')], 1), _c('v-tab-item', [_c('Ratings')], 1), _c('v-tab-item', [_c('Swipers')], 1), _c('v-tab-item', [_c('Tabs')], 1), _c('v-tab-item', [_c('Tables')], 1), _c('v-tab-item', [_c('Cards')], 1), _c('v-tab-item', [_c('Menus')], 1), _c('v-tab-item', [_c('Dialogs')], 1), _c('v-tab-item', [_c('Groups')], 1), _c('v-tab-item', [_c('Headers')], 1), _c('v-tab-item', [_c('Footers')], 1), _c('v-tab-item', [_c('SubHeads')], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }