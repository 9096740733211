<template>
    <div>
        <!-- Parents -->
        <h1>Parents</h1>
        <p><u>swiper-pagination-{parents}</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <swiper-pagination-primary>
                    <span class="swiper-pagination-bullet swiper-pagination-bullet-active">01</span>
                    <span class="swiper-pagination-bullet">02</span>
                    <span class="swiper-pagination-bullet">03</span>
                    <span class="swiper-pagination-bullet">04</span>
                </swiper-pagination-primary>
            </v-col>
            <v-col cols="auto">
                <swiper-pagination-secondary>
                    <span class="swiper-pagination-bullet swiper-pagination-bullet-active"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                </swiper-pagination-secondary>
            </v-col>
            <v-col cols="auto">
                <swiper-pagination-tertiary>
                    <span class="swiper-pagination-bullet swiper-pagination-bullet-active"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                </swiper-pagination-tertiary>
            </v-col>
            <v-col cols="auto">
                <swiper-pagination-quarternary>
                    <span class="swiper-pagination-bullet swiper-pagination-bullet-active"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                </swiper-pagination-quarternary>
            </v-col>
        </v-row>
        <!-- // Parents -->

        <v-divider class="my-30px" />

        <!-- Styles -->
        <h1>Styles</h1>
        <p>swiper-pagination-bullet는 템플릿 작업을 위해 넣은 놓은 것입니다.</p>
        <p>사용시 swiper-pagination-bullet는 지우고 u-swiper-pagination-{}만 사용해주세요.</p>
        <br />

        <!-- S: Filled -->
        <h2>Filled</h2>
        <p><u>u-swiper-pagination-filled</u></p>
        <p><u>u-swiper-pagination-filled-outlined</u></p>
        <br />

        <v-row align="center">
            <v-col cols="auto">
                <u-swiper-pagination-filled class="swiper-pagination--sm">
                    <span class="swiper-pagination-bullet swiper-pagination-bullet-active"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                </u-swiper-pagination-filled>
            </v-col>
            <v-col cols="auto">
                <u-swiper-pagination-filled>
                    <span class="swiper-pagination-bullet swiper-pagination-bullet-active"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                </u-swiper-pagination-filled>
            </v-col>
            <v-col cols="auto">
                <u-swiper-pagination-filled-outlined class="swiper-pagination--sm">
                    <span class="swiper-pagination-bullet swiper-pagination-bullet-active"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                </u-swiper-pagination-filled-outlined>
            </v-col>
            <v-col cols="auto">
                <u-swiper-pagination-filled-outlined>
                    <span class="swiper-pagination-bullet swiper-pagination-bullet-active"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                </u-swiper-pagination-filled-outlined>
            </v-col>
        </v-row>
        <!-- E: Filled -->
        <br />

        <!-- S: Ellipse -->
        <h2>Ellipse</h2>
        <p><u>u-swiper-pagination-ellipse</u></p>
        <p><u>u-swiper-pagination-ellipse-outlined</u></p>
        <br />

        <v-row align="center">
            <v-col cols="auto">
                <u-swiper-pagination-ellipse class="swiper-pagination--sm">
                    <span class="swiper-pagination-bullet swiper-pagination-bullet-active"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                </u-swiper-pagination-ellipse>
            </v-col>
            <v-col cols="auto">
                <u-swiper-pagination-ellipse>
                    <span class="swiper-pagination-bullet swiper-pagination-bullet-active"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                </u-swiper-pagination-ellipse>
            </v-col>
            <v-col cols="auto">
                <u-swiper-pagination-ellipse-outlined class="swiper-pagination--sm">
                    <span class="swiper-pagination-bullet swiper-pagination-bullet-active"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                </u-swiper-pagination-ellipse-outlined>
            </v-col>
            <v-col cols="auto">
                <u-swiper-pagination-ellipse-outlined>
                    <span class="swiper-pagination-bullet swiper-pagination-bullet-active"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                </u-swiper-pagination-ellipse-outlined>
            </v-col>
        </v-row>
        <!-- E: Ellipse -->
        <br />

        <!-- S: Around -->
        <h2>Around</h2>
        <p><u>u-swiper-pagination-around</u></p>
        <p><u>u-swiper-pagination-around-outlined</u></p>
        <p><u>u-swiper-pagination-around-border</u></p>
        <br />

        <v-row align="center">
            <v-col cols="auto">
                <u-swiper-pagination-around class="swiper-pagination--sm">
                    <span class="swiper-pagination-bullet swiper-pagination-bullet-active"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                </u-swiper-pagination-around>
            </v-col>
            <v-col cols="auto">
                <u-swiper-pagination-around>
                    <span class="swiper-pagination-bullet swiper-pagination-bullet-active"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                </u-swiper-pagination-around>
            </v-col>
            <v-col cols="auto">
                <u-swiper-pagination-around-outlined class="swiper-pagination--sm">
                    <span class="swiper-pagination-bullet swiper-pagination-bullet-active"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                </u-swiper-pagination-around-outlined>
            </v-col>
            <v-col cols="auto">
                <u-swiper-pagination-around-outlined>
                    <span class="swiper-pagination-bullet swiper-pagination-bullet-active"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                </u-swiper-pagination-around-outlined>
            </v-col>
            <v-col cols="auto">
                <u-swiper-pagination-around-border class="swiper-pagination--sm">
                    <span class="swiper-pagination-bullet swiper-pagination-bullet-active"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                </u-swiper-pagination-around-border>
            </v-col>
            <v-col cols="auto">
                <u-swiper-pagination-around-border>
                    <span class="swiper-pagination-bullet swiper-pagination-bullet-active"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                </u-swiper-pagination-around-border>
            </v-col>
        </v-row>
        <!-- E: Around -->
        <br />

        <!-- S: Line -->
        <h2>Line</h2>
        <p><u>u-swiper-pagination-line</u></p>
        <p><u>u-swiper-pagination-line-connected</u></p>
        <br />

        <v-row align="center">
            <v-col cols="auto">
                <u-swiper-pagination-line class="swiper-pagination--sm">
                    <span class="swiper-pagination-bullet swiper-pagination-bullet-active"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                </u-swiper-pagination-line>
            </v-col>
            <v-col cols="auto">
                <u-swiper-pagination-line>
                    <span class="swiper-pagination-bullet swiper-pagination-bullet-active"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                </u-swiper-pagination-line>
            </v-col>
            <v-col cols="auto">
                <u-swiper-pagination-line-connected class="swiper-pagination--sm">
                    <span class="swiper-pagination-bullet swiper-pagination-bullet-active"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                </u-swiper-pagination-line-connected>
            </v-col>
            <v-col cols="auto">
                <u-swiper-pagination-line-connected>
                    <span class="swiper-pagination-bullet swiper-pagination-bullet-active"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                </u-swiper-pagination-line-connected>
            </v-col>
        </v-row>
        <!-- E: Line -->
        <br />

        <!-- S: Fraction -->
        <h2>Fraction</h2>
        <p><u>u-swiper-pagination-fraction</u></p>
        <p><u>u-swiper-pagination-fraction-divider</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <u-swiper-pagination-fraction>
                    <span class="swiper-pagination-current">02</span>
                    <span class="swiper-pagination__decoration"></span>
                    <span class="swiper-pagination-total">03</span>
                </u-swiper-pagination-fraction>
            </v-col>
            <v-col cols="auto">
                <u-swiper-pagination-fraction-divider>
                    <span class="swiper-pagination-current">02</span>
                    <span class="swiper-pagination__decoration"></span>
                    <span class="swiper-pagination-total">03</span>
                </u-swiper-pagination-fraction-divider>
            </v-col>
        </v-row>
        <!-- E: Fraction -->
        <br />

        <!-- S: Text -->
        <h2>Text</h2>
        <p><u>u-swiper-pagination-text</u></p>
        <p><u>u-swiper-pagination-text-divider</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <u-swiper-pagination-text>
                    <span class="swiper-pagination-bullet swiper-pagination-bullet-active">01</span>
                    <span class="swiper-pagination-bullet">02</span>
                    <span class="swiper-pagination-bullet">03</span>
                    <span class="swiper-pagination-bullet">04</span>
                </u-swiper-pagination-text>
            </v-col>
            <v-col cols="auto">
                <u-swiper-pagination-text-divider>
                    <span class="swiper-pagination-bullet swiper-pagination-bullet-active">01</span>
                    <span class="swiper-pagination-bullet">02</span>
                    <span class="swiper-pagination-bullet">03</span>
                    <span class="swiper-pagination-bullet">04</span>
                </u-swiper-pagination-text-divider>
            </v-col>
        </v-row>
        <!-- E: Text -->
        <!-- // Styles -->

        <v-divider class="my-30px" />

        <!-- Color -->
        <h1>Color</h1>
        <p><u>swiper-pagination--dark</u></p>
        <p><u>swiper-pagination--light</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <u-swiper-pagination-filled>
                    <span class="swiper-pagination-bullet swiper-pagination-bullet-active"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                </u-swiper-pagination-filled>
            </v-col>
            <v-col cols="auto">
                <u-swiper-pagination-filled class="swiper-pagination--dark">
                    <span class="swiper-pagination-bullet swiper-pagination-bullet-active"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                </u-swiper-pagination-filled>
            </v-col>
            <v-col cols="auto" class="grey pa-20px">
                <u-swiper-pagination-filled class="swiper-pagination--light">
                    <span class="swiper-pagination-bullet swiper-pagination-bullet-active"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                </u-swiper-pagination-filled>
            </v-col>
        </v-row>
        <!-- // Color -->

        <v-divider class="my-30px" />

        <!-- Align -->
        <h1>Border Radius</h1>
        <p><u>swiper-pagination--angular</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <u-swiper-pagination-filled class="swiper-pagination--angular">
                    <span class="swiper-pagination-bullet swiper-pagination-bullet-active"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                </u-swiper-pagination-filled>
            </v-col>
            <v-col cols="auto">
                <u-swiper-pagination-ellipse class="swiper-pagination--angular">
                    <span class="swiper-pagination-bullet swiper-pagination-bullet-active"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                </u-swiper-pagination-ellipse>
            </v-col>
            <v-col cols="auto">
                <u-swiper-pagination-around class="swiper-pagination--angular">
                    <span class="swiper-pagination-bullet swiper-pagination-bullet-active"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                </u-swiper-pagination-around>
            </v-col>
        </v-row>
        <!-- // Color -->

        <v-divider class="my-30px" />

        <!-- Align -->
        <h1>Align</h1>
        <p><u>swiper-pagination--left</u></p>
        <p><u>swiper-pagination--right</u></p>
        <!-- // Color -->
    </div>
</template>

<script>
import GuideApp from "./guide-app.vue";

import SwiperPaginationPrimary from "@/components/publish/parents/swipers/swiper-pagination-primary.vue";
import SwiperPaginationSecondary from "@/components/publish/parents/swipers/swiper-pagination-secondary.vue";
import SwiperPaginationTertiary from "@/components/publish/parents/swipers/swiper-pagination-tertiary.vue";
import SwiperPaginationQuarternary from "@/components/publish/parents/swipers/swiper-pagination-quarternary.vue";

import USwiperPaginationFilled from "@/components/publish/styles/swipers/u-swiper-pagination-filled.vue";
import USwiperPaginationFilledOutlined from "@/components/publish/styles/swipers/u-swiper-pagination-filled-outlined.vue";
import USwiperPaginationEllipse from "@/components/publish/styles/swipers/u-swiper-pagination-ellipse.vue";
import USwiperPaginationEllipseOutlined from "@/components/publish/styles/swipers/u-swiper-pagination-ellipse-outlined.vue";
import USwiperPaginationAround from "@/components/publish/styles/swipers/u-swiper-pagination-around.vue";
import USwiperPaginationAroundOutlined from "@/components/publish/styles/swipers/u-swiper-pagination-around-outlined.vue";
import USwiperPaginationAroundBorder from "@/components/publish/styles/swipers/u-swiper-pagination-around-border.vue";
import USwiperPaginationLine from "@/components/publish/styles/swipers/u-swiper-pagination-line.vue";
import USwiperPaginationLineConnected from "@/components/publish/styles/swipers/u-swiper-pagination-line-connected.vue";
import USwiperPaginationFraction from "@/components/publish/styles/swipers/u-swiper-pagination-fraction.vue";
import USwiperPaginationFractionDivider from "@/components/publish/styles/swipers/u-swiper-pagination-fraction-divider.vue";
import USwiperPaginationText from "@/components/publish/styles/swipers/u-swiper-pagination-text.vue";
import USwiperPaginationTextDivider from "@/components/publish/styles/swipers/u-swiper-pagination-text-divider.vue";

export default {
    props: {},
    components: {
        GuideApp,

        SwiperPaginationPrimary,
        SwiperPaginationSecondary,
        SwiperPaginationTertiary,
        SwiperPaginationQuarternary,

        USwiperPaginationFilled,
        USwiperPaginationFilledOutlined,
        USwiperPaginationEllipse,
        USwiperPaginationEllipseOutlined,
        USwiperPaginationAround,
        USwiperPaginationAroundOutlined,
        USwiperPaginationAroundBorder,
        USwiperPaginationLine,
        USwiperPaginationLineConnected,
        USwiperPaginationFraction,
        USwiperPaginationFractionDivider,
        USwiperPaginationText,
        USwiperPaginationTextDivider,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
// 가이드 작업을 위해 넣어 놓은 스타일입니다.
.swiper-pagination {
    position: relative;
    bottom: auto;
    left: auto;
    transform: none;
}
</style>
