<template>
    <div>
        <!-- Parents -->
        <h1>Parents</h1>
        <p><u>dialog-{parents}</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <dialog-primary v-model="dialogPrimary">
                    <template #activator="{ attrs, on }">
                        <v-btn v-bind="attrs" v-on="on" color="primary">Dialog Primary</v-btn>
                    </template>

                    <template #tit>제목을입력해주세요</template>
                    내용을입력해주세요
                </dialog-primary>
            </v-col>
            <v-col cols="auto">
                <dialog-secondary v-model="dialogSecondary">
                    <template #activator="{ attrs, on }">
                        <v-btn v-bind="attrs" v-on="on" color="primary">Dialog Secondary</v-btn>
                    </template>

                    <template #tit>제목을입력해주세요</template>
                    내용을입력해주세요
                </dialog-secondary>
            </v-col>
            <v-col cols="auto">
                <dialog-tertiary v-model="dialogTertiary">
                    <template #activator="{ attrs, on }">
                        <v-btn v-bind="attrs" v-on="on" color="primary">Dialog Tertiary</v-btn>
                    </template>

                    <template #tit>제목을입력해주세요</template>
                    내용을입력해주세요
                </dialog-tertiary>
            </v-col>
            <v-col cols="auto">
                <dialog-quarternary v-model="dialogQuarternary">
                    <template #activator="{ attrs, on }">
                        <v-btn v-bind="attrs" v-on="on" color="primary">Dialog Quarternary</v-btn>
                    </template>

                    <template #tit>제목을입력해주세요</template>
                    내용을입력해주세요
                </dialog-quarternary>
            </v-col>
        </v-row>
        <!-- // Parents -->

        <v-divider class="my-30px" />

        <!-- Styles -->
        <h1>Styles</h1>
        <!-- S: Flat -->
        <h2>Flat</h2>
        <p><u>u-dialog-flat-{}</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <u-dialog-flat-tile v-model="dialog3">
                    <template #activator="{ attrs, on }">
                        <v-btn v-bind="attrs" v-on="on" color="primary">Open Dialog Tile</v-btn>
                    </template>

                    <template #tit>제목을입력해주세요</template>
                    내용을입력해주세요
                </u-dialog-flat-tile>
            </v-col>
            <v-col cols="auto">
                <u-dialog-flat-rounded v-model="dialog4">
                    <template #activator="{ attrs, on }">
                        <v-btn v-bind="attrs" v-on="on" color="primary">Open Dialog Rounded</v-btn>
                    </template>

                    <template #tit>제목을입력해주세요</template>
                    내용을입력해주세요
                </u-dialog-flat-rounded>
            </v-col>
        </v-row>
        <!-- E: Flat -->
        <br />

        <!-- S: Color Bg -->
        <h2>Color Bg</h2>
        <p><u>u-dialog-color-bg-{}</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <u-dialog-color-bg-tile v-model="dialog5">
                    <template #activator="{ attrs, on }">
                        <v-btn v-bind="attrs" v-on="on" color="primary">Open Dialog Tile</v-btn>
                    </template>

                    <template #tit>제목을입력해주세요</template>
                    내용을입력해주세요
                </u-dialog-color-bg-tile>
            </v-col>
            <v-col cols="auto">
                <u-dialog-color-bg-rounded v-model="dialog6">
                    <template #activator="{ attrs, on }">
                        <v-btn v-bind="attrs" v-on="on" color="primary">Open Dialog Rounded</v-btn>
                    </template>

                    <template #tit>제목을입력해주세요</template>
                    내용을입력해주세요
                </u-dialog-color-bg-rounded>
            </v-col>
        </v-row>
        <!-- E: Color Bg -->
        <br />

        <!-- S: Underline -->
        <h2>Underline</h2>
        <p><u>u-dialog-underline-{}</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <u-dialog-underline-tile v-model="dialog7">
                    <template #activator="{ attrs, on }">
                        <v-btn v-bind="attrs" v-on="on" color="primary">Open Dialog Tile</v-btn>
                    </template>

                    <template #tit>제목을입력해주세요</template>
                    내용을입력해주세요
                </u-dialog-underline-tile>
            </v-col>
            <v-col cols="auto">
                <u-dialog-underline-rounded v-model="dialog8">
                    <template #activator="{ attrs, on }">
                        <v-btn v-bind="attrs" v-on="on" color="primary">Open Dialog Rounded</v-btn>
                    </template>

                    <template #tit>제목을입력해주세요</template>
                    내용을입력해주세요
                </u-dialog-underline-rounded>
            </v-col>
        </v-row>
        <!-- E: Underline -->
        <!-- // Styles -->
    </div>
</template>

<script>
import GuideApp from "./guide-app.vue";

import DialogPrimary from "@/components/publish/parents/dialogs/dialog-primary.vue";
import DialogSecondary from "@/components/publish/parents/dialogs/dialog-secondary.vue";
import DialogTertiary from "@/components/publish/parents/dialogs/dialog-tertiary.vue";
import DialogQuarternary from "@/components/publish/parents/dialogs/dialog-quarternary.vue";

import UDialogFlatTile from "@/components/publish/styles/dialogs/u-dialog-flat-tile.vue";
import UDialogFlatRounded from "@/components/publish/styles/dialogs/u-dialog-flat-rounded.vue";
import UDialogColorBgTile from "@/components/publish/styles/dialogs/u-dialog-color-bg-tile.vue";
import UDialogColorBgRounded from "@/components/publish/styles/dialogs/u-dialog-color-bg-rounded.vue";
import UDialogUnderlineTile from "@/components/publish/styles/dialogs/u-dialog-underline-tile.vue";
import UDialogUnderlineRounded from "@/components/publish/styles/dialogs/u-dialog-underline-rounded.vue";

import UIcon from "@/components/publish/styles/icons/u-icon.vue";
import Tit from "@/components/publish/styles/typography/tit.vue";

export default {
    props: {},
    components: {
        GuideApp,

        DialogPrimary,
        DialogSecondary,
        DialogTertiary,
        DialogQuarternary,

        UDialogFlatTile,
        UDialogFlatRounded,
        UDialogColorBgTile,
        UDialogColorBgRounded,
        UDialogUnderlineTile,
        UDialogUnderlineRounded,

        UIcon,
        Tit,
    },
    data() {
        return {
            dialogPrimary: false,
            dialogSecondary: false,
            dialogTertiary: false,
            dialogQuarternary: false,
            dialog: false,
            dialog2: false,
            dialog3: false,
            dialog4: false,
            dialog5: false,
            dialog6: false,
            dialog7: false,
            dialog8: false,
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
