var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h1', [_vm._v("Parents")]), _vm._m(0), _vm._m(1), _vm._m(2), _c('br'), _c('h2', [_vm._v("Tabs, Windows")]), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "tabs-wrap"
  }, [_c('tabs-primary', {
    on: {
      "change": _vm.TabsPrimaryChange
    },
    model: {
      value: _vm.TabsPrimary,
      callback: function ($$v) {
        _vm.TabsPrimary = $$v;
      },
      expression: "TabsPrimary"
    }
  }, [_c('tab-primary', [_vm._v("Tab Primary1")]), _c('tab-primary', [_vm._v("Tab Primary2")]), _c('tab-primary', [_vm._v("Tab Primary3")])], 1)], 1), _c('window-primary', {
    on: {
      "change": _vm.TabsPrimaryChange
    },
    model: {
      value: _vm.TabsPrimary,
      callback: function ($$v) {
        _vm.TabsPrimary = $$v;
      },
      expression: "TabsPrimary"
    }
  }, [_c('window-item-primary', [_vm._v("Window Primary1")]), _c('window-item-primary', [_vm._v("Window Primary2")]), _c('window-item-primary', [_vm._v("Window Primary3")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "tabs-wrap"
  }, [_c('tabs-secondary', {
    on: {
      "change": _vm.TabsSecondaryChange
    },
    model: {
      value: _vm.TabsSecondary,
      callback: function ($$v) {
        _vm.TabsSecondary = $$v;
      },
      expression: "TabsSecondary"
    }
  }, [_c('tab-secondary', [_vm._v("Tab Secondary1")]), _c('tab-secondary', [_vm._v("Tab Secondary2")]), _c('tab-secondary', [_vm._v("Tab Secondary3")])], 1)], 1), _c('window-secondary', {
    on: {
      "change": _vm.TabsSecondaryChange
    },
    model: {
      value: _vm.TabsSecondary,
      callback: function ($$v) {
        _vm.TabsSecondary = $$v;
      },
      expression: "TabsSecondary"
    }
  }, [_c('window-item-secondary', [_vm._v("Window Secondary1")]), _c('window-item-secondary', [_vm._v("Window Secondary2")]), _c('window-item-secondary', [_vm._v("Window Secondary3")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "tabs-wrap"
  }, [_c('tabs-tertiary', {
    on: {
      "change": _vm.TabsTertiaryChange
    },
    model: {
      value: _vm.TabsTertiary,
      callback: function ($$v) {
        _vm.TabsTertiary = $$v;
      },
      expression: "TabsTertiary"
    }
  }, [_c('tab-tertiary', [_vm._v("Tab Tertiary1")]), _c('tab-tertiary', [_vm._v("Tab Tertiary2")]), _c('tab-tertiary', [_vm._v("Tab Tertiary3")])], 1)], 1), _c('window-tertiary', {
    on: {
      "change": _vm.TabsTertiaryChange
    },
    model: {
      value: _vm.TabsTertiary,
      callback: function ($$v) {
        _vm.TabsTertiary = $$v;
      },
      expression: "TabsTertiary"
    }
  }, [_c('window-item-tertiary', [_vm._v("Window Tertiary1")]), _c('window-item-tertiary', [_vm._v("Window Tertiary2")]), _c('window-item-tertiary', [_vm._v("Window Tertiary3")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "tabs-wrap"
  }, [_c('tabs-quarternary', {
    on: {
      "change": _vm.TabsQuarternaryChange
    },
    model: {
      value: _vm.TabsQuarternary,
      callback: function ($$v) {
        _vm.TabsQuarternary = $$v;
      },
      expression: "TabsQuarternary"
    }
  }, [_c('tab-quarternary', [_vm._v("Tab Quarternary1")]), _c('tab-quarternary', [_vm._v("Tab Quarternary2")]), _c('tab-quarternary', [_vm._v("Tab Quarternary3")])], 1)], 1), _c('window-quarternary', {
    on: {
      "change": _vm.TabsQuarternaryChange
    },
    model: {
      value: _vm.TabsQuarternary,
      callback: function ($$v) {
        _vm.TabsQuarternary = $$v;
      },
      expression: "TabsQuarternary"
    }
  }, [_c('window-item-quarternary', [_vm._v("Window Quarternary1")]), _c('window-item-quarternary', [_vm._v("Window Quarternary2")]), _c('window-item-quarternary', [_vm._v("Window Quarternary3")])], 1)], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Styles")]), _c('h2', [_vm._v("Filled")]), _vm._m(3), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('u-tabs-filled', [_c('v-tab', [_vm._v("Tab")]), _c('v-tab', [_vm._v("Tab")]), _c('v-tab', [_vm._v("Tab")]), _c('v-tab', [_vm._v("Tab")]), _c('v-tab', [_vm._v("Tab")])], 1)], 1)], 1), _c('br'), _c('h2', [_vm._v("Border")]), _vm._m(4), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('u-tabs-border', [_c('v-tab', [_vm._v("Tab")]), _c('v-tab', [_vm._v("Tab")]), _c('v-tab', [_vm._v("Tab")]), _c('v-tab', [_vm._v("Tab")]), _c('v-tab', [_vm._v("Tab")])], 1)], 1)], 1), _c('br'), _c('h2', [_vm._v("Slider")]), _vm._m(5), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('u-tabs-slider', [_c('v-tab', [_vm._v("Tab")]), _c('v-tab', [_vm._v("Tab")]), _c('v-tab', [_vm._v("Tab")]), _c('v-tab', [_vm._v("Tab")]), _c('v-tab', [_vm._v("Tab")])], 1)], 1)], 1), _c('br'), _c('h2', [_vm._v("Dot")]), _vm._m(6), _vm._m(7), _c('p', [_vm._v("u-tabs-dot, u-tab-dot 세트로 사용합니다.")]), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('u-tabs-dot', [_c('u-tab-dot', [_vm._v("Tab")]), _c('u-tab-dot', [_vm._v("Tab")]), _c('u-tab-dot', [_vm._v("Tab")]), _c('u-tab-dot', [_vm._v("Tab")]), _c('u-tab-dot', [_vm._v("Tab")])], 1)], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Density")]), _c('h3', [_vm._v("{density} : compact, 기본-comfortable, default")]), _vm._m(8), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-tabs', _vm._b({
    staticClass: "v-tabs--density-compact",
    attrs: {
      "grow": false
    }
  }, 'v-tabs', Object.assign({}, _vm.attrs_tabs_common), false), [_c('v-tab', [_vm._v("Tab")]), _c('v-tab', [_vm._v("Tab")]), _c('v-tab', [_vm._v("Tab")]), _c('v-tab', [_vm._v("Tab")]), _c('v-tab', [_vm._v("Tab")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-tabs', _vm._b({
    attrs: {
      "grow": false
    }
  }, 'v-tabs', Object.assign({}, _vm.attrs_tabs_common), false), [_c('v-tab', [_vm._v("Tab")]), _c('v-tab', [_vm._v("Tab")]), _c('v-tab', [_vm._v("Tab")]), _c('v-tab', [_vm._v("Tab")]), _c('v-tab', [_vm._v("Tab")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-tabs', _vm._b({
    staticClass: "v-tabs--density-default",
    attrs: {
      "grow": false
    }
  }, 'v-tabs', Object.assign({}, _vm.attrs_tabs_common), false), [_c('v-tab', [_vm._v("Tab")]), _c('v-tab', [_vm._v("Tab")]), _c('v-tab', [_vm._v("Tab")]), _c('v-tab', [_vm._v("Tab")]), _c('v-tab', [_vm._v("Tab")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-tabs', _vm._b({
    staticClass: "v-tabs--density-compact"
  }, 'v-tabs', Object.assign({}, _vm.attrs_tabs_common), false), [_c('v-tab', [_vm._v("Tab")]), _c('v-tab', [_vm._v("Tab")]), _c('v-tab', [_vm._v("Tab")]), _c('v-tab', [_vm._v("Tab")]), _c('v-tab', [_vm._v("Tab")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-tabs', _vm._b({}, 'v-tabs', Object.assign({}, _vm.attrs_tabs_common), false), [_c('v-tab', [_vm._v("Tab")]), _c('v-tab', [_vm._v("Tab")]), _c('v-tab', [_vm._v("Tab")]), _c('v-tab', [_vm._v("Tab")]), _c('v-tab', [_vm._v("Tab")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-tabs', _vm._b({
    staticClass: "v-tabs--density-default"
  }, 'v-tabs', Object.assign({}, _vm.attrs_tabs_common), false), [_c('v-tab', [_vm._v("Tab")]), _c('v-tab', [_vm._v("Tab")]), _c('v-tab', [_vm._v("Tab")]), _c('v-tab', [_vm._v("Tab")]), _c('v-tab', [_vm._v("Tab")])], 1)], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Show Arrows")]), _vm._m(9), _c('p', [_vm._v("show-arrows와 같이 사용합니다.")]), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-tabs', _vm._b({
    staticClass: "v-tabs--arrows-gradient",
    attrs: {
      "show-arrows": ""
    }
  }, 'v-tabs', Object.assign({}, _vm.attrs_tabs_common, _vm.$attrs), false), [_c('v-tab', [_vm._v("TabTabTab")]), _c('v-tab', [_vm._v("TabTabTab")]), _c('v-tab', [_vm._v("TabTabTab")]), _c('v-tab', [_vm._v("TabTabTab")]), _c('v-tab', [_vm._v("TabTabTab")]), _c('v-tab', [_vm._v("TabTabTab")]), _c('v-tab', [_vm._v("TabTabTab")]), _c('v-tab', [_vm._v("TabTabTab")]), _c('v-tab', [_vm._v("TabTabTab")]), _c('v-tab', [_vm._v("TabTabTab")]), _c('v-tab', [_vm._v("TabTabTab")]), _c('v-tab', [_vm._v("TabTabTab")]), _c('v-tab', [_vm._v("TabTabTab")]), _c('v-tab', [_vm._v("TabTabTab")]), _c('v-tab', [_vm._v("TabTabTab")]), _c('v-tab', [_vm._v("TabTabTab")]), _c('v-tab', [_vm._v("TabTabTab")]), _c('v-tab', [_vm._v("TabTabTab")]), _c('v-tab', [_vm._v("TabTabTab")]), _c('v-tab', [_vm._v("TabTabTab")])], 1)], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Filled")]), _c('h2', [_vm._v("Default")]), _vm._m(10), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-tabs', _vm._b({
    staticClass: "v-tabs--filled",
    attrs: {
      "grow": false
    }
  }, 'v-tabs', Object.assign({}, _vm.attrs_tabs_common), false), [_c('v-tab', [_vm._v("Tab")]), _c('v-tab', [_vm._v("Tab")]), _c('v-tab', [_vm._v("Tab")]), _c('v-tab', [_vm._v("Tab")]), _c('v-tab', [_vm._v("Tab")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-tabs', _vm._b({
    staticClass: "v-tabs--filled"
  }, 'v-tabs', Object.assign({}, _vm.attrs_tabs_common), false), [_c('v-tab', [_vm._v("Tab")]), _c('v-tab', [_vm._v("Tab")]), _c('v-tab', [_vm._v("Tab")]), _c('v-tab', [_vm._v("Tab")]), _c('v-tab', [_vm._v("Tab")])], 1)], 1)], 1), _c('br'), _c('h2', [_vm._v("Wide")]), _vm._m(11), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-tabs', _vm._b({
    staticClass: "v-tabs--wide-filled",
    attrs: {
      "grow": false
    }
  }, 'v-tabs', Object.assign({}, _vm.attrs_tabs_common), false), [_c('v-tab', [_vm._v("Tab")]), _c('v-tab', [_vm._v("Tab")]), _c('v-tab', [_vm._v("Tab")]), _c('v-tab', [_vm._v("Tab")]), _c('v-tab', [_vm._v("Tab")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-tabs', _vm._b({
    staticClass: "v-tabs--wide-filled"
  }, 'v-tabs', Object.assign({}, _vm.attrs_tabs_common), false), [_c('v-tab', [_vm._v("Tab")]), _c('v-tab', [_vm._v("Tab")]), _c('v-tab', [_vm._v("Tab")]), _c('v-tab', [_vm._v("Tab")]), _c('v-tab', [_vm._v("Tab")])], 1)], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Border")]), _c('h2', [_vm._v("Default")]), _vm._m(12), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-tabs', _vm._b({
    staticClass: "v-tabs--border",
    attrs: {
      "grow": false
    }
  }, 'v-tabs', Object.assign({}, _vm.attrs_tabs_common), false), [_c('v-tab', [_vm._v("Tab")]), _c('v-tab', [_vm._v("Tab")]), _c('v-tab', [_vm._v("Tab")]), _c('v-tab', [_vm._v("Tab")]), _c('v-tab', [_vm._v("Tab")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-tabs', _vm._b({
    staticClass: "v-tabs--border"
  }, 'v-tabs', Object.assign({}, _vm.attrs_tabs_common), false), [_c('v-tab', [_vm._v("Tab")]), _c('v-tab', [_vm._v("Tab")]), _c('v-tab', [_vm._v("Tab")]), _c('v-tab', [_vm._v("Tab")]), _c('v-tab', [_vm._v("Tab")])], 1)], 1)], 1), _c('br'), _c('h2', [_vm._v("Wide")]), _vm._m(13), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-tabs', _vm._b({
    staticClass: "v-tabs--wide-border",
    attrs: {
      "grow": false
    }
  }, 'v-tabs', Object.assign({}, _vm.attrs_tabs_common), false), [_c('v-tab', [_vm._v("Tab")]), _c('v-tab', [_vm._v("Tab")]), _c('v-tab', [_vm._v("Tab")]), _c('v-tab', [_vm._v("Tab")]), _c('v-tab', [_vm._v("Tab")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-tabs', _vm._b({
    staticClass: "v-tabs--wide-border"
  }, 'v-tabs', Object.assign({}, _vm.attrs_tabs_common), false), [_c('v-tab', [_vm._v("Tab")]), _c('v-tab', [_vm._v("Tab")]), _c('v-tab', [_vm._v("Tab")]), _c('v-tab', [_vm._v("Tab")]), _c('v-tab', [_vm._v("Tab")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("tabs-{parents}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("window-{parents}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("window-item-{parents}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-tabs-filled")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-tabs-border")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-tabs-slider")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-tabs-dot")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-tab-dot")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("class=\"v-tabs--density-{density}\"")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("show-arrows class=\"v-tabs--arrows-gradient\"")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("v-tabs--filled")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("v-tabs--wide-filled")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("v-tabs--border")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("v-tabs--wide-border")])]);

}]

export { render, staticRenderFns }