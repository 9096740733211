<template>
    <div>
        <p>사용시 tabs-{}, tab-{} 클래스명은 지우고 사용해주세요.</p>
        <p>템플릿 작업을 위해 넣어 놓은 클래스명입니다.</p>
        <br />
        <!-- Styles -->
        <h1>Styles</h1>
        <!-- S: Filled -->
        <h2>Filled</h2>
        <br />
        <div class="tabs-wrap">
            <u-tabs-filled>
                <u-tab-filled class="tab-filled">Tab</u-tab-filled>
                <u-tab-filled class="tab-filled">Tab</u-tab-filled>
                <u-tab-filled class="tab-filled">Tab</u-tab-filled>
                <u-tab-filled class="tab-filled">Tab</u-tab-filled>
                <u-tab-filled class="tab-filled">Tab</u-tab-filled>
            </u-tabs-filled>
        </div>
        <div class="tabs-wrap">
            <u-tabs-filled class="v-tabs--wide-filled tabs-filled2">
                <u-tab-filled class="tab-filled2">Tab</u-tab-filled>
                <u-tab-filled class="tab-filled2">Tab</u-tab-filled>
                <u-tab-filled class="tab-filled2">Tab</u-tab-filled>
                <u-tab-filled class="tab-filled2">Tab</u-tab-filled>
                <u-tab-filled class="tab-filled2">Tab</u-tab-filled>
            </u-tabs-filled>
        </div>
        <div class="tabs-wrap">
            <u-tabs-filled class="v-tabs--wide-filled tabs-filled3">
                <u-tab-filled class="tab-filled3">Tab</u-tab-filled>
                <u-tab-filled class="tab-filled3">Tab</u-tab-filled>
                <u-tab-filled class="tab-filled3">Tab</u-tab-filled>
                <u-tab-filled class="tab-filled3">Tab</u-tab-filled>
                <u-tab-filled class="tab-filled3">Tab</u-tab-filled>
            </u-tabs-filled>
        </div>
        <div class="tabs-wrap">
            <u-tabs-filled class="v-tabs--wide-filled tabs-filled4">
                <u-tab-filled class="tab-filled4">Tab</u-tab-filled>
                <u-tab-filled class="tab-filled4">Tab</u-tab-filled>
                <u-tab-filled class="tab-filled4">Tab</u-tab-filled>
                <u-tab-filled class="tab-filled4">Tab</u-tab-filled>
                <u-tab-filled class="tab-filled4">Tab</u-tab-filled>
            </u-tabs-filled>
        </div>
        <div class="tabs-wrap">
            <u-tabs-filled class="v-tabs--wide-filled tabs-filled5">
                <u-tab-filled class="tab-filled5">Tab</u-tab-filled>
                <u-tab-filled class="tab-filled5">Tab</u-tab-filled>
                <u-tab-filled class="tab-filled5">Tab</u-tab-filled>
                <u-tab-filled class="tab-filled5">Tab</u-tab-filled>
                <u-tab-filled class="tab-filled5">Tab</u-tab-filled>
            </u-tabs-filled>
        </div>
        <!-- E: Filled -->
        <br />

        <!-- S: Slider -->
        <h2>Slider</h2>
        <br />
        <div class="tabs-wrap">
            <u-tabs-slider>
                <v-tab>Tab</v-tab>
                <v-tab>Tab</v-tab>
                <v-tab>Tab</v-tab>
                <v-tab>Tab</v-tab>
                <v-tab>Tab</v-tab>
            </u-tabs-slider>
        </div>
        <div class="tabs-wrap">
            <u-tabs-border centered :grow="false" class="v-tabs--wide-border">
                <u-tab-slider>Tab</u-tab-slider>
                <u-tab-slider>Tab</u-tab-slider>
                <u-tab-slider>Tab</u-tab-slider>
                <u-tab-slider>Tab</u-tab-slider>
                <u-tab-slider>Tab</u-tab-slider>
            </u-tabs-border>
        </div>
        <div class="tabs-wrap">
            <u-tabs-slider color="white" class="v-tabs--wide-filled tabs-slider">
                <v-tab class="tab-slider">Tab</v-tab>
                <v-tab class="tab-slider">Tab</v-tab>
                <v-tab class="tab-slider">Tab</v-tab>
                <v-tab class="tab-slider">Tab</v-tab>
                <v-tab class="tab-slider">Tab</v-tab>
            </u-tabs-slider>
        </div>
        <div class="tabs-wrap">
            <u-tabs-filled centered :grow="false" color="white" class="v-tabs--wide-filled tabs-slider2">
                <u-tab-slider class="tab-slider2">Tab</u-tab-slider>
                <u-tab-slider class="tab-slider2">Tab</u-tab-slider>
                <u-tab-slider class="tab-slider2">Tab</u-tab-slider>
                <u-tab-slider class="tab-slider2">Tab</u-tab-slider>
                <u-tab-slider class="tab-slider2">Tab</u-tab-slider>
            </u-tabs-filled>
        </div>
        <!-- E: Slider -->
        <br />

        <!-- S: Dot -->
        <h2>Dot</h2>
        <br />
        <div class="tabs-wrap">
            <u-tabs-dot class="v-tabs--wide-border">
                <u-tab-dot>Tab</u-tab-dot>
                <u-tab-dot>Tab</u-tab-dot>
                <u-tab-dot>Tab</u-tab-dot>
                <u-tab-dot>Tab</u-tab-dot>
                <u-tab-dot>Tab</u-tab-dot>
            </u-tabs-dot>
        </div>
        <!-- E: Dot -->
        <br />

        <!-- S: Button -->
        <h2>Button</h2>
        <br />
        <div class="tabs-wrap">
            <v-tabs v-bind="{ ...attrs_tabs_common, ...$attrs }">
                <u-tab-button>Tab</u-tab-button>
                <u-tab-button>Tab</u-tab-button>
                <u-tab-button>Tab</u-tab-button>
                <u-tab-button>Tab</u-tab-button>
                <u-tab-button>Tab</u-tab-button>
            </v-tabs>
        </div>
        <div class="tabs-wrap">
            <u-tabs-border class="pb-10px pb-md-16px">
                <u-tab-button class="tab-button">Tab</u-tab-button>
                <u-tab-button class="tab-button">Tab</u-tab-button>
                <u-tab-button class="tab-button">Tab</u-tab-button>
                <u-tab-button class="tab-button">Tab</u-tab-button>
                <u-tab-button class="tab-button">Tab</u-tab-button>
            </u-tabs-border>
        </div>
        <!-- E: Button -->
        <br />

        <!-- S: Punch -->
        <h2>Punch</h2>
        <br />
        <div class="tabs-wrap">
            <v-tabs v-bind="{ ...attrs_tabs_common, ...$attrs }">
                <u-tab-punch>Tab</u-tab-punch>
                <u-tab-punch>Tab</u-tab-punch>
                <u-tab-punch>Tab</u-tab-punch>
                <u-tab-punch>Tab</u-tab-punch>
                <u-tab-punch>Tab</u-tab-punch>
            </v-tabs>
        </div>
        <div class="tabs-wrap">
            <u-tabs-filled>
                <u-tab-punch class="tab-punch">Tab</u-tab-punch>
                <u-tab-punch class="tab-punch">Tab</u-tab-punch>
                <u-tab-punch class="tab-punch">Tab</u-tab-punch>
                <u-tab-punch class="tab-punch">Tab</u-tab-punch>
                <u-tab-punch class="tab-punch">Tab</u-tab-punch>
            </u-tabs-filled>
        </div>
        <!-- E: Punch -->
        <!-- // Styles -->
    </div>
</template>

<script>
import { attrs_tabs_common } from "@/assets/variables";

import UTabsFilled from "@/components/publish/styles/tabs/u-tabs-filled.vue";
import UTabsBorder from "@/components/publish/styles/tabs/u-tabs-border.vue";
import UTabsSlider from "@/components/publish/styles/tabs/u-tabs-slider.vue";
import UTabsDot from "@/components/publish/styles/tabs/u-tabs-dot.vue";

import UTabFilled from "@/components/publish/styles/tabs/u-tab-filled.vue";
import UTabSlider from "@/components/publish/styles/tabs/u-tab-slider.vue";
import UTabDot from "@/components/publish/styles/tabs/u-tab-dot.vue";
import UTabButton from "@/components/publish/styles/tabs/u-tab-button.vue";
import UTabPunch from "@/components/publish/styles/tabs/u-tab-punch.vue";

export default {
    props: {},
    components: {
        UTabsFilled,
        UTabsBorder,
        UTabsSlider,
        UTabsDot,

        UTabFilled,
        UTabSlider,
        UTabDot,
        UTabButton,
        UTabPunch,
    },
    data() {
        return {
            attrs_tabs_common,
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
// 해당되는 스타일만 복사해서 사용해주세요.
// Filled
.tab-filled {
    color: var(--v-grey-base) !important;
}

.tabs-filled2 {
    background-color: transparent !important;
    &:before {
        background-color: rgba(0, 0, 0, 0.5) !important;
    }
}
.tab-filled2 {
    color: #fff !important;
    background-color: transparent !important;
    &.v-tab {
        &--active {
            color: var(--v-primary-base) !important;
            background-color: #fff !important;
        }
    }
}

.tabs-filled3 {
    background-color: var(--v-primary-base) !important;
}
.tab-filled3 {
    color: #fff !important;
    background-color: transparent !important;
    &.v-tab {
        &--active {
            color: var(--v-primary-base) !important;
            background-color: #fff !important;
        }
    }
}

.tabs-filled4 {
    background-color: transparent !important;
    &:before {
        background-color: rgba(0, 0, 0, 0.5) !important;
    }
}
.tab-filled4 {
    color: #fff !important;
    background-color: transparent !important;
    &.v-tab {
        &--active {
            color: #fff !important;
            background-color: rgba(255, 255, 255, 0.2) !important;
        }
    }
}

.tabs-filled5 {
    background-color: var(--v-primary-base) !important;
}
.tab-filled5 {
    color: #fff !important;
    background-color: transparent !important;
    &.v-tab {
        &--active {
            color: #fff !important;
            background-color: rgba(255, 255, 255, 0.2) !important;
        }
    }
}

// Slider
.tabs-slider,
.tabs-slider2 {
    background-color: transparent !important;
    &:before {
        background-color: rgba(0, 0, 0, 0.5) !important;
    }
}
.tab-slider,
.tab-slider2 {
    color: #fff !important;
    &.v-tab {
        &--active {
            color: #fff !important;
        }
    }
}

// Button
.tab-button {
    color: var(--tab-color) !important;
    background-color: transparent !important;
}

// Punch
.tab-punch{
    color: var(--v-grey-base) !important;
    background-color: var(--v-grey-lighten5) !important;
}
</style>
