<template>
    <div class="v-divider--center">
        <span class="v-divider__txt"><slot /></span>
        <v-divider v-bind="$attrs"></v-divider>
    </div>
</template>

<script>
export default {
    props: {},
    components: {},
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
