<template>
    <div>
        <v-tabs v-model="tab" grow class="mb-30px">
            <v-tab>Pagination</v-tab>
            <v-tab>Button</v-tab>
        </v-tabs>

        <v-window v-model="tab">
            <v-window-item><SwipersPagination /></v-window-item>
            <v-window-item><SwipersButton /></v-window-item>
        </v-window>
    </div>
</template>

<script>
import GuideApp from "./guide-app.vue";

import SwipersPagination from "./swipers-pagination.vue";
import SwipersButton from "./swipers-button.vue";

export default {
    props: {},
    components: {
        GuideApp,

        SwipersPagination,
        SwipersButton,
    },
    data() {
        return {
            tab: null,
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
