var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('scrolldown', _vm._b({
    staticClass: "scrolldown--arrow"
  }, 'scrolldown', _vm.$attrs, false), [_vm.reverse ? [_c('div', {
    staticClass: "scrolldown__txt"
  }, [_vm._v("Scroll Down")]), _c('div', {
    staticClass: "scrolldown__arrow"
  }, [this.$slots['arrow'] ? _vm._t("arrow") : _c('icon-chevron', {
    attrs: {
      "size": "small",
      "direction": "down"
    }
  })], 2)] : [_c('div', {
    staticClass: "scrolldown__arrow"
  }, [this.$slots['arrow'] ? _vm._t("arrow") : _c('icon-chevron', {
    attrs: {
      "size": "small",
      "direction": "down"
    }
  })], 2), _c('div', {
    staticClass: "scrolldown__txt"
  }, [_vm._v("Scroll Down")])]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }