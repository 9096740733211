<template>
    <v-file-input v-bind="{ ...attrs_file_input_common, ...$attrs }" solo class="rounded-pill">
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </v-file-input>
</template>

<script>
import { attrs_file_input_common } from "@/assets/variables";

export default {
    props: {
    },
    components: {},
    data() {
        return {
            attrs_file_input_common,
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
