<template>
    <u-pagination-outlined-rounded v-bind="$attrs"></u-pagination-outlined-rounded>
</template>

<script>
import UPaginationOutlinedRounded from "@/components/publish/styles/paginations/u-pagination-outlined-rounded.vue";

export default {
    props: {},
    components: {
        UPaginationOutlinedRounded,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
