var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-divider', _vm._b({
    staticClass: "primary",
    staticStyle: {
      "border-width": "4px 0 0"
    }
  }, 'v-divider', _vm.$attrs, false));

}
var staticRenderFns = []

export { render, staticRenderFns }