<template>
    <swiper-pagination v-bind="$attrs" class="swiper-pagination--line-connected"><slot /></swiper-pagination>
</template>

<script>
import SwiperPagination from "@/components/publish/styles/swipers/swiper-pagination.vue";

export default {
    props: {},
    components: { SwiperPagination },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.swiper-pagination--line-connected {
    ::v-deep {
        .swiper-pagination {
            &-bullet {
                width: 40px;
                height: 2px;
                margin: 0;
                border: 0;
                border-radius: 0;
                &::before,
                &::after{
                    display: none;
                }
                &-active{
                    opacity: 1;
                }
            }
        }
    }

    &.swiper-pagination {
        &--sm {
            ::v-deep {
                .swiper-pagination {
                    &-bullet {
                        width: 30px;
                        margin: 0;
                    }
                }
            }
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .swiper-pagination--line-connected {
        ::v-deep {
            .swiper-pagination {
                &-bullet {
                    width: 80px;
                }
            }
        }

        &.swiper-pagination {
            &--sm {
                ::v-deep {
                    .swiper-pagination {
                        &-bullet {
                            width: 40px;
                        }
                    }
                }
            }
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
</style>
