var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-scrolldown-mouse', _vm._b({}, 'u-scrolldown-mouse', _vm.$attrs, false));

}
var staticRenderFns = []

export { render, staticRenderFns }