<template>
    <div>
        <!-- Parents -->
        <h1>Parents</h1>
        <p><u>footer-{parents}</u></p>
        <br />
        <v-row align="center">
            <v-col cols="12">
                <footer-primary></footer-primary>
            </v-col>
        </v-row>
        <!-- // Parents -->
    </div>
</template>

<script>
import FooterPrimary from "@/sets/parents/footers/footer-primary.vue";

export default {
    props: {},
    components: {
        FooterPrimary,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
