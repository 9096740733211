var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h1', [_vm._v("Parents")]), _vm._m(0), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('header-search-primary', {
    staticClass: "primary--text"
  })], 1), _c('v-col', {
    staticClass: "grey",
    attrs: {
      "cols": "auto"
    }
  }, [_c('header-search-secondary')], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Styles")]), _c('h2', [_vm._v("Input")]), _vm._m(1), _vm._m(2), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-header-search-outlined-pill')], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-header-search-outlined-tile')], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-header-search-underlined')], 1)], 1), _c('br'), _c('h2', [_vm._v("Button")]), _vm._m(3), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-header-search-btn')], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Color")]), _vm._m(4), _c('br'), _c('div', {
    staticClass: "grey pa-20px"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-header-search-outlined-pill', {
    staticClass: "header-search--light"
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-header-search-outlined-tile', {
    staticClass: "header-search--light"
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-header-search-underlined', {
    staticClass: "header-search--light"
  })], 1), _c('v-col', {
    staticClass: "grey",
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-header-search-btn', {
    staticClass: "header-search--light"
  })], 1)], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Type")]), _c('h2', [_vm._v("Input")]), _vm._m(5), _c('br'), _c('h2', [_vm._v("Button")]), _vm._m(6), _vm._m(7)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("header-search-{parents}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-header-search-outlined-{pill|tile}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-header-search-underlined")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-header-search-btn")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("header-search--light")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("<header-search></header-search>")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("type=\"button\"")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("<header-search type=\"button\"></header-search>")])]);

}]

export { render, staticRenderFns }