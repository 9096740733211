<template>
    <div>
        <!-- Parents -->
        <h1>Parents</h1>
        <p><u>text-field-{parents}</u></p>
        <br />
        <v-row align="center">
            <v-col cols="4" md="2">
                <text-field-primary v-model="forms.test" label="Text Field Primary" class="v-input--density-compact" />
            </v-col>
            <v-col cols="4" md="2">
                <text-field-secondary v-model="forms.test" placeholder="Text Field Secondary" class="v-input--density-default" />
            </v-col>
            <v-col cols="4" md="2">
                <text-field-tertiary v-model="forms.test" label="Text Field Tertiary" />
            </v-col>
            <v-col cols="4" md="2">
                <text-field-quarternary v-model="forms.test" placeholder="Text Field Quarternary" />
            </v-col>
        </v-row>
        <!-- // Parents -->

        <v-divider class="my-30px" />

        <!-- Styles -->
        <h1>Styles</h1>
        <!-- S: Underlined -->
        <h2>Underlined</h2>
        <p><u>u-text-field-underlined</u></p>
        <br />
        <v-row align="center">
            <v-col cols="4" md="2">
                <u-text-field-underlined label="Underlined Label"></u-text-field-underlined>
            </v-col>
            <v-col cols="4" md="2">
                <u-text-field-underlined placeholder="Underlined Placeholder"></u-text-field-underlined>
            </v-col>
        </v-row>
        <!-- E: Underlined -->
        <br />

        <!-- S: Outlined -->
        <h2>Outlined</h2>
        <p><u>u-text-field-outlined-{}</u></p>
        <br />
        <v-row align="center">
            <v-col cols="4" md="2">
                <u-text-field-outlined-tile label="Outlined Tile Label"></u-text-field-outlined-tile>
            </v-col>
            <v-col cols="4" md="2">
                <u-text-field-outlined-rounded label="Outlined Rounded Label"></u-text-field-outlined-rounded>
            </v-col>
            <v-col cols="4" md="2">
                <u-text-field-outlined-pill label="Outlined Pill Label"></u-text-field-outlined-pill>
            </v-col>
            <v-col cols="4" md="2">
                <u-text-field-outlined-tile label="Outlined Tile Label Inner" class="v-text-field--label-inner"></u-text-field-outlined-tile>
            </v-col>
            <v-col cols="4" md="2">
                <u-text-field-outlined-rounded label="Outlined Rounded Label Inner" class="v-text-field--label-inner"></u-text-field-outlined-rounded>
            </v-col>
            <v-col cols="4" md="2">
                <u-text-field-outlined-pill label="Outlined Pill Label Inner" class="v-text-field--label-inner"></u-text-field-outlined-pill>
            </v-col>
            <v-col cols="4" md="2">
                <u-text-field-outlined-tile placeholder="Outlined Tile Placeholder"></u-text-field-outlined-tile>
            </v-col>
            <v-col cols="4" md="2">
                <u-text-field-outlined-rounded placeholder="Outlined Rounded Placeholder"></u-text-field-outlined-rounded>
            </v-col>
            <v-col cols="4" md="2">
                <u-text-field-outlined-pill placeholder="Outlined Pill Placeholder"></u-text-field-outlined-pill>
            </v-col>
        </v-row>
        <!-- E: Outlined -->
        <br />

        <!-- S: Filled -->
        <h2>Filled</h2>
        <p><u>u-text-field-filled-{}</u></p>
        <br />
        <v-row align="center">
            <v-col cols="4" md="2">
                <u-text-field-filled-tile label="Filled Tile Label"></u-text-field-filled-tile>
            </v-col>
            <v-col cols="4" md="2">
                <u-text-field-filled-rounded label="Filled Rounded Label"></u-text-field-filled-rounded>
            </v-col>
            <v-col cols="4" md="2">
                <u-text-field-filled-pill label="Filled Pill Label"></u-text-field-filled-pill>
            </v-col>
            <v-col cols="4" md="2">
                <u-text-field-filled-tile placeholder="Filled Tile Placeholder"></u-text-field-filled-tile>
            </v-col>
            <v-col cols="4" md="2">
                <u-text-field-filled-rounded placeholder="Filled Rounded Placeholder"></u-text-field-filled-rounded>
            </v-col>
            <v-col cols="4" md="2">
                <u-text-field-filled-pill placeholder="Filled Pill Placeholder"></u-text-field-filled-pill>
            </v-col>
        </v-row>
        <!-- E: Filled -->
        <br />

        <!-- S: Solo -->
        <h2>Solo</h2>
        <p><u>u-text-field-solo-{}</u></p>
        <br />
        <v-row align="center">
            <v-col cols="4" md="2">
                <u-text-field-solo-tile placeholder="Solo Tile Placeholder"></u-text-field-solo-tile>
            </v-col>
            <v-col cols="4" md="2">
                <u-text-field-solo-rounded placeholder="Solo Rounded Placeholder"></u-text-field-solo-rounded>
            </v-col>
            <v-col cols="4" md="2">
                <u-text-field-solo-pill placeholder="Solo Pill Placeholder"></u-text-field-solo-pill>
            </v-col>
        </v-row>
        <!-- E: Solo -->
        <!-- // Styles -->

        <v-divider class="my-30px" />

        <!-- Density -->
        <h1>Density</h1>
        <h3>{density} : compact, (기본-comfortable), default</h3>
        <p><u>class="v-input--density-{density}"</u></p>
        <br />
        <!-- S: Underlined -->
        <h2>Underlined</h2>
        <v-row align="center">
            <v-col cols="4" md="2">
                <v-text-field v-bind="{ ...attrs_input_common, ...$attrs }" label="Label" class="v-input--density-compact"></v-text-field>
            </v-col>
            <v-col cols="4" md="2">
                <v-text-field v-bind="{ ...attrs_input_common, ...$attrs }" label="Label"></v-text-field>
            </v-col>
            <v-col cols="4" md="2">
                <v-text-field v-bind="{ ...attrs_input_common, ...$attrs }" label="Label" class="v-input--density-default"></v-text-field>
            </v-col>
            <v-col cols="4" md="2">
                <v-text-field v-bind="{ ...attrs_input_common, ...$attrs }" placeholder="Placeholder" class="v-input--density-compact"></v-text-field>
            </v-col>
            <v-col cols="4" md="2">
                <v-text-field v-bind="{ ...attrs_input_common, ...$attrs }" placeholder="Placeholder"></v-text-field>
            </v-col>
            <v-col cols="4" md="2">
                <v-text-field v-bind="{ ...attrs_input_common, ...$attrs }" placeholder="Placeholder" class="v-input--density-default"></v-text-field>
            </v-col>
        </v-row>
        <!-- E: Underlined -->
        <br />

        <!-- S: Outlined -->
        <h2>Outlined</h2>
        <v-row align="center">
            <v-col cols="4" md="2">
                <v-text-field v-bind="{ ...attrs_input_common, ...$attrs }" outlined label="Label" class="v-input--density-compact"></v-text-field>
            </v-col>
            <v-col cols="4" md="2">
                <v-text-field v-bind="{ ...attrs_input_common, ...$attrs }" outlined label="Label"></v-text-field>
            </v-col>
            <v-col cols="4" md="2">
                <v-text-field v-bind="{ ...attrs_input_common, ...$attrs }" outlined label="Label" class="v-input--density-default"></v-text-field>
            </v-col>
            <v-col cols="4" md="2">
                <v-text-field v-bind="{ ...attrs_input_common, ...$attrs }" outlined placeholder="Placeholder" class="v-input--density-compact"></v-text-field>
            </v-col>
            <v-col cols="4" md="2">
                <v-text-field v-bind="{ ...attrs_input_common, ...$attrs }" outlined placeholder="Placeholder"></v-text-field>
            </v-col>
            <v-col cols="4" md="2">
                <v-text-field v-bind="{ ...attrs_input_common, ...$attrs }" outlined placeholder="Placeholder" class="v-input--density-default"></v-text-field>
            </v-col>
        </v-row>
        <!-- E: Outlined -->
        <br />

        <!-- S: Label Inner -->
        <h2>Label Inner</h2>
        <v-row align="center">
            <v-col cols="4" md="2">
                <v-text-field v-bind="{ ...attrs_input_common, ...$attrs }" outlined label="Label" class="v-input--density-compact v-text-field--label-inner"></v-text-field>
            </v-col>
            <v-col cols="4" md="2">
                <v-text-field v-bind="{ ...attrs_input_common, ...$attrs }" outlined label="Label" class="v-text-field--label-inner"></v-text-field>
            </v-col>
            <v-col cols="4" md="2">
                <v-text-field v-bind="{ ...attrs_input_common, ...$attrs }" outlined label="Label" class="v-input--density-default v-text-field--label-inner"></v-text-field>
            </v-col>
            <v-col cols="4" md="2">
                <v-text-field v-bind="{ ...attrs_input_common, ...$attrs }" outlined placeholder="Placeholder" class="v-input--density-compact v-text-field--label-inner"></v-text-field>
            </v-col>
            <v-col cols="4" md="2">
                <v-text-field v-bind="{ ...attrs_input_common, ...$attrs }" outlined placeholder="Placeholder" class="v-text-field--label-inner"></v-text-field>
            </v-col>
            <v-col cols="4" md="2">
                <v-text-field v-bind="{ ...attrs_input_common, ...$attrs }" outlined placeholder="Placeholder" class="v-input--density-default v-text-field--label-inner"></v-text-field>
            </v-col>
        </v-row>
        <!-- E: Label Inner -->
        <br />

        <!-- S: Filled -->
        <h2>Filled</h2>
        <v-row align="center">
            <v-col cols="4" md="2">
                <v-text-field v-bind="{ ...attrs_input_common, ...$attrs }" filled label="Label" class="v-input--density-compact"></v-text-field>
            </v-col>
            <v-col cols="4" md="2">
                <v-text-field v-bind="{ ...attrs_input_common, ...$attrs }" filled label="Label"></v-text-field>
            </v-col>
            <v-col cols="4" md="2">
                <v-text-field v-bind="{ ...attrs_input_common, ...$attrs }" filled label="Label" class="v-input--density-default"></v-text-field>
            </v-col>
            <v-col cols="4" md="2">
                <v-text-field v-bind="{ ...attrs_input_common, ...$attrs }" filled placeholder="Placeholder" class="v-input--density-compact"></v-text-field>
            </v-col>
            <v-col cols="4" md="2">
                <v-text-field v-bind="{ ...attrs_input_common, ...$attrs }" filled placeholder="Placeholder"></v-text-field>
            </v-col>
            <v-col cols="4" md="2">
                <v-text-field v-bind="{ ...attrs_input_common, ...$attrs }" filled placeholder="Placeholder" class="v-input--density-default"></v-text-field>
            </v-col>
        </v-row>
        <!-- E: Filled -->
        <br />

        <!-- S: Solo -->
        <h2>Solo</h2>
        <v-row align="center">
            <v-col cols="4" md="2">
                <v-text-field v-bind="{ ...attrs_input_common, ...$attrs }" solo placeholder="Placeholder" class="v-input--density-compact"></v-text-field>
            </v-col>
            <v-col cols="4" md="2">
                <v-text-field v-bind="{ ...attrs_input_common, ...$attrs }" solo placeholder="Placeholder"></v-text-field>
            </v-col>
            <v-col cols="4" md="2">
                <v-text-field v-bind="{ ...attrs_input_common, ...$attrs }" solo placeholder="Placeholder" class="v-input--density-default"></v-text-field>
            </v-col>
        </v-row>
        <!-- E: Solo -->
        <!-- // density -->

        <v-divider class="my-30px" />

        <!-- Width -->
        <h1>Width</h1>
        <p><u>w-{breakpoint}-{size}</u></p>
        <p><u>v-input--width-fixed</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <v-text-field v-bind="{ ...attrs_input_common, ...$attrs }" label="Custom Width" class="min-w-140px min-w-md-200px"></v-text-field>
            </v-col>
            <v-col cols="12">
                <v-text-field v-bind="{ ...attrs_input_common, ...$attrs }" label="default block"></v-text-field>
            </v-col>
            <v-col cols="auto">
                <v-text-field v-bind="{ ...attrs_input_common, ...$attrs }" label="Width Fixed" class="v-input--density-compact v-input--width-fixed"></v-text-field>
            </v-col>
            <v-col cols="auto">
                <v-text-field v-bind="{ ...attrs_input_common, ...$attrs }" label="Width Fixed" class="v-input--width-fixed"></v-text-field>
            </v-col>
            <v-col cols="auto">
                <v-text-field v-bind="{ ...attrs_input_common, ...$attrs }" label="Width Fixed" class="v-input--density-default v-input--width-fixed"></v-text-field>
            </v-col>
        </v-row>
        <!-- // Width -->

        <v-divider class="my-30px" />

        <!-- State -->
        <h1>State</h1>
        <!-- S: Disabled -->
        <h2>Disabled</h2>
        <p><u>disabled</u></p>
        <br />
        <v-row align="center">
            <v-col cols="6" md="3">
                <v-text-field v-bind="{ ...attrs_input_common, ...$attrs }" disabled label="Label"></v-text-field>
            </v-col>
            <v-col cols="6" md="3">
                <v-text-field v-bind="{ ...attrs_input_common, ...$attrs }" disabled placeholder="placeholder"></v-text-field>
            </v-col>
        </v-row>
        <!-- E: Disabled -->
        <br />

        <!-- S: Readonly -->
        <h2>Readonly</h2>
        <p><u>readonly</u></p>
        <br />
        <v-row align="center">
            <v-col cols="6" md="3">
                <v-text-field v-bind="{ ...attrs_input_common, ...$attrs }" readonly label="Label" value="readonly"></v-text-field>
            </v-col>
            <v-col cols="6" md="3">
                <v-text-field v-bind="{ ...attrs_input_common, ...$attrs }" readonly placeholder="placeholder"></v-text-field>
            </v-col>
        </v-row>
        <!-- E: Readonly -->
        <!-- // State -->

        <v-divider class="my-30px" />

        <!-- Variant -->
        <h1>Variant</h1>
        <h3>{variant} : (기본-underlined), outlined, label-inner(outlined), filled, solo</h3>
        <p>v-text-field--label-inner은 클래스로 입력해주세요. label-inner은 outlined속성과 같이 사용합니다.</p>
        <p>solo는 label 스타일이 없습니다.</p>
        <p><u>{variant}</u></p>
        <p><u>class="v-text-field--label-inner"</u></p>
        <br />
        <v-row align="center">
            <v-col cols="6" md="3">
                <v-text-field v-bind="{ ...attrs_input_common, ...$attrs }" label="Label"></v-text-field>
            </v-col>
            <v-col cols="6" md="3">
                <v-text-field v-bind="{ ...attrs_input_common, ...$attrs }" placeholder="Placeholder"></v-text-field>
            </v-col>
            <v-col cols="6" md="3">
                <v-text-field v-bind="{ ...attrs_input_common, ...$attrs }" outlined label="Label"></v-text-field>
            </v-col>
            <v-col cols="6" md="3">
                <v-text-field v-bind="{ ...attrs_input_common, ...$attrs }" outlined placeholder="Placeholder"></v-text-field>
            </v-col>
            <v-col cols="6" md="3">
                <v-text-field v-bind="{ ...attrs_input_common, ...$attrs }" outlined label="Label Inner" class="v-text-field--label-inner"></v-text-field>
            </v-col>
            <v-col cols="6" md="3">
                <v-text-field v-bind="{ ...attrs_input_common, ...$attrs }" outlined placeholder="Placeholder" class="v-text-field--label-inner"></v-text-field>
            </v-col>
            <v-col cols="6" md="3">
                <v-text-field v-bind="{ ...attrs_input_common, ...$attrs }" filled label="Label"></v-text-field>
            </v-col>
            <v-col cols="6" md="3">
                <v-text-field v-bind="{ ...attrs_input_common, ...$attrs }" filled placeholder="Placeholder"></v-text-field>
            </v-col>
            <v-col cols="6" md="3">
                <v-text-field v-bind="{ ...attrs_input_common, ...$attrs }" solo placeholder="Placeholder"></v-text-field>
            </v-col>
        </v-row>
        <!-- // Variant -->

        <v-divider class="my-30px" />

        <!-- Rounded -->
        <h1>Rounded</h1>
        <h3>{rounded} : (기본),pill, 0</h3>
        <p><u>class="rounded-{rounded}"</u></p>
        <br />
        <v-row align="center">
            <v-col cols="6" md="2">
                <v-text-field v-bind="{ ...attrs_input_common, ...$attrs }" outlined label="Default"></v-text-field>
            </v-col>
            <v-col cols="6" md="2">
                <v-text-field v-bind="{ ...attrs_input_common, ...$attrs }" outlined label="Pill" class="rounded-pill"></v-text-field>
            </v-col>
            <v-col cols="6" md="2">
                <v-text-field v-bind="{ ...attrs_input_common, ...$attrs }" outlined label="Tile" class="rounded-0"></v-text-field>
            </v-col>
            <v-col cols="6" md="2">
                <v-text-field v-bind="{ ...attrs_input_common, ...$attrs }" filled label="Default"></v-text-field>
            </v-col>
            <v-col cols="6" md="2">
                <v-text-field v-bind="{ ...attrs_input_common, ...$attrs }" filled label="Pill" class="rounded-pill"></v-text-field>
            </v-col>
            <v-col cols="6" md="2">
                <v-text-field v-bind="{ ...attrs_input_common, ...$attrs }" filled label="Tile" class="rounded-0"></v-text-field>
            </v-col>
            <v-col cols="6" md="2">
                <v-text-field v-bind="{ ...attrs_input_common, ...$attrs }" outlined label="Default Label Inner" class="v-text-field--label-inner"></v-text-field>
            </v-col>
            <v-col cols="6" md="2">
                <v-text-field v-bind="{ ...attrs_input_common, ...$attrs }" outlined label="Pill Label Inner" class="rounded-pill v-text-field--label-inner"></v-text-field>
            </v-col>
            <v-col cols="6" md="2">
                <v-text-field v-bind="{ ...attrs_input_common, ...$attrs }" outlined label="Tile Label Inner" class="rounded-0 v-text-field--label-inner"></v-text-field>
            </v-col>
        </v-row>
        <!-- // Rounded -->

        <v-divider class="my-30px" />

        <!-- Icon -->
        <h1>Icon</h1>
        <h3>{direction} : prepend, prepend-inner, append, append-outer, clear-icon</h3>
        <p>vue에서 렌더링 하는데 소모하는 시간이 있어 되도록이면 icon=""을 사용하지 않고 template v-slot으로 아이콘을 추가합니다.</p>
        <p>이벤트가 있는 아이콘은 template v-slot으로 사용하면 이벤트가 작동되지 않아 {direction}=""형태로 수정합니다. 이때 아이콘은 mdi아이콘만 사용할 수 있습니다.</p>
        <p><u>#{direction}</u></p>
        <p><u>{direction}=""</u></p>
        <br />
        <v-row align="center">
            <v-col cols="6" md="3">
                <v-text-field v-bind="{ ...attrs_input_common, ...$attrs }" outlined placeholder="Prepend Icon">
                    <template #prepend><u-icon>attach_file</u-icon></template>
                </v-text-field>
            </v-col>
            <v-col cols="6" md="3">
                <v-text-field v-bind="{ ...attrs_input_common, ...$attrs }" outlined placeholder="Prepend Inner Icon">
                    <template #prepend-inner><u-icon>attach_file</u-icon></template>
                </v-text-field>
            </v-col>
            <v-col cols="6" md="3">
                <v-text-field v-bind="{ ...attrs_input_common, ...$attrs }" outlined placeholder="Append Outer Icon">
                    <template #append-outer><u-icon>attach_file</u-icon></template>
                </v-text-field>
            </v-col>
            <v-col cols="6" md="3">
                <v-text-field v-bind="{ ...attrs_input_common, ...$attrs }" outlined placeholder="Append Icon">
                    <template #append><u-icon>attach_file</u-icon></template>
                </v-text-field>
            </v-col>
            <v-col cols="6" md="3">
                <v-text-field v-bind="{ ...attrs_input_common, ...$attrs }" outlined clearable placeholder="Prepend Icon Clearable">
                    <template #prepend><u-icon>attach_file</u-icon></template>
                </v-text-field>
            </v-col>
            <v-col cols="6" md="3">
                <v-text-field v-bind="{ ...attrs_input_common, ...$attrs }" outlined clearable placeholder="Prepend Inner Icon Clearable">
                    <template #prepend-inner><u-icon>attach_file</u-icon></template>
                </v-text-field>
            </v-col>
            <v-col cols="6" md="3">
                <v-text-field v-bind="{ ...attrs_input_common, ...$attrs }" outlined clearable placeholder="Append Outer Icon Clearable">
                    <template #append-outer><u-icon>attach_file</u-icon></template>
                </v-text-field>
            </v-col>
            <v-col cols="6" md="3">
                <v-text-field v-bind="{ ...attrs_input_common, ...$attrs }" outlined clearable placeholder="Append Icon Clearable">
                    <template #append><u-icon>attach_file</u-icon></template>
                </v-text-field>
            </v-col>
            <v-col cols="6" md="3">
                <v-text-field v-bind="{ ...attrs_input_common, ...$attrs }" outlined clearable clear-icon="delete" placeholder="Prepend Append Clearable Icon">
                    <template #prepend><u-icon>attach_file</u-icon></template>
                    <template #append-outer><u-icon>check</u-icon></template>
                </v-text-field>
            </v-col>
            <v-col cols="6" md="3">
                <v-text-field v-bind="{ ...attrs_input_common, ...$attrs }" outlined clearable clear-icon="delete" placeholder="Prepend Append Clearable Icon">
                    <template #prepend-inner><u-icon>attach_file</u-icon></template>
                    <template #append><u-icon>check</u-icon></template>
                </v-text-field>
            </v-col>
        </v-row>
        <!-- // Icon -->

        <v-divider class="my-30px" />

        <!-- Supporting Text -->
        <h1>Supporting Text</h1>
        <!-- S: Hide details -->
        <h2>Hide details</h2>
        <h3>{hide-details} : auto, hide</h3>
        <p><u>hide-details="{hide-details}"</u></p>
        <br />
        <v-row align="center">
            <v-col cols="6" md="3">
                <v-text-field v-bind="{ ...attrs_input_common, ...$attrs }" hint="Hint" placeholder="Hide Details Auto"></v-text-field>
            </v-col>
            <v-col cols="6" md="3">
                <v-text-field v-bind="{ ...attrs_input_common, ...$attrs }" hide-details="hide" hint="Hint" placeholder="Hide Details Hide"></v-text-field>
            </v-col>
        </v-row>
        <!-- E: Hide details -->
        <br />

        <!-- S: Hint, Messages -->
        <h2>Hint, Messages</h2>
        <p><u>hint=""</u></p>
        <p><u>messages=""</u></p>
        <br />
        <v-row align="center">
            <v-col cols="6" md="3">
                <v-text-field v-bind="{ ...attrs_input_common, ...$attrs }" hint="Hint" placeholder="Hint"></v-text-field>
            </v-col>
            <v-col cols="6" md="3">
                <v-text-field v-bind="{ ...attrs_input_common, ...$attrs }" messages="Messages" placeholder="Messages"></v-text-field>
            </v-col>
        </v-row>
        <!-- E: Hint, Messages -->
        <br />

        <!-- S: Persistence -->
        <h2>Persistence</h2>
        <h3>{persistent} : hint, counter, clear, placeholder</h3>
        <p><u>persistent-{persistent}</u></p>
        <p>persistent-hint - 항상 힌트가 보입니다.</p>
        <p>persistent-counter - 항상 입력 문자 길이 요소를 표시합니다.</p>
        <p>persistent-clear - 값이 있을 때 입력 지우기 아이콘을 항상 표시합니다.</p>
        <p>persistent-placeholder - 항상 placeholder를 표시하고 label이 자동으로 올라갑니다.</p>
        <br />
        <v-row align="center">
            <v-col cols="6" md="3">
                <v-text-field v-bind="{ ...attrs_input_common, ...$attrs }" persistent-hint hint="Hint" placeholder="persistent-hint"></v-text-field>
            </v-col>
            <v-col cols="6" md="3">
                <v-text-field v-bind="{ ...attrs_input_common, ...$attrs }" counter persistent-counter placeholder="persistent-counter"></v-text-field>
            </v-col>
            <v-col cols="6" md="3">
                <v-text-field v-bind="{ ...attrs_input_common, ...$attrs }" clearable persistent-clear placeholder="persistent-clear"></v-text-field>
            </v-col>
            <v-col cols="6" md="3">
                <v-text-field v-bind="{ ...attrs_input_common, ...$attrs }" persistent-placeholder label="Label" placeholder="placeholder-placeholder"></v-text-field>
            </v-col>
        </v-row>
        <!-- E: Persistence -->
        <!-- // Supporting Text -->
    </div>
</template>

<script>
import { attrs_input_common } from "@/assets/variables";

import TextFieldPrimary from "@/components/publish/parents/forms/text-field-primary.vue";
import TextFieldSecondary from "@/components/publish/parents/forms/text-field-secondary.vue";
import TextFieldTertiary from "@/components/publish/parents/forms/text-field-tertiary.vue";
import TextFieldQuarternary from "@/components/publish/parents/forms/text-field-quarternary.vue";

import UTextFieldOutlinedTile from "@/components/publish/styles/forms/u-text-field-outlined-tile.vue";
import UTextFieldOutlinedRounded from "@/components/publish/styles/forms/u-text-field-outlined-rounded.vue";
import UTextFieldOutlinedPill from "@/components/publish/styles/forms/u-text-field-outlined-pill.vue";
import UTextFieldFilledTile from "@/components/publish/styles/forms/u-text-field-filled-tile.vue";
import UTextFieldFilledRounded from "@/components/publish/styles/forms/u-text-field-filled-rounded.vue";
import UTextFieldFilledPill from "@/components/publish/styles/forms/u-text-field-filled-pill.vue";
import UTextFieldSoloTile from "@/components/publish/styles/forms/u-text-field-solo-tile.vue";
import UTextFieldSoloRounded from "@/components/publish/styles/forms/u-text-field-solo-rounded.vue";
import UTextFieldSoloPill from "@/components/publish/styles/forms/u-text-field-solo-pill.vue";
import UTextFieldUnderlined from "@/components/publish/styles/forms/u-text-field-underlined.vue";

import UIcon from "@/components/publish/styles/icons/u-icon.vue";

export default {
    props: {},
    components: {
        TextFieldPrimary,
        TextFieldSecondary,
        TextFieldTertiary,
        TextFieldQuarternary,

        UTextFieldOutlinedTile,
        UTextFieldOutlinedRounded,
        UTextFieldOutlinedPill,
        UTextFieldFilledTile,
        UTextFieldFilledRounded,
        UTextFieldFilledPill,
        UTextFieldSoloTile,
        UTextFieldSoloRounded,
        UTextFieldSoloPill,
        UTextFieldUnderlined,

        UIcon,
    },
    data() {
        return {
            attrs_input_common,

            forms: {
                test: null,
            },
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
