var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('page-section', _vm._b({
    scopedSlots: _vm._u([{
      key: "pageSectionFoot",
      fn: function () {
        return [_vm._t("default")];
      },
      proxy: true
    }], null, true)
  }, 'page-section', _vm.$attrs, false), [this.$slots['tit'] ? _c('tit-wrap-primary', {
    class: _vm.titWrapClass
  }, [_vm._t("tit")], 2) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }