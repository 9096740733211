var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h1', [_vm._v("Parents")]), _vm._m(0), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('swiper-pagination-primary', [_c('span', {
    staticClass: "swiper-pagination-bullet swiper-pagination-bullet-active"
  }, [_vm._v("01")]), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }, [_vm._v("02")]), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }, [_vm._v("03")]), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }, [_vm._v("04")])])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('swiper-pagination-secondary', [_c('span', {
    staticClass: "swiper-pagination-bullet swiper-pagination-bullet-active"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  })])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('swiper-pagination-tertiary', [_c('span', {
    staticClass: "swiper-pagination-bullet swiper-pagination-bullet-active"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  })])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('swiper-pagination-quarternary', [_c('span', {
    staticClass: "swiper-pagination-bullet swiper-pagination-bullet-active"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  })])], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Styles")]), _c('p', [_vm._v("swiper-pagination-bullet는 템플릿 작업을 위해 넣은 놓은 것입니다.")]), _c('p', [_vm._v("사용시 swiper-pagination-bullet는 지우고 u-swiper-pagination-{}만 사용해주세요.")]), _c('br'), _c('h2', [_vm._v("Filled")]), _vm._m(1), _vm._m(2), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-swiper-pagination-filled', {
    staticClass: "swiper-pagination--sm"
  }, [_c('span', {
    staticClass: "swiper-pagination-bullet swiper-pagination-bullet-active"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  })])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-swiper-pagination-filled', [_c('span', {
    staticClass: "swiper-pagination-bullet swiper-pagination-bullet-active"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  })])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-swiper-pagination-filled-outlined', {
    staticClass: "swiper-pagination--sm"
  }, [_c('span', {
    staticClass: "swiper-pagination-bullet swiper-pagination-bullet-active"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  })])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-swiper-pagination-filled-outlined', [_c('span', {
    staticClass: "swiper-pagination-bullet swiper-pagination-bullet-active"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  })])], 1)], 1), _c('br'), _c('h2', [_vm._v("Ellipse")]), _vm._m(3), _vm._m(4), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-swiper-pagination-ellipse', {
    staticClass: "swiper-pagination--sm"
  }, [_c('span', {
    staticClass: "swiper-pagination-bullet swiper-pagination-bullet-active"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  })])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-swiper-pagination-ellipse', [_c('span', {
    staticClass: "swiper-pagination-bullet swiper-pagination-bullet-active"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  })])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-swiper-pagination-ellipse-outlined', {
    staticClass: "swiper-pagination--sm"
  }, [_c('span', {
    staticClass: "swiper-pagination-bullet swiper-pagination-bullet-active"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  })])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-swiper-pagination-ellipse-outlined', [_c('span', {
    staticClass: "swiper-pagination-bullet swiper-pagination-bullet-active"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  })])], 1)], 1), _c('br'), _c('h2', [_vm._v("Around")]), _vm._m(5), _vm._m(6), _vm._m(7), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-swiper-pagination-around', {
    staticClass: "swiper-pagination--sm"
  }, [_c('span', {
    staticClass: "swiper-pagination-bullet swiper-pagination-bullet-active"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  })])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-swiper-pagination-around', [_c('span', {
    staticClass: "swiper-pagination-bullet swiper-pagination-bullet-active"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  })])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-swiper-pagination-around-outlined', {
    staticClass: "swiper-pagination--sm"
  }, [_c('span', {
    staticClass: "swiper-pagination-bullet swiper-pagination-bullet-active"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  })])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-swiper-pagination-around-outlined', [_c('span', {
    staticClass: "swiper-pagination-bullet swiper-pagination-bullet-active"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  })])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-swiper-pagination-around-border', {
    staticClass: "swiper-pagination--sm"
  }, [_c('span', {
    staticClass: "swiper-pagination-bullet swiper-pagination-bullet-active"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  })])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-swiper-pagination-around-border', [_c('span', {
    staticClass: "swiper-pagination-bullet swiper-pagination-bullet-active"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  })])], 1)], 1), _c('br'), _c('h2', [_vm._v("Line")]), _vm._m(8), _vm._m(9), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-swiper-pagination-line', {
    staticClass: "swiper-pagination--sm"
  }, [_c('span', {
    staticClass: "swiper-pagination-bullet swiper-pagination-bullet-active"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  })])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-swiper-pagination-line', [_c('span', {
    staticClass: "swiper-pagination-bullet swiper-pagination-bullet-active"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  })])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-swiper-pagination-line-connected', {
    staticClass: "swiper-pagination--sm"
  }, [_c('span', {
    staticClass: "swiper-pagination-bullet swiper-pagination-bullet-active"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  })])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-swiper-pagination-line-connected', [_c('span', {
    staticClass: "swiper-pagination-bullet swiper-pagination-bullet-active"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  })])], 1)], 1), _c('br'), _c('h2', [_vm._v("Fraction")]), _vm._m(10), _vm._m(11), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-swiper-pagination-fraction', [_c('span', {
    staticClass: "swiper-pagination-current"
  }, [_vm._v("02")]), _c('span', {
    staticClass: "swiper-pagination__decoration"
  }), _c('span', {
    staticClass: "swiper-pagination-total"
  }, [_vm._v("03")])])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-swiper-pagination-fraction-divider', [_c('span', {
    staticClass: "swiper-pagination-current"
  }, [_vm._v("02")]), _c('span', {
    staticClass: "swiper-pagination__decoration"
  }), _c('span', {
    staticClass: "swiper-pagination-total"
  }, [_vm._v("03")])])], 1)], 1), _c('br'), _c('h2', [_vm._v("Text")]), _vm._m(12), _vm._m(13), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-swiper-pagination-text', [_c('span', {
    staticClass: "swiper-pagination-bullet swiper-pagination-bullet-active"
  }, [_vm._v("01")]), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }, [_vm._v("02")]), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }, [_vm._v("03")]), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }, [_vm._v("04")])])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-swiper-pagination-text-divider', [_c('span', {
    staticClass: "swiper-pagination-bullet swiper-pagination-bullet-active"
  }, [_vm._v("01")]), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }, [_vm._v("02")]), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }, [_vm._v("03")]), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }, [_vm._v("04")])])], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Color")]), _vm._m(14), _vm._m(15), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-swiper-pagination-filled', [_c('span', {
    staticClass: "swiper-pagination-bullet swiper-pagination-bullet-active"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  })])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-swiper-pagination-filled', {
    staticClass: "swiper-pagination--dark"
  }, [_c('span', {
    staticClass: "swiper-pagination-bullet swiper-pagination-bullet-active"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  })])], 1), _c('v-col', {
    staticClass: "grey pa-20px",
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-swiper-pagination-filled', {
    staticClass: "swiper-pagination--light"
  }, [_c('span', {
    staticClass: "swiper-pagination-bullet swiper-pagination-bullet-active"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  })])], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Border Radius")]), _vm._m(16), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-swiper-pagination-filled', {
    staticClass: "swiper-pagination--angular"
  }, [_c('span', {
    staticClass: "swiper-pagination-bullet swiper-pagination-bullet-active"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  })])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-swiper-pagination-ellipse', {
    staticClass: "swiper-pagination--angular"
  }, [_c('span', {
    staticClass: "swiper-pagination-bullet swiper-pagination-bullet-active"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  })])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-swiper-pagination-around', {
    staticClass: "swiper-pagination--angular"
  }, [_c('span', {
    staticClass: "swiper-pagination-bullet swiper-pagination-bullet-active"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  })])], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Align")]), _vm._m(17), _vm._m(18)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("swiper-pagination-{parents}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-swiper-pagination-filled")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-swiper-pagination-filled-outlined")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-swiper-pagination-ellipse")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-swiper-pagination-ellipse-outlined")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-swiper-pagination-around")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-swiper-pagination-around-outlined")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-swiper-pagination-around-border")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-swiper-pagination-line")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-swiper-pagination-line-connected")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-swiper-pagination-fraction")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-swiper-pagination-fraction-divider")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-swiper-pagination-text")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-swiper-pagination-text-divider")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("swiper-pagination--dark")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("swiper-pagination--light")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("swiper-pagination--angular")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("swiper-pagination--left")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("swiper-pagination--right")])]);

}]

export { render, staticRenderFns }