<template>
    <scrolldown v-bind="$attrs" class="scrolldown--filled" :class="[vertical ? 'scrolldown--vertical' : '', verticalWide ? 'scrolldown--vertical-wide' : '']">
        <template v-if="vertical">
            <div class="scrolldown__txt">Scroll</div>
            <span class="scrolldown__bar"></span>
        </template>
        <template v-else-if="verticalWide">
            <div class="scrolldown__txt">Scroll Down</div>
            <span class="scrolldown__bar"></span>
        </template>
        <template v-else>
            <span class="scrolldown__bar"></span>
            <div class="scrolldown__txt">Scroll</div>
        </template>
    </scrolldown>
</template>

<script>
import Scrolldown from "@/components/publish/styles/scrolldowns/scrolldown.vue";

export default {
    props: {
        vertical: { type: Boolean, default: false },
        verticalWide: { type: Boolean, default: false },
    },
    components: {
        Scrolldown,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
