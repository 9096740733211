var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h1', [_vm._v("Parents")]), _vm._m(0), _vm._m(1), _vm._m(2), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('table-primary', {
    attrs: {
      "items": _vm.items,
      "headers": _vm.headers
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref) {
        var items = _ref.items;
        return [_c('tbody', _vm._l(_vm.headers, function (header) {
          return _c('tr', {
            key: header.text
          }, [_c('th-primary', {
            style: 'width: ' + header.width
          }, [_vm._v(_vm._s(header.text))]), _vm._l(items, function (item) {
            return _c('td-primary', {
              key: item.text
            }, [_vm._v(_vm._s(item[header.value]))]);
          })], 2);
        }), 0)];
      }
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('table-secondary', {
    attrs: {
      "items": _vm.items,
      "headers": _vm.headers
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function (_ref2) {
        var headers = _ref2.props.headers;
        return [_c('thead', [_c('tr', _vm._l(headers, function (head) {
          return _c('th-secondary', {
            key: head.text,
            style: 'width:' + head.width
          }, [_vm._v(_vm._s(head.text))]);
        }), 1)])];
      }
    }, {
      key: "body",
      fn: function (_ref3) {
        var items = _ref3.items,
          headers = _ref3.headers;
        return [_c('tbody', _vm._l(items, function (item) {
          return _c('tr', {
            key: item.text
          }, _vm._l(headers, function (header) {
            return _c('td-secondary', {
              key: header.text
            }, [_vm._v(_vm._s(item[header.value]))]);
          }), 1);
        }), 0)];
      }
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('table-tertiary', {
    attrs: {
      "items": _vm.items,
      "headers": _vm.headers
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref4) {
        var items = _ref4.items;
        return [_c('tbody', _vm._l(_vm.headers, function (header) {
          return _c('tr', {
            key: header.text
          }, [_c('th-tertiary', {
            style: 'width: ' + header.width
          }, [_vm._v(_vm._s(header.text))]), _vm._l(items, function (item) {
            return _c('td-tertiary', {
              key: item.text
            }, [_vm._v(_vm._s(item[header.value]))]);
          })], 2);
        }), 0)];
      }
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('table-quarternary', {
    attrs: {
      "items": _vm.items,
      "headers": _vm.headers
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function (_ref5) {
        var headers = _ref5.props.headers;
        return [_c('thead', [_c('tr', _vm._l(headers, function (head) {
          return _c('th-quarternary', {
            key: head.text,
            style: 'width:' + head.width
          }, [_vm._v(_vm._s(head.text))]);
        }), 1)])];
      }
    }, {
      key: "body",
      fn: function (_ref6) {
        var items = _ref6.items,
          headers = _ref6.headers;
        return [_c('tbody', _vm._l(items, function (item) {
          return _c('tr', {
            key: item.text
          }, _vm._l(headers, function (header) {
            return _c('td-quarternary', {
              key: header.text
            }, [_vm._v(_vm._s(item[header.value]))]);
          }), 1);
        }), 0)];
      }
    }])
  })], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Styles")]), _c('h2', [_vm._v("Line")]), _vm._m(3), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('u-table-line', {
    attrs: {
      "items": _vm.items,
      "headers": _vm.headers
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref7) {
        var items = _ref7.items;
        return [_c('tbody', _vm._l(_vm.headers, function (header) {
          return _c('tr', {
            key: header.text
          }, [_c('th', {
            style: 'width: ' + header.width
          }, [_vm._v(_vm._s(header.text))]), _vm._l(items, function (item) {
            return _c('td', {
              key: item.text
            }, [_vm._v(_vm._s(item[header.value]))]);
          })], 2);
        }), 0)];
      }
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('u-table-line', {
    attrs: {
      "items": _vm.items,
      "headers": _vm.headers
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function (_ref8) {
        var headers = _ref8.props.headers;
        return [_c('thead', [_c('tr', _vm._l(headers, function (head) {
          return _c('th', {
            key: head.text,
            style: 'width:' + head.width
          }, [_vm._v(_vm._s(head.text))]);
        }), 0)])];
      }
    }, {
      key: "body",
      fn: function (_ref9) {
        var items = _ref9.items,
          headers = _ref9.headers;
        return [_c('tbody', _vm._l(items, function (item) {
          return _c('tr', {
            key: item.text
          }, _vm._l(headers, function (header) {
            return _c('td', {
              key: header.text
            }, [_vm._v(_vm._s(item[header.value]))]);
          }), 0);
        }), 0)];
      }
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('u-table-line', {
    staticClass: "v-data-table--border",
    attrs: {
      "items": _vm.items,
      "headers": _vm.headers
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function (_ref10) {
        var headers = _ref10.props.headers;
        return [_c('thead', [_c('tr', _vm._l(headers, function (head) {
          return _c('th', {
            key: head.text,
            style: 'width:' + head.width
          }, [_vm._v(_vm._s(head.text))]);
        }), 0)])];
      }
    }, {
      key: "body",
      fn: function (_ref11) {
        var items = _ref11.items,
          headers = _ref11.headers;
        return [_c('tbody', _vm._l(items, function (item) {
          return _c('tr', {
            key: item.text
          }, _vm._l(headers, function (header) {
            return _c('td', {
              key: header.text
            }, [_vm._v(_vm._s(item[header.value]))]);
          }), 0);
        }), 0)];
      }
    }])
  })], 1)], 1), _c('br'), _c('h2', [_vm._v("Background")]), _vm._m(4), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('u-table-line', {
    attrs: {
      "items": _vm.items,
      "headers": _vm.headers
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref12) {
        var items = _ref12.items;
        return [_c('tbody', _vm._l(_vm.headers, function (header) {
          return _c('tr', {
            key: header.text
          }, [_c('th', {
            staticClass: "grey lighten-5",
            style: 'width: ' + header.width
          }, [_vm._v(_vm._s(header.text))]), _vm._l(items, function (item) {
            return _c('td', {
              key: item.text
            }, [_vm._v(_vm._s(item[header.value]))]);
          })], 2);
        }), 0)];
      }
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('u-table-line', {
    attrs: {
      "items": _vm.items,
      "headers": _vm.headers
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function (_ref13) {
        var headers = _ref13.props.headers;
        return [_c('thead', [_c('tr', _vm._l(headers, function (head) {
          return _c('th', {
            key: head.text,
            staticClass: "grey lighten-5",
            style: 'width:' + head.width
          }, [_vm._v(_vm._s(head.text))]);
        }), 0)])];
      }
    }, {
      key: "body",
      fn: function (_ref14) {
        var items = _ref14.items,
          headers = _ref14.headers;
        return [_c('tbody', _vm._l(items, function (item) {
          return _c('tr', {
            key: item.text
          }, _vm._l(headers, function (header) {
            return _c('td', {
              key: header.text
            }, [_vm._v(_vm._s(item[header.value]))]);
          }), 0);
        }), 0)];
      }
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('u-table-line', {
    staticClass: "v-data-table--border",
    attrs: {
      "items": _vm.items,
      "headers": _vm.headers
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function (_ref15) {
        var headers = _ref15.props.headers;
        return [_c('thead', [_c('tr', _vm._l(headers, function (head) {
          return _c('th', {
            key: head.text,
            staticClass: "grey lighten-5",
            style: 'width:' + head.width
          }, [_vm._v(_vm._s(head.text))]);
        }), 0)])];
      }
    }, {
      key: "body",
      fn: function (_ref16) {
        var items = _ref16.items,
          headers = _ref16.headers;
        return [_c('tbody', _vm._l(items, function (item) {
          return _c('tr', {
            key: item.text
          }, _vm._l(headers, function (header) {
            return _c('td', {
              key: header.text
            }, [_vm._v(_vm._s(item[header.value]))]);
          }), 0);
        }), 0)];
      }
    }])
  })], 1)], 1), _c('br'), _c('h2', [_vm._v("Color Bg")]), _vm._m(5), _vm._m(6), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('u-table-color-bg', {
    staticClass: "v-data-table--line border-1",
    attrs: {
      "items": _vm.items,
      "headers": _vm.headers
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref17) {
        var items = _ref17.items;
        return [_c('tbody', _vm._l(_vm.headers, function (header) {
          return _c('tr', {
            key: header.text
          }, [_c('th', {
            staticClass: "v-data-table__color-bg grey white--text",
            style: 'width: ' + header.width
          }, [_vm._v(_vm._s(header.text))]), _vm._l(items, function (item) {
            return _c('td', {
              key: item.text
            }, [_vm._v(_vm._s(item[header.value]))]);
          })], 2);
        }), 0)];
      }
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('u-table-color-bg', {
    attrs: {
      "items": _vm.items,
      "headers": _vm.headers
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function (_ref18) {
        var headers = _ref18.props.headers;
        return [_c('thead', [_c('tr', _vm._l(headers, function (head) {
          return _c('th', {
            key: head.text,
            staticClass: "v-data-table__color-bg grey white--text",
            style: 'width:' + head.width
          }, [_vm._v(_vm._s(head.text))]);
        }), 0)])];
      }
    }, {
      key: "body",
      fn: function (_ref19) {
        var items = _ref19.items,
          headers = _ref19.headers;
        return [_c('tbody', _vm._l(items, function (item) {
          return _c('tr', {
            key: item.text
          }, _vm._l(headers, function (header) {
            return _c('td', {
              key: header.text
            }, [_vm._v(_vm._s(item[header.value]))]);
          }), 0);
        }), 0)];
      }
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('u-table-color-bg', {
    staticClass: "v-data-table--border",
    attrs: {
      "items": _vm.items,
      "headers": _vm.headers
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function (_ref20) {
        var headers = _ref20.props.headers;
        return [_c('thead', [_c('tr', _vm._l(headers, function (head) {
          return _c('th', {
            key: head.text,
            staticClass: "v-data-table__color-bg grey white--text",
            style: 'width:' + head.width
          }, [_vm._v(_vm._s(head.text))]);
        }), 0)])];
      }
    }, {
      key: "body",
      fn: function (_ref21) {
        var items = _ref21.items,
          headers = _ref21.headers;
        return [_c('tbody', _vm._l(items, function (item) {
          return _c('tr', {
            key: item.text
          }, _vm._l(headers, function (header) {
            return _c('td', {
              key: header.text
            }, [_vm._v(_vm._s(item[header.value]))]);
          }), 0);
        }), 0)];
      }
    }])
  })], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Density")]), _c('h3', [_vm._v("{density} : compact, comfortable, (기본-default)")]), _vm._m(7), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-data-table', {
    staticClass: "v-data-table--density-compact",
    attrs: {
      "items": _vm.items,
      "headers": _vm.headers,
      "hide-default-header": "",
      "hide-default-footer": "",
      "disable-sort": "",
      "disable-pagination": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-data-table', {
    staticClass: "v-data-table--density-comfortable",
    attrs: {
      "items": _vm.items,
      "headers": _vm.headers,
      "hide-default-header": "",
      "hide-default-footer": "",
      "disable-sort": "",
      "disable-pagination": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-data-table', {
    attrs: {
      "items": _vm.items,
      "headers": _vm.headers,
      "hide-default-header": "",
      "hide-default-footer": "",
      "disable-sort": "",
      "disable-pagination": ""
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("table-{parents}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("th-{parents}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("td-{parents}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-table-line")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-table-line")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-table-color-bg")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("class=\"v-data-table__color-bg\"")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("class=\"v-data-table--density-{density}\"")])]);

}]

export { render, staticRenderFns }