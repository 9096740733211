var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tooltip', _vm._b({
    attrs: {
      "bottom": "",
      "content-class": "white elevation-4"
    },
    scopedSlots: _vm._u([_vm._l(_vm.$scopedSlots, function (_, scopedSlotName) {
      return {
        key: scopedSlotName,
        fn: function (slotData) {
          return [_vm._t(scopedSlotName, null, null, slotData)];
        }
      };
    })], null, true)
  }, 'v-tooltip', _vm.$attrs, false));

}
var staticRenderFns = []

export { render, staticRenderFns }