var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h1', [_vm._v("Parents")]), _vm._m(0), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('divider-primary')], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('divider-secondary')], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "2"
    }
  }, [_c('divider-tertiary')], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4"
    }
  }, [_c('divider-quarternary', [_vm._v("Divider Quarternary")])], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Display")]), _vm._m(1), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    staticClass: "h-40px",
    attrs: {
      "vertical": ""
    }
  })], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Text")]), _c('h3', [_vm._v("{location} : top, bottom, center")]), _vm._m(2), _vm._m(3), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('div', {
    staticClass: "v-divider--top"
  }, [_c('span', {
    staticClass: "v-divider__txt"
  }, [_vm._v("Divider")]), _c('v-divider')], 1)]), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('div', {
    staticClass: "v-divider--bottom"
  }, [_c('span', {
    staticClass: "v-divider__txt"
  }, [_vm._v("Divider")]), _c('v-divider')], 1)]), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('div', {
    staticClass: "v-divider--center"
  }, [_c('span', {
    staticClass: "v-divider__txt"
  }, [_vm._v("Divider")]), _c('v-divider')], 1)])], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Thickness")]), _c('p', [_vm._v("인라인스타일로 넣어줍니다.")]), _vm._m(4), _vm._m(5), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-divider', {
    staticStyle: {
      "border-width": "10px 0 0"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    staticClass: "h-40px",
    staticStyle: {
      "border-width": "0 6px 0 0"
    },
    attrs: {
      "vertical": ""
    }
  })], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Color")]), _vm._m(6), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-divider', {
    staticClass: "primary"
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-divider', {
    staticClass: "h-40px secondary",
    attrs: {
      "vertical": ""
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("divider-{parents}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("vertical")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("v-divider--{location}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("v-divider__txt")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("style=\"border-width: 2px 0 0;\"")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("vertical style=\"border-width: 0 2px 0 0;\"")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("class=\"{color}\"")])]);

}]

export { render, staticRenderFns }