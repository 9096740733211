<template>
    <div>
        <!-- Divider Group -->
        <h1>Divider Group</h1>
        <p><u>u-divider-group</u></p>
        <br />
        <v-row align="center">
            <v-col cols="6">
                <u-divider-group>
                    <li>11111</li>
                    <li>22222</li>
                    <li>33333</li>
                </u-divider-group>
            </v-col>
            <v-col cols="6">
                <u-divider-group class="divider-group--lg">
                    <li>11111</li>
                    <li>22222</li>
                    <li>33333</li>
                </u-divider-group>
            </v-col>
        </v-row>
        <!-- // Divider Group -->

        <v-divider class="my-30px" />

        <!-- Inline Group -->
        <h1>Inline Group</h1>
        <p><u>u-inline-group</u></p>
        <p><u>class="clear"</u>을 넣으면 줄바꿈됩니다.</p>
        <br />
        <v-row align="center">
            <v-col cols="6">
                <u-inline-group>
                    <li>11111</li>
                    <li>22222</li>
                    <li>33333</li>
                </u-inline-group>
            </v-col>
            <v-col cols="6">
                <u-inline-group class="inline-group--lg">
                    <li>11111</li>
                    <li class="clear">22222</li>
                    <li>33333</li>
                </u-inline-group>
            </v-col>
        </v-row>
        <!-- // Inline Group -->
    </div>
</template>

<script>
import UDividerGroup from "@/components/publish/styles/groups/u-divider-group.vue";
import UInlineGroup from "@/components/publish/styles/groups/u-inline-group.vue";

export default {
    props: {},
    components: {
        UDividerGroup,
        UInlineGroup,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
