<template>
    <div>
        <!-- Parents -->
        <h1>Parents</h1>
        <p><u>scrolldown-{parents}</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <scrolldown-primary></scrolldown-primary>
            </v-col>
            <v-col cols="auto">
                <scrolldown-secondary></scrolldown-secondary>
            </v-col>
            <v-col cols="auto">
                <scrolldown-tertiary></scrolldown-tertiary>
            </v-col>
            <v-col cols="auto">
                <scrolldown-quarternary></scrolldown-quarternary>
            </v-col>
        </v-row>
        <!-- // Parents -->

        <v-divider class="my-30px" />

        <!-- Styles -->
        <h1>Styles</h1>
        <!-- S: Double Arrow -->
        <h2>Double Arrow</h2>
        <p><u>u-scrolldown-double-arrow</u></p>
        <p><u>reverse</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <u-scrolldown-double-arrow></u-scrolldown-double-arrow>
            </v-col>
            <v-col cols="auto">
                <u-scrolldown-double-arrow reverse></u-scrolldown-double-arrow>
            </v-col>
            <v-col cols="auto">
                <div class="pa-20px grey darken-4">
                    <u-scrolldown-double-arrow class="scrolldown--light"></u-scrolldown-double-arrow>
                </div>
            </v-col>
            <v-col cols="auto">
                <div class="pa-20px grey darken-4">
                    <u-scrolldown-double-arrow reverse class="scrolldown--light"></u-scrolldown-double-arrow>
                </div>
            </v-col>
        </v-row>
        <!-- E: Double Arrow -->
        <br />

        <!-- S: Arrow -->
        <h2>Arrow</h2>
        <p><u>u-scrolldown-arrow</u></p>
        <p><u>reverse</u></p>
        <p><u>#arrow</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <u-scrolldown-arrow></u-scrolldown-arrow>
            </v-col>
            <v-col cols="auto">
                <u-scrolldown-arrow reverse></u-scrolldown-arrow>
            </v-col>
            <v-col cols="auto">
                <div class="pa-20px grey darken-4">
                    <u-scrolldown-arrow class="scrolldown--light"></u-scrolldown-arrow>
                </div>
            </v-col>
            <v-col cols="auto">
                <div class="pa-20px grey darken-4">
                    <u-scrolldown-arrow reverse class="scrolldown--light"></u-scrolldown-arrow>
                </div>
            </v-col>
            <v-col cols="auto">
                <u-scrolldown-arrow>
                    <template #arrow>
                        <icon-arrow-rounded size="small" direction="down" class="grey--text" />
                    </template>
                </u-scrolldown-arrow>
            </v-col>
            <v-col cols="auto">
                <u-scrolldown-arrow reverse>
                    <template #arrow>
                        <icon-arrow-rounded size="small" direction="down" class="grey--text" />
                    </template>
                </u-scrolldown-arrow>
            </v-col>
            <v-col cols="auto">
                <div class="pa-20px grey darken-4">
                    <u-scrolldown-arrow class="scrolldown--light">
                        <template #arrow>
                            <icon-arrow-rounded size="small" direction="down" />
                        </template>
                    </u-scrolldown-arrow>
                </div>
            </v-col>
            <v-col cols="auto">
                <div class="pa-20px grey darken-4">
                    <u-scrolldown-arrow reverse class="scrolldown--light">
                        <template #arrow>
                            <icon-arrow-rounded size="small" direction="down" />
                        </template>
                    </u-scrolldown-arrow>
                </div>
            </v-col>
        </v-row>
        <!-- E: Arrow -->
        <br />

        <!-- S: Filled -->
        <h2>Filled</h2>
        <p><u>u-scrolldown-filled</u></p>
        <p><u>vertical</u></p>
        <p><u>verticalWide</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <u-scrolldown-filled></u-scrolldown-filled>
            </v-col>
            <v-col cols="auto">
                <div class="pa-20px grey darken-4">
                    <u-scrolldown-filled class="scrolldown--light"></u-scrolldown-filled>
                </div>
            </v-col>
            <v-col cols="auto">
                <u-scrolldown-filled vertical></u-scrolldown-filled>
            </v-col>
            <v-col cols="auto">
                <div class="pa-20px grey darken-4">
                    <u-scrolldown-filled vertical class="scrolldown--light"></u-scrolldown-filled>
                </div>
            </v-col>
            <v-col cols="auto">
                <u-scrolldown-filled verticalWide></u-scrolldown-filled>
            </v-col>
            <v-col cols="auto">
                <div class="pa-20px grey darken-4">
                    <u-scrolldown-filled verticalWide class="scrolldown--light"></u-scrolldown-filled>
                </div>
            </v-col>
        </v-row>
        <!-- E: Filled -->
        <br />

        <!-- S: Mouse -->
        <h2>Mouse</h2>
        <p><u>u-scrolldown-mouse</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <u-scrolldown-mouse></u-scrolldown-mouse>
            </v-col>
            <v-col cols="auto">
                <div class="pa-20px grey darken-4">
                    <u-scrolldown-mouse class="scrolldown--light"></u-scrolldown-mouse>
                </div>
            </v-col>
            <v-col cols="auto">
                <u-scrolldown-mouse arrow></u-scrolldown-mouse>
            </v-col>
            <v-col cols="auto">
                <div class="pa-20px grey darken-4">
                    <u-scrolldown-mouse arrow class="scrolldown--light"></u-scrolldown-mouse>
                </div>
            </v-col>
        </v-row>
        <!-- // Mouse -->
        <!-- // Styles -->

        <v-divider class="my-30px" />

        <!-- Color -->
        <h1>Color</h1>
        <p><u>scrolldown--light</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <div class="pa-20px grey darken-4">
                    <u-scrolldown-arrow class="scrolldown--light"></u-scrolldown-arrow>
                </div>
            </v-col>
        </v-row>
        <!-- // Color -->
    </div>
</template>

<script>
import ScrolldownPrimary from "@/components/publish/parents/scrolldowns/scrolldown-primary.vue";
import ScrolldownSecondary from "@/components/publish/parents/scrolldowns/scrolldown-secondary.vue";
import ScrolldownTertiary from "@/components/publish/parents/scrolldowns/scrolldown-tertiary.vue";
import ScrolldownQuarternary from "@/components/publish/parents/scrolldowns/scrolldown-quarternary.vue";

import UScrolldownDoubleArrow from "@/components/publish/styles/scrolldowns/u-scrolldown-double-arrow.vue";
import UScrolldownArrow from "@/components/publish/styles/scrolldowns/u-scrolldown-arrow.vue";
import UScrolldownFilled from "@/components/publish/styles/scrolldowns/u-scrolldown-filled.vue";
import UScrolldownMouse from "@/components/publish/styles/scrolldowns/u-scrolldown-mouse.vue";

import UIcon from "@/components/publish/styles/icons/u-icon.vue";
import IconChevron from "@/components/publish/styles/icons/icon-chevron.vue";
import IconArrowRounded from "@/components/publish/styles/icons/icon-arrow-rounded.vue";

export default {
    props: {},
    components: {
        ScrolldownPrimary,
        ScrolldownSecondary,
        ScrolldownTertiary,
        ScrolldownQuarternary,

        UScrolldownDoubleArrow,
        UScrolldownArrow,
        UScrolldownFilled,
        UScrolldownMouse,

        UIcon,
        IconChevron,
        IconArrowRounded,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
