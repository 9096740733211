<template>
    <div>
        <!-- Parents -->
        <h1>Parents</h1>
        <p><u>file-input-{parents}</u></p>
        <br />
        <v-row align="center">
            <v-col cols="4" md="2">
                <file-input-primary label="Text Field Primary" class="v-input--density-compact" />
            </v-col>
            <v-col cols="4" md="2">
                <file-input-secondary placeholder="Text Field Secondary" class="v-input--density-default" />
            </v-col>
            <v-col cols="4" md="2">
                <file-input-tertiary label="Text Field Tertiary" />
            </v-col>
            <v-col cols="4" md="2">
                <file-input-quarternary placeholder="Text Field Quarternary" />
            </v-col>
        </v-row>
        <!-- // Parents -->

        <v-divider class="my-30px" />

        <!-- Styles -->
        <h1>Styles</h1>
        <!-- S: Underlined -->
        <h2>Underlined</h2>
        <p><u>u-file-input-underlined</u></p>
        <br />
        <v-row align="center">
            <v-col cols="4" md="2">
                <u-file-input-underlined label="Underlined Label"></u-file-input-underlined>
            </v-col>
            <v-col cols="4" md="2">
                <u-file-input-underlined placeholder="Underlined Placeholder"></u-file-input-underlined>
            </v-col>
        </v-row>
        <!-- E: Underlined -->
        <br />

        <!-- S: Outlined -->
        <h2>Outlined</h2>
        <p><u>u-file-input-outlined-{}</u></p>
        <br />
        <v-row align="center">
            <v-col cols="4" md="2">
                <u-file-input-outlined-tile label="Outlined Tile Label"></u-file-input-outlined-tile>
            </v-col>
            <v-col cols="4" md="2">
                <u-file-input-outlined-rounded label="Outlined Rounded Label"></u-file-input-outlined-rounded>
            </v-col>
            <v-col cols="4" md="2">
                <u-file-input-outlined-pill label="Outlined Pill Label"></u-file-input-outlined-pill>
            </v-col>
            <v-col cols="4" md="2">
                <u-file-input-outlined-tile label="Outlined Tile Label Inner" class="v-text-field--label-inner"></u-file-input-outlined-tile>
            </v-col>
            <v-col cols="4" md="2">
                <u-file-input-outlined-rounded label="Outlined Rounded Label Inner" class="v-text-field--label-inner"></u-file-input-outlined-rounded>
            </v-col>
            <v-col cols="4" md="2">
                <u-file-input-outlined-pill label="Outlined Pill Label Inner" class="v-text-field--label-inner"></u-file-input-outlined-pill>
            </v-col>
            <v-col cols="4" md="2">
                <u-file-input-outlined-tile placeholder="Outlined Tile Placeholder"></u-file-input-outlined-tile>
            </v-col>
            <v-col cols="4" md="2">
                <u-file-input-outlined-rounded placeholder="Outlined Rounded Placeholder"></u-file-input-outlined-rounded>
            </v-col>
            <v-col cols="4" md="2">
                <u-file-input-outlined-pill placeholder="Outlined Pill Placeholder"></u-file-input-outlined-pill>
            </v-col>
            <v-col cols="4" md="2">
                <u-file-input-outlined-tile placeholder="Outlined Tile Placeholder Label Inner" class="v-text-field--label-inner"></u-file-input-outlined-tile>
            </v-col>
            <v-col cols="4" md="2">
                <u-file-input-outlined-rounded placeholder="Outlined Rounded Placeholder Label Inner" class="v-text-field--label-inner"></u-file-input-outlined-rounded>
            </v-col>
            <v-col cols="4" md="2">
                <u-file-input-outlined-pill placeholder="Outlined Pill Placeholder Label Inner" class="v-text-field--label-inner"></u-file-input-outlined-pill>
            </v-col>
        </v-row>
        <!-- E: Outlined -->
        <br />

        <!-- S: Filled -->
        <h2>Filled</h2>
        <p><u>u-file-input-filled-{}</u></p>
        <br />
        <v-row align="center">
            <v-col cols="4" md="2">
                <u-file-input-filled-tile label="Filled Tile Label"></u-file-input-filled-tile>
            </v-col>
            <v-col cols="4" md="2">
                <u-file-input-filled-rounded label="Filled Rounded Label"></u-file-input-filled-rounded>
            </v-col>
            <v-col cols="4" md="2">
                <u-file-input-filled-pill label="Filled Pill Label"></u-file-input-filled-pill>
            </v-col>
            <v-col cols="4" md="2">
                <u-file-input-filled-tile placeholder="Filled Tile Placeholder"></u-file-input-filled-tile>
            </v-col>
            <v-col cols="4" md="2">
                <u-file-input-filled-rounded placeholder="Filled Rounded Placeholder"></u-file-input-filled-rounded>
            </v-col>
            <v-col cols="4" md="2">
                <u-file-input-filled-pill placeholder="Filled Pill Placeholder"></u-file-input-filled-pill>
            </v-col>
        </v-row>
        <!-- E: Filled -->
        <br />

        <!-- S: Solo -->
        <h2>Solo</h2>
        <p><u>u-file-input-solo-{}</u></p>
        <br />
        <v-row align="center">
            <v-col cols="4" md="2">
                <u-file-input-solo-tile placeholder="Solo Tile Placeholder"></u-file-input-solo-tile>
            </v-col>
            <v-col cols="4" md="2">
                <u-file-input-solo-rounded placeholder="Solo Rounded Placeholder"></u-file-input-solo-rounded>
            </v-col>
            <v-col cols="4" md="2">
                <u-file-input-solo-pill placeholder="Solo Pill Placeholder"></u-file-input-solo-pill>
            </v-col>
        </v-row>
        <!-- E: Solo -->
        <!-- // Styles -->

        <v-divider class="my-30px" />

        <!-- Density -->
        <h1>Density</h1>
        <h3>{density} : compact, (기본-comfortable), default</h3>
        <p><u>class="v-input--density-{density}"</u></p>
        <br />
        <!-- S: Underlined -->
        <h2>Underlined</h2>
        <v-row align="center">
            <v-col cols="6" md="4">
                <v-file-input v-bind="{ ...attrs_file_input_common, ...$attrs }" label="Label" class="v-input--density-compact"></v-file-input>
            </v-col>
            <v-col cols="6" md="4">
                <v-file-input v-bind="{ ...attrs_file_input_common, ...$attrs }" label="Label"></v-file-input>
            </v-col>
            <v-col cols="6" md="4">
                <v-file-input v-bind="{ ...attrs_file_input_common, ...$attrs }" label="Label" class="v-input--density-default"></v-file-input>
            </v-col>
            <v-col cols="6" md="4">
                <v-file-input v-bind="{ ...attrs_file_input_common, ...$attrs }" placeholder="Placeholder" class="v-input--density-compact"></v-file-input>
            </v-col>
            <v-col cols="6" md="4">
                <v-file-input v-bind="{ ...attrs_file_input_common, ...$attrs }" placeholder="Placeholder"></v-file-input>
            </v-col>
            <v-col cols="6" md="4">
                <v-file-input v-bind="{ ...attrs_file_input_common, ...$attrs }" placeholder="Placeholder" class="v-input--density-default"></v-file-input>
            </v-col>
        </v-row>
        <!-- E: Underlined -->
        <br />

        <!-- S: Outlined -->
        <h2>Outlined</h2>
        <v-row align="center">
            <v-col cols="6" md="4">
                <v-file-input v-bind="{ ...attrs_file_input_common, ...$attrs }" outlined label="Label" class="v-input--density-compact"></v-file-input>
            </v-col>
            <v-col cols="6" md="4">
                <v-file-input v-bind="{ ...attrs_file_input_common, ...$attrs }" outlined label="Label"></v-file-input>
            </v-col>
            <v-col cols="6" md="4">
                <v-file-input v-bind="{ ...attrs_file_input_common, ...$attrs }" outlined label="Label" class="v-input--density-default"></v-file-input>
            </v-col>
            <v-col cols="6" md="4">
                <v-file-input v-bind="{ ...attrs_file_input_common, ...$attrs }" outlined placeholder="Placeholder" class="v-input--density-compact"></v-file-input>
            </v-col>
            <v-col cols="6" md="4">
                <v-file-input v-bind="{ ...attrs_file_input_common, ...$attrs }" outlined placeholder="Placeholder"></v-file-input>
            </v-col>
            <v-col cols="6" md="4">
                <v-file-input v-bind="{ ...attrs_file_input_common, ...$attrs }" outlined placeholder="Placeholder" class="v-input--density-default"></v-file-input>
            </v-col>
        </v-row>
        <!-- E: Outlined -->
        <br />

        <!-- S: Label Inner -->
        <h2>Label Inner</h2>
        <v-row align="center">
            <v-col cols="6" md="4">
                <v-file-input v-bind="{ ...attrs_file_input_common, ...$attrs }" outlined label="Label" class="v-input--density-compact v-text-field--label-inner"></v-file-input>
            </v-col>
            <v-col cols="6" md="4">
                <v-file-input v-bind="{ ...attrs_file_input_common, ...$attrs }" outlined label="Label" class="v-text-field--label-inner"></v-file-input>
            </v-col>
            <v-col cols="6" md="4">
                <v-file-input v-bind="{ ...attrs_file_input_common, ...$attrs }" outlined label="Label" class="v-input--density-default v-text-field--label-inner"></v-file-input>
            </v-col>
        </v-row>
        <!-- E: Label Inner -->
        <br />

        <!-- S: Filled -->
        <h2>Filled</h2>
        <v-row align="center">
            <v-col cols="6" md="4">
                <v-file-input v-bind="{ ...attrs_file_input_common, ...$attrs }" filled label="Label" class="v-input--density-compact"></v-file-input>
            </v-col>
            <v-col cols="6" md="4">
                <v-file-input v-bind="{ ...attrs_file_input_common, ...$attrs }" filled label="Label"></v-file-input>
            </v-col>
            <v-col cols="6" md="4">
                <v-file-input v-bind="{ ...attrs_file_input_common, ...$attrs }" filled label="Label" class="v-input--density-default"></v-file-input>
            </v-col>
            <v-col cols="6" md="4">
                <v-file-input v-bind="{ ...attrs_file_input_common, ...$attrs }" filled placeholder="Placeholder" class="v-input--density-compact"></v-file-input>
            </v-col>
            <v-col cols="6" md="4">
                <v-file-input v-bind="{ ...attrs_file_input_common, ...$attrs }" filled placeholder="Placeholder"></v-file-input>
            </v-col>
            <v-col cols="6" md="4">
                <v-file-input v-bind="{ ...attrs_file_input_common, ...$attrs }" filled placeholder="Placeholder" class="v-input--density-default"></v-file-input>
            </v-col>
        </v-row>
        <!-- E: Filled -->
        <br />

        <!-- S: Solo -->
        <h2>Solo</h2>
        <v-row align="center">
            <v-col cols="6" md="4">
                <v-file-input v-bind="{ ...attrs_file_input_common, ...$attrs }" solo placeholder="Placeholder" class="v-input--density-compact"></v-file-input>
            </v-col>
            <v-col cols="6" md="4">
                <v-file-input v-bind="{ ...attrs_file_input_common, ...$attrs }" solo placeholder="Placeholder"></v-file-input>
            </v-col>
            <v-col cols="6" md="4">
                <v-file-input v-bind="{ ...attrs_file_input_common, ...$attrs }" solo placeholder="Placeholder" class="v-input--density-default"></v-file-input>
            </v-col>
        </v-row>
        <!-- E: Solo -->
        <!-- // Density -->

        <v-divider class="my-30px" />

        <!-- Width -->
        <h1>Width</h1>
        <p><u>w-{breakpoint}-{size}</u></p>
        <p><u>v-input--width-fixed</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <v-file-input v-bind="{ ...attrs_file_input_common, ...$attrs }" label="Custom Width" class="min-w-140px min-w-md-200px"></v-file-input>
            </v-col>
            <v-col cols="12">
                <v-file-input v-bind="{ ...attrs_file_input_common, ...$attrs }" label="default block"></v-file-input>
            </v-col>
            <v-col cols="auto">
                <v-file-input v-bind="{ ...attrs_file_input_common, ...$attrs }" label="Width Fixed" class="v-input--density-compact v-input--width-fixed"></v-file-input>
            </v-col>
            <v-col cols="auto">
                <v-file-input v-bind="{ ...attrs_file_input_common, ...$attrs }" label="Width Fixed" class="v-input--width-fixed"></v-file-input>
            </v-col>
            <v-col cols="auto">
                <v-file-input v-bind="{ ...attrs_file_input_common, ...$attrs }" label="Width Fixed" class="v-input--density-default v-input--width-fixed"></v-file-input>
            </v-col>
        </v-row>
        <!-- // Width -->

        <v-divider class="my-30px" />

        <!-- State -->
        <h1>State</h1>
        <!-- S: Disabled -->
        <h2>Disabled</h2>
        <p><u>disabled</u></p>
        <br />
        <v-row align="center">
            <v-col cols="6" md="3">
                <v-file-input v-bind="{ ...attrs_file_input_common, ...$attrs }" disabled label="Label"></v-file-input>
            </v-col>
            <v-col cols="6" md="3">
                <v-file-input v-bind="{ ...attrs_file_input_common, ...$attrs }" disabled placeholder="Placeholder"></v-file-input>
            </v-col>
        </v-row>
        <!-- E: Disabled -->
        <!-- // State -->

        <v-divider class="my-30px" />

        <!-- Variant -->
        <h1>Variant</h1>
        <h3>{variant} : (기본-underlined), outlined, label-inner(outlined), filled, solo</h3>
        <p>v-text-field--label-inner은 클래스로 입력해주세요. label-inner은 outlined속성과 같이 사용합니다.</p>
        <p>solo는 label 스타일이 없습니다.</p>
        <p><u>{variant}</u></p>
        <p><u>class="v-text-field--label-inner"</u></p>
        <br />
        <v-row align="center">
            <v-col cols="6" md="3">
                <v-file-input v-bind="{ ...attrs_file_input_common, ...$attrs }" label="Label"></v-file-input>
            </v-col>
            <v-col cols="6" md="3">
                <v-file-input v-bind="{ ...attrs_file_input_common, ...$attrs }" placeholder="Placeholder"></v-file-input>
            </v-col>
            <v-col cols="6" md="3">
                <v-file-input v-bind="{ ...attrs_file_input_common, ...$attrs }" outlined label="Label"></v-file-input>
            </v-col>
            <v-col cols="6" md="3">
                <v-file-input v-bind="{ ...attrs_file_input_common, ...$attrs }" outlined placeholder="Placeholder"></v-file-input>
            </v-col>
            <v-col cols="6" md="3">
                <v-file-input v-bind="{ ...attrs_file_input_common, ...$attrs }" outlined label="Label Inner" class="v-text-field--label-inner"></v-file-input>
            </v-col>
            <v-col cols="6" md="3">
                <v-file-input v-bind="{ ...attrs_file_input_common, ...$attrs }" outlined placeholder="Placeholder" class="v-text-field--label-inner"></v-file-input>
            </v-col>
            <v-col cols="6" md="3">
                <v-file-input v-bind="{ ...attrs_file_input_common, ...$attrs }" filled label="Label"></v-file-input>
            </v-col>
            <v-col cols="6" md="3">
                <v-file-input v-bind="{ ...attrs_file_input_common, ...$attrs }" filled placeholder="Placeholder"></v-file-input>
            </v-col>
            <v-col cols="6" md="3">
                <v-file-input v-bind="{ ...attrs_file_input_common, ...$attrs }" solo placeholder="Placeholder"></v-file-input>
            </v-col>
        </v-row>
        <!-- // Variant -->

        <v-divider class="my-30px" />

        <!-- Rounded -->
        <h1>Rounded</h1>
        <h3>{rounded} : (기본), pill, 0</h3>
        <p><u>class="rounded-{rounded}"</u></p>
        <br />
        <v-row align="center">
            <v-col cols="6" md="4">
                <v-file-input v-bind="{ ...attrs_file_input_common, ...$attrs }" outlined label="Default"></v-file-input>
            </v-col>
            <v-col cols="6" md="4">
                <v-file-input v-bind="{ ...attrs_file_input_common, ...$attrs }" outlined label="Pill" class="rounded-pill"></v-file-input>
            </v-col>
            <v-col cols="6" md="4">
                <v-file-input v-bind="{ ...attrs_file_input_common, ...$attrs }" outlined label="Tile" class="rounded-0"></v-file-input>
            </v-col>
            <v-col cols="6" md="4">
                <v-file-input v-bind="{ ...attrs_file_input_common, ...$attrs }" label="Default" filled></v-file-input>
            </v-col>
            <v-col cols="6" md="4">
                <v-file-input v-bind="{ ...attrs_file_input_common, ...$attrs }" label="Pill" filled class="rounded-pill"></v-file-input>
            </v-col>
            <v-col cols="6" md="4">
                <v-file-input v-bind="{ ...attrs_file_input_common, ...$attrs }" label="Tile" filled class="rounded-0"></v-file-input>
            </v-col>
            <v-col cols="6" md="4">
                <v-file-input v-bind="{ ...attrs_file_input_common, ...$attrs }" outlined label="Default Label Inner" class="v-text-field--label-inner"></v-file-input>
            </v-col>
            <v-col cols="6" md="4">
                <v-file-input v-bind="{ ...attrs_file_input_common, ...$attrs }" outlined label="Pill Label Inner" class="rounded-pill v-text-field--label-inner"></v-file-input>
            </v-col>
            <v-col cols="6" md="4">
                <v-file-input v-bind="{ ...attrs_file_input_common, ...$attrs }" outlined label="Tile Label Inner" class="rounded-0 v-text-field--label-inner"></v-file-input>
            </v-col>
        </v-row>
        <!-- // Rounded -->

        <v-divider class="my-30px" />

        <!-- Icon -->
        <h1>Icon</h1>
        <h3>{direction} : prepend, prepend-inner, append, append-outer, clear-icon</h3>
        <p>vue에서 렌더링 하는데 소모하는 시간이 있어 되도록이면 icon=""을 사용하지 않고 template v-slot으로 아이콘을 추가합니다.</p>
        <p>이벤트가 있는 아이콘은 template v-slot으로 사용하면 이벤트가 작동되지 않아 {direction}=""형태로 수정합니다. prepend-icon도 {direction}=""형태로 수정합니다. 이때 아이콘은 mdi아이콘만 사용할 수 있습니다.</p>
        <p><u>#{direction}</u></p>
        <p><u>{direction}=""</u></p>
        <br />
        <v-row align="center">
            <v-col cols="6" md="3">
                <v-file-input v-bind="{ ...attrs_file_input_common, ...$attrs }" outlined prepend-icon="mdi-heart" label="Prepend Icon"></v-file-input>
            </v-col>
            <v-col cols="6" md="3">
                <v-file-input v-bind="{ ...attrs_file_input_common, ...$attrs }" outlined prepend-icon="" label="Prepend Inner Icon">
                    <template #prepend-inner><u-icon>favorite</u-icon></template>
                </v-file-input>
            </v-col>
            <v-col cols="6" md="3">
                <v-file-input v-bind="{ ...attrs_file_input_common, ...$attrs }" outlined prepend-icon="" placeholder="Append Outer Icon">
                    <template #append-outer><u-icon>favorite</u-icon></template>
                </v-file-input>
            </v-col>
            <v-col cols="6" md="3">
                <v-file-input v-bind="{ ...attrs_file_input_common, ...$attrs }" outlined prepend-icon="" placeholder="Append Icon">
                    <template #append><u-icon>favorite</u-icon></template>
                </v-file-input>
            </v-col>
            <v-col cols="6" md="3">
                <v-file-input v-bind="{ ...attrs_file_input_common, ...$attrs }" outlined prepend-icon="mdi-heart" clear-icon="delete" placeholder="Prepend Append Icon">
                    <template #append-outer><u-icon>check</u-icon></template>
                </v-file-input>
            </v-col>
            <v-col cols="6" md="3">
                <v-file-input v-bind="{ ...attrs_file_input_common, ...$attrs }" outlined prepend-icon="" clear-icon="delete" placeholder="Prepend Append Icon">
                    <template #prepend-inner><u-icon>favorite</u-icon></template>
                    <template #append><u-icon>check</u-icon></template>
                </v-file-input>
            </v-col>
        </v-row>
        <!-- // Icon -->

        <v-divider class="my-30px" />

        <!-- Chips -->
        <h1>Chips</h1>
        <p><u>chips</u></p>
        <br />
        <v-row align="center">
            <v-col cols="6">
                <v-file-input v-bind="{ ...attrs_file_input_common, ...$attrs }" chips multiple label="Chips"></v-file-input>
            </v-col>
            <v-col cols="6">
                <v-file-input v-bind="{ ...attrs_file_input_common, ...$attrs }" chips multiple placeholder="Placeholder"></v-file-input>
            </v-col>
            <v-col cols="6">
                <v-file-input v-bind="{ ...attrs_file_input_common, ...$attrs }" chips multiple label="Chips Custom">
                    <template #selection="{ text }">
                        <v-chip size="x-small" label color="primary">
                            {{ text }}
                        </v-chip>
                    </template>
                </v-file-input>
            </v-col>
            <v-col cols="6">
                <v-file-input v-bind="{ ...attrs_file_input_common, ...$attrs }" chips multiple placeholder="Placeholder">
                    <template #selection="{ text }">
                        <v-chip size="x-small" label color="primary">
                            {{ text }}
                        </v-chip>
                    </template>
                </v-file-input>
            </v-col>
        </v-row>
        <!-- // Chips -->

        <v-divider class="my-30px" />

        <!-- Counter -->
        <h1>Counter</h1>
        <p>counter와 함께 show-size 속성을 사용하면 총 파일 수와 크기가 아래에 표시됩니다.</p>
        <p><u>show-size counter multiple</u></p>
        <br />
        <v-row align="center">
            <v-col cols="6">
                <v-file-input v-bind="{ ...attrs_file_input_common, ...$attrs }" show-size counter multiple label="Counter"></v-file-input>
            </v-col>
            <v-col cols="6">
                <v-file-input v-bind="{ ...attrs_file_input_common, ...$attrs }" show-size counter multiple></v-file-input>
            </v-col>
        </v-row>
        <!-- // Counter -->

        <v-divider class="my-30px" />

        <!-- Supporting Text -->
        <h1>Supporting Text</h1>
        <!-- S: Messages -->
        <h2>Messages</h2>
        <p><u>messages=""</u></p>
        <br />
        <v-row align="center">
            <v-col cols="6" md="3">
                <v-file-input v-bind="{ ...attrs_file_input_common, ...$attrs }" messages="Messages" label="Messages"></v-file-input>
            </v-col>
        </v-row>
        <!-- E: Messages -->
        <br />

        <!-- S: Persistence -->
        <h2>Persistence</h2>
        <h3>{persistent} : hint</h3>
        <p><u>persistent-{persistent}</u></p>
        <p>persistent-hint - 항상 힌트가 보입니다.</p>
        <br />
        <v-row align="center">
            <v-col cols="6" md="3">
                <v-file-input v-bind="{ ...attrs_file_input_common, ...$attrs }" persistent-hint hint="Hint" label="persistent-hint"></v-file-input>
            </v-col>
        </v-row>
        <!-- E: Persistence -->
        <!-- // Supporting Text -->
    </div>
</template>

<script>
import { attrs_file_input_common } from "@/assets/variables";

import FileInputPrimary from "@/components/publish/parents/forms/file-input-primary.vue";
import FileInputSecondary from "@/components/publish/parents/forms/file-input-secondary.vue";
import FileInputTertiary from "@/components/publish/parents/forms/file-input-tertiary.vue";
import FileInputQuarternary from "@/components/publish/parents/forms/file-input-quarternary.vue";

import UFileInputOutlinedTile from "@/components/publish/styles/forms/u-file-input-outlined-tile.vue";
import UFileInputOutlinedRounded from "@/components/publish/styles/forms/u-file-input-outlined-rounded.vue";
import UFileInputOutlinedPill from "@/components/publish/styles/forms/u-file-input-outlined-pill.vue";
import UFileInputFilledTile from "@/components/publish/styles/forms/u-file-input-filled-tile.vue";
import UFileInputFilledRounded from "@/components/publish/styles/forms/u-file-input-filled-rounded.vue";
import UFileInputFilledPill from "@/components/publish/styles/forms/u-file-input-filled-pill.vue";
import UFileInputSoloTile from "@/components/publish/styles/forms/u-file-input-solo-tile.vue";
import UFileInputSoloRounded from "@/components/publish/styles/forms/u-file-input-solo-rounded.vue";
import UFileInputSoloPill from "@/components/publish/styles/forms/u-file-input-solo-pill.vue";
import UFileInputUnderlined from "@/components/publish/styles/forms/u-file-input-underlined.vue";

import UIcon from "@/components/publish/styles/icons/u-icon.vue";

export default {
    props: {},
    components: {
        FileInputPrimary,
        FileInputSecondary,
        FileInputTertiary,
        FileInputQuarternary,

        UFileInputOutlinedTile,
        UFileInputOutlinedRounded,
        UFileInputOutlinedPill,
        UFileInputFilledTile,
        UFileInputFilledRounded,
        UFileInputFilledPill,
        UFileInputSoloTile,
        UFileInputSoloRounded,
        UFileInputSoloPill,
        UFileInputUnderlined,

        UIcon,
    },
    data() {
        return {
            attrs_file_input_common,
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
