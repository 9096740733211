<template>
    <header-search class="header-search--underlined" InputTag="u-text-field-underlined">
        <slot />
    </header-search>
</template>

<script>
import HeaderSearch from "@/components/publish/styles/headers/header-search.vue";

export default {
    props: {},
    components: {
        HeaderSearch,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
