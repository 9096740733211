var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h1', [_vm._v("Parents")]), _vm._m(0), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('tooltip-secondary', {
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({}, 'v-btn', attrs, false), on), [_vm._v(" Tooltip Primary ")])];
      }
    }])
  }, [_c('span', [_vm._v("Tooltip Primary")])])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('tooltip-secondary', {
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref2) {
        var on = _ref2.on,
          attrs = _ref2.attrs;
        return [_c('v-btn', _vm._g(_vm._b({}, 'v-btn', attrs, false), on), [_vm._v(" Tooltip Secondary ")])];
      }
    }])
  }, [_c('div', {
    staticClass: "v-tooltip__tit"
  }, [_vm._v("제목을입력해주세요")]), _c('p', [_vm._v("동해물과 백두산이 마르고 닳도록 하느님이 보우하사 우리나라 만세 무궁화 삼천리 화려 강산 대한 사람 대한으로 길이 보전하세")])])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('tooltip-tertiary', {
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref3) {
        var on = _ref3.on,
          attrs = _ref3.attrs;
        return [_c('v-btn', _vm._g(_vm._b({}, 'v-btn', attrs, false), on), [_vm._v(" Tooltip Tertiary ")])];
      }
    }])
  }, [_c('span', [_vm._v("Tooltip Tertiary")])])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('tooltip-quarternary', {
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref4) {
        var on = _ref4.on,
          attrs = _ref4.attrs;
        return [_c('v-btn', _vm._g(_vm._b({}, 'v-btn', attrs, false), on), [_vm._v(" Tooltip Quarternary ")])];
      }
    }])
  }, [_c('span', [_vm._v("Tooltip Quarternary")])])], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Styles")]), _c('h2', [_vm._v("Opacity")]), _vm._m(1), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-tooltip-opacity-tile', {
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref5) {
        var on = _ref5.on,
          attrs = _ref5.attrs;
        return [_c('v-btn', _vm._g(_vm._b({}, 'v-btn', attrs, false), on), [_vm._v(" Tooltip Opacity Tile ")])];
      }
    }])
  }, [_c('span', [_vm._v("Tooltip Opacity Tile")])])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-tooltip-opacity-rounded', {
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref6) {
        var on = _ref6.on,
          attrs = _ref6.attrs;
        return [_c('v-btn', _vm._g(_vm._b({}, 'v-btn', attrs, false), on), [_vm._v(" Tooltip Opacity Rounded ")])];
      }
    }])
  }, [_c('span', [_vm._v("Tooltip Opacity Rounded")])])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-tooltip-opacity-pill', {
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref7) {
        var on = _ref7.on,
          attrs = _ref7.attrs;
        return [_c('v-btn', _vm._g(_vm._b({}, 'v-btn', attrs, false), on), [_vm._v(" Tooltip Opacity Pill ")])];
      }
    }])
  }, [_c('span', [_vm._v("Tooltip Opacity Pill")])])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-tooltip-opacity-tile-lg', {
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref8) {
        var on = _ref8.on,
          attrs = _ref8.attrs;
        return [_c('v-btn', _vm._g(_vm._b({}, 'v-btn', attrs, false), on), [_vm._v(" Tooltip Opacity Tile Large ")])];
      }
    }])
  }, [_c('div', {
    staticClass: "v-tooltip__tit"
  }, [_vm._v("제목을입력해주세요")]), _c('p', [_vm._v("동해물과 백두산이 마르고 닳도록 하느님이 보우하사 우리나라 만세 무궁화 삼천리 화려 강산 대한 사람 대한으로 길이 보전하세")])])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-tooltip-opacity-rounded-lg', {
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref9) {
        var on = _ref9.on,
          attrs = _ref9.attrs;
        return [_c('v-btn', _vm._g(_vm._b({}, 'v-btn', attrs, false), on), [_vm._v(" Tooltip Opacity Rounded Large ")])];
      }
    }])
  }, [_c('div', {
    staticClass: "v-tooltip__tit"
  }, [_vm._v("제목을입력해주세요")]), _c('p', [_vm._v("동해물과 백두산이 마르고 닳도록 하느님이 보우하사 우리나라 만세 무궁화 삼천리 화려 강산 대한 사람 대한으로 길이 보전하세")])])], 1)], 1), _c('br'), _c('h2', [_vm._v("Outlined")]), _vm._m(2), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-tooltip-outlined-tile', {
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref10) {
        var on = _ref10.on,
          attrs = _ref10.attrs;
        return [_c('v-btn', _vm._g(_vm._b({}, 'v-btn', attrs, false), on), [_vm._v(" Tooltip Outlined Tile ")])];
      }
    }])
  }, [_c('span', [_vm._v("Tooltip Outlined Tile")])])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-tooltip-outlined-rounded', {
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref11) {
        var on = _ref11.on,
          attrs = _ref11.attrs;
        return [_c('v-btn', _vm._g(_vm._b({}, 'v-btn', attrs, false), on), [_vm._v(" Tooltip Outlined Rounded ")])];
      }
    }])
  }, [_c('span', [_vm._v("Tooltip Outlined Rounded")])])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-tooltip-outlined-pill', {
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref12) {
        var on = _ref12.on,
          attrs = _ref12.attrs;
        return [_c('v-btn', _vm._g(_vm._b({}, 'v-btn', attrs, false), on), [_vm._v(" Tooltip Outlined Pill ")])];
      }
    }])
  }, [_c('span', [_vm._v("Tooltip Outlined Pill")])])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-tooltip-outlined-tile-lg', {
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref13) {
        var on = _ref13.on,
          attrs = _ref13.attrs;
        return [_c('v-btn', _vm._g(_vm._b({}, 'v-btn', attrs, false), on), [_vm._v(" Tooltip Outlined Tile Large ")])];
      }
    }])
  }, [_c('div', {
    staticClass: "v-tooltip__tit"
  }, [_vm._v("제목을입력해주세요")]), _c('p', [_vm._v("동해물과 백두산이 마르고 닳도록 하느님이 보우하사 우리나라 만세 무궁화 삼천리 화려 강산 대한 사람 대한으로 길이 보전하세")])])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-tooltip-outlined-rounded-lg', {
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref14) {
        var on = _ref14.on,
          attrs = _ref14.attrs;
        return [_c('v-btn', _vm._g(_vm._b({}, 'v-btn', attrs, false), on), [_vm._v(" Tooltip Outlined Rounded Large ")])];
      }
    }])
  }, [_c('div', {
    staticClass: "v-tooltip__tit"
  }, [_vm._v("제목을입력해주세요")]), _c('p', [_vm._v("동해물과 백두산이 마르고 닳도록 하느님이 보우하사 우리나라 만세 무궁화 삼천리 화려 강산 대한 사람 대한으로 길이 보전하세")])])], 1)], 1), _c('br'), _c('h2', [_vm._v("Elevation")]), _vm._m(3), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-tooltip-elevation-tile', {
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref15) {
        var on = _ref15.on,
          attrs = _ref15.attrs;
        return [_c('v-btn', _vm._g(_vm._b({}, 'v-btn', attrs, false), on), [_vm._v(" Tooltip Elevation Tile ")])];
      }
    }])
  }, [_c('span', [_vm._v("Tooltip Elevation Tile")])])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-tooltip-elevation-rounded', {
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref16) {
        var on = _ref16.on,
          attrs = _ref16.attrs;
        return [_c('v-btn', _vm._g(_vm._b({}, 'v-btn', attrs, false), on), [_vm._v(" Tooltip Elevation Rounded ")])];
      }
    }])
  }, [_c('span', [_vm._v("Tooltip Elevation Rounded")])])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-tooltip-elevation-pill', {
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref17) {
        var on = _ref17.on,
          attrs = _ref17.attrs;
        return [_c('v-btn', _vm._g(_vm._b({}, 'v-btn', attrs, false), on), [_vm._v(" Tooltip Elevation Pill ")])];
      }
    }])
  }, [_c('span', [_vm._v("Tooltip Elevation Pill")])])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-tooltip-elevation-tile-lg', {
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref18) {
        var on = _ref18.on,
          attrs = _ref18.attrs;
        return [_c('v-btn', _vm._g(_vm._b({}, 'v-btn', attrs, false), on), [_vm._v(" Tooltip Elevation Tile Large ")])];
      }
    }])
  }, [_c('div', {
    staticClass: "v-tooltip__tit"
  }, [_vm._v("제목을입력해주세요")]), _c('p', [_vm._v("동해물과 백두산이 마르고 닳도록 하느님이 보우하사 우리나라 만세 무궁화 삼천리 화려 강산 대한 사람 대한으로 길이 보전하세")])])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-tooltip-elevation-rounded-lg', {
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref19) {
        var on = _ref19.on,
          attrs = _ref19.attrs;
        return [_c('v-btn', _vm._g(_vm._b({}, 'v-btn', attrs, false), on), [_vm._v(" Tooltip Elevation Rounded Large ")])];
      }
    }])
  }, [_c('div', {
    staticClass: "v-tooltip__tit"
  }, [_vm._v("제목을입력해주세요")]), _c('p', [_vm._v("동해물과 백두산이 마르고 닳도록 하느님이 보우하사 우리나라 만세 무궁화 삼천리 화려 강산 대한 사람 대한으로 길이 보전하세")])])], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Location")]), _c('h3', [_vm._v("{location} : start, end, top, (기본-bottom)")]), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-tooltip', {
    attrs: {
      "left": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref20) {
        var on = _ref20.on,
          attrs = _ref20.attrs;
        return [_c('v-btn', _vm._g(_vm._b({}, 'v-btn', attrs, false), on), [_vm._v("Tooltip Left")])];
      }
    }])
  }, [_c('span', [_vm._v("Tooltip Left")])])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-tooltip', {
    attrs: {
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref21) {
        var on = _ref21.on,
          attrs = _ref21.attrs;
        return [_c('v-btn', _vm._g(_vm._b({}, 'v-btn', attrs, false), on), [_vm._v("Tooltip Right")])];
      }
    }])
  }, [_c('span', [_vm._v("Tooltip Right")])])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-tooltip', {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref22) {
        var on = _ref22.on,
          attrs = _ref22.attrs;
        return [_c('v-btn', _vm._g(_vm._b({}, 'v-btn', attrs, false), on), [_vm._v("Tooltip Top")])];
      }
    }])
  }, [_c('span', [_vm._v("Tooltip Top")])])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref23) {
        var on = _ref23.on,
          attrs = _ref23.attrs;
        return [_c('v-btn', _vm._g(_vm._b({}, 'v-btn', attrs, false), on), [_vm._v("Tooltip Bottom")])];
      }
    }])
  }, [_c('span', [_vm._v("Tooltip Bottom")])])], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Size")]), _vm._m(4), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-tooltip', {
    attrs: {
      "bottom": "",
      "content-class": "v-tooltip--lg"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref24) {
        var on = _ref24.on,
          attrs = _ref24.attrs;
        return [_c('v-btn', _vm._g(_vm._b({}, 'v-btn', attrs, false), on), [_vm._v("Size Lg")])];
      }
    }])
  }, [_c('p', [_vm._v("동해물과 백두산이 마르고 닳도록 하느님이 보우하사 우리나라 만세 무궁화 삼천리 화려 강산 대한 사람 대한으로 길이 보전하세")])])], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("State")]), _c('h2', [_vm._v("Activator")]), _c('p', [_vm._v("activator에 마우스오버 시 활성화됩니다.")]), _vm._m(5), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref25) {
        var on = _ref25.on,
          attrs = _ref25.attrs;
        return [_c('v-btn', _vm._g(_vm._b({}, 'v-btn', attrs, false), on), [_vm._v("Tooltip Default")])];
      }
    }])
  }, [_c('span', [_vm._v("Tooltip Default")])])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "id": "tooltip-id"
    }
  }, [_vm._v("Tooltip Id")]), _c('v-tooltip', {
    attrs: {
      "bottom": "",
      "activator": "#tooltip-id"
    }
  }, [_vm._v("Tooltip Id")])], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Rounded")]), _c('h3', [_vm._v("{rounded} : (기본), xs, sm, (md), lg, xl, pill, 0")]), _vm._m(6), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-tooltip', {
    attrs: {
      "bottom": "",
      "content-class": "rounded-2222222222"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref26) {
        var on = _ref26.on,
          attrs = _ref26.attrs;
        return [_c('v-btn', _vm._g(_vm._b({}, 'v-btn', attrs, false), on), [_vm._v("Rounded Default")])];
      }
    }])
  }, [_c('span', [_vm._v("Rounded Default")])])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-tooltip', {
    attrs: {
      "bottom": "",
      "content-class": "rounded-xs"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref27) {
        var on = _ref27.on,
          attrs = _ref27.attrs;
        return [_c('v-btn', _vm._g(_vm._b({}, 'v-btn', attrs, false), on), [_vm._v("Rounded XS")])];
      }
    }])
  }, [_c('span', [_vm._v("Rounded XS")])])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-tooltip', {
    attrs: {
      "bottom": "",
      "content-class": "rounded-sm"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref28) {
        var on = _ref28.on,
          attrs = _ref28.attrs;
        return [_c('v-btn', _vm._g(_vm._b({}, 'v-btn', attrs, false), on), [_vm._v("Rounded Sm")])];
      }
    }])
  }, [_c('span', [_vm._v("Rounded Sm")])])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-tooltip', {
    attrs: {
      "bottom": "",
      "content-class": "rounded"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref29) {
        var on = _ref29.on,
          attrs = _ref29.attrs;
        return [_c('v-btn', _vm._g(_vm._b({}, 'v-btn', attrs, false), on), [_vm._v("Rounded")])];
      }
    }])
  }, [_c('span', [_vm._v("Rounded")])])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-tooltip', {
    attrs: {
      "bottom": "",
      "content-class": "rounded-lg"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref30) {
        var on = _ref30.on,
          attrs = _ref30.attrs;
        return [_c('v-btn', _vm._g(_vm._b({}, 'v-btn', attrs, false), on), [_vm._v("Rounded Lg")])];
      }
    }])
  }, [_c('span', [_vm._v("Rounded Lg")])])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-tooltip', {
    attrs: {
      "bottom": "",
      "content-class": "rounded-xl"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref31) {
        var on = _ref31.on,
          attrs = _ref31.attrs;
        return [_c('v-btn', _vm._g(_vm._b({}, 'v-btn', attrs, false), on), [_vm._v("Rounded XL")])];
      }
    }])
  }, [_c('span', [_vm._v("Rounded XL")])])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-tooltip', {
    attrs: {
      "bottom": "",
      "content-class": "rounded-pill"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref32) {
        var on = _ref32.on,
          attrs = _ref32.attrs;
        return [_c('v-btn', _vm._g(_vm._b({}, 'v-btn', attrs, false), on), [_vm._v("Rounded Pill")])];
      }
    }])
  }, [_c('span', [_vm._v("Rounded Pill")])])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-tooltip', {
    attrs: {
      "bottom": "",
      "content-class": "rounded-0"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref33) {
        var on = _ref33.on,
          attrs = _ref33.attrs;
        return [_c('v-btn', _vm._g(_vm._b({}, 'v-btn', attrs, false), on), [_vm._v("Rounded 0")])];
      }
    }])
  }, [_c('span', [_vm._v("Rounded 0")])])], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Color")]), _c('h2', [_vm._v("Text")]), _vm._m(7), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-tooltip', {
    attrs: {
      "bottom": "",
      "content-class": "primary--text"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref34) {
        var on = _ref34.on,
          attrs = _ref34.attrs;
        return [_c('v-btn', _vm._g(_vm._b({}, 'v-btn', attrs, false), on), [_vm._v("Text Primary")])];
      }
    }])
  }, [_c('span', [_vm._v("Text Primary")])])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-tooltip', {
    attrs: {
      "bottom": "",
      "content-class": "secondary--text"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref35) {
        var on = _ref35.on,
          attrs = _ref35.attrs;
        return [_c('v-btn', _vm._g(_vm._b({}, 'v-btn', attrs, false), on), [_vm._v("Text Secondary")])];
      }
    }])
  }, [_c('span', [_vm._v("Text Secondary")])])], 1)], 1), _c('br'), _c('h2', [_vm._v("Background")]), _vm._m(8), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-tooltip', {
    attrs: {
      "bottom": "",
      "content-class": "white"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref36) {
        var on = _ref36.on,
          attrs = _ref36.attrs;
        return [_c('v-btn', _vm._g(_vm._b({}, 'v-btn', attrs, false), on), [_vm._v("Background White")])];
      }
    }])
  }, [_c('span', [_vm._v("Background White")])])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-tooltip', {
    attrs: {
      "bottom": "",
      "content-class": "primary"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref37) {
        var on = _ref37.on,
          attrs = _ref37.attrs;
        return [_c('v-btn', _vm._g(_vm._b({}, 'v-btn', attrs, false), on), [_vm._v("Background Primary")])];
      }
    }])
  }, [_c('span', [_vm._v("Background Primary")])])], 1)], 1), _c('br'), _c('h2', [_vm._v("Border")]), _vm._m(9), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-tooltip', {
    attrs: {
      "bottom": "",
      "content-class": "v-tooltip--outlined"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref38) {
        var on = _ref38.on,
          attrs = _ref38.attrs;
        return [_c('v-btn', _vm._g(_vm._b({}, 'v-btn', attrs, false), on), [_vm._v("Border")])];
      }
    }])
  }, [_c('span', [_vm._v("Border")])])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-tooltip', {
    attrs: {
      "bottom": "",
      "content-class": "v-tooltip--outlined primary primary--text"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref39) {
        var on = _ref39.on,
          attrs = _ref39.attrs;
        return [_c('v-btn', _vm._g(_vm._b({}, 'v-btn', attrs, false), on), [_vm._v("Border Primary")])];
      }
    }])
  }, [_c('span', [_vm._v("Border Primary")])])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-tooltip', {
    attrs: {
      "bottom": "",
      "content-class": "v-tooltip--outlined secondary secondary--text"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref40) {
        var on = _ref40.on,
          attrs = _ref40.attrs;
        return [_c('v-btn', _vm._g(_vm._b({}, 'v-btn', attrs, false), on), [_vm._v("Border Secondary")])];
      }
    }])
  }, [_c('span', [_vm._v("Border Secondary")])])], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Elevation")]), _c('h3', [_vm._v("{elevation} : 0 ~ 24")]), _vm._m(10), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-tooltip', {
    attrs: {
      "bottom": "",
      "content-class": "elevation-2"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref41) {
        var on = _ref41.on,
          attrs = _ref41.attrs;
        return [_c('v-btn', _vm._g(_vm._b({}, 'v-btn', attrs, false), on), [_vm._v("Elevation 2")])];
      }
    }])
  }, [_c('span', [_vm._v("Elevation 2")])])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-tooltip', {
    attrs: {
      "bottom": "",
      "content-class": "elevation-10"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref42) {
        var on = _ref42.on,
          attrs = _ref42.attrs;
        return [_c('v-btn', _vm._g(_vm._b({}, 'v-btn', attrs, false), on), [_vm._v("Elevation 10")])];
      }
    }])
  }, [_c('span', [_vm._v("Elevation 10")])])], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Title Text")]), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref43) {
        var on = _ref43.on,
          attrs = _ref43.attrs;
        return [_c('v-btn', _vm._g(_vm._b({}, 'v-btn', attrs, false), on), [_vm._v("Title Text")])];
      }
    }])
  }, [_c('div', {
    staticClass: "v-tooltip__tit"
  }, [_vm._v("제목을입력해주세요")]), _c('p', [_vm._v("동해물과 백두산이 마르고 닳도록 하느님이 보우하사 우리나라 만세 무궁화 삼천리 화려 강산 대한 사람 대한으로 길이 보전하세")])])], 1)], 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("tooltip-{parents}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-tooltip-opacity-{}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-tooltip-outlined-{}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-tooltip-elevation-{}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("content-class=\"v-tooltip--lg\"")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("activator=\"\"")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("content-class=\"rounded-{rounded}\"")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("content-class=\"{color}--text\"")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("content-class=\"{color}\"")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("content-class=\"v-tooltip--outlined {color}\"")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("elevation-{elevation}")])]);

}]

export { render, staticRenderFns }