<template>
    <div>
        <!-- Spacing -->
        <h1>Spacing</h1>
        <v-row>
            <v-col cols="12" md="auto">
                <h3>{property}</h3>
                <p>
                    <b>m</b> - applies margin<br />
                    <b>p</b> - applies padding
                </p>
            </v-col>
            <v-col cols="12" md="auto">
                <h3>{direction}</h3>
                <p>
                    <b>a</b> - the property in all directions<br />
                    <b>x</b> - both *-left and *-right<br />
                    <b>y</b> - both *-top and *-bottom<br />
                    <b>t</b> - margin-top and padding-top<br />
                    <b>b</b> - margin-bottom and padding-bottom<br />
                    <b>l</b> - margin-left and padding-left<br />
                    <b>r</b> - margin-right and padding-right
                </p>
            </v-col>
            <v-col cols="12" md="auto">
                <h3>{size}</h3>
                <p>0, auto, 1px ~ 300px(px), n1px ~ n300px(-1px ~ -300px)</p>
            </v-col>
            <v-col cols="12">
                <p><u>{property}{direction}-{breakpoint}-{size}</u></p>
                <p>예시) pt-12px, pa-md-16px, ma-sm-10px, mt-m10px</p>
            </v-col>
        </v-row>
        <!-- //Spacing -->

        <v-divider class="my-30px" />

        <!-- Sizing -->
        <h1>Sizing</h1>
        <v-row>
            <v-col cols="12" md="auto">
                <h3>{abbrev}</h3>
                <p>
                    <b>w</b> - width<br />
                    <b>mw</b> - max-width<br />
                    <b>min-w</b> - min-width<br />
                    <b>h</b> - height<br />
                    <b>mh</b> - max-height<br />
                    <b>min-h</b> - min-height<br />
                    <b>vw</b> - width<br />
                    <b>vh</b> - height<br />
                    <b>min-vw</b> - min-width<br />
                    <b>min-vh</b> - min-height<br />
                </p>
            </v-col>
            <v-col cols="12" md="auto">
                <h3>{size}</h3>
                <p>
                    % - <b>1 ~ 100</b>(10%씩 늘어납니다)<br />
                    vw, vh - <b>100</b>만 있습니다<br />
                    px - <b>10px ~ 300px</b>(10px씩 늘어납니다), auto
                </p>
            </v-col>
            <v-col cols="12">
                <p><u>{abbrev}-{breakpoint}-{size}</u></p>
                <p>예시) w-10px, mh-md-100px, w-100, min-vw-100, vh-100</p>
            </v-col>
        </v-row>
        <!-- //Sizing -->

        <v-divider class="my-30px" />

        <!-- Grid -->
        <h1>Grid</h1>
        <!-- S: Size -->
        <h2>Size</h2>
        <h3>{size} : xxs, xs, sm, (기본), lg, xl, xxl</h3>
        <p><u>row--{size}</u></p>
        <p>예시) row--sm, row--lg</p>
        <!-- E: Size -->
        <br />

        <!-- S: Row columns -->
        <h2>Row columns</h2>
        <h3>{i} : auto, 1 ~ 12</h3>
        <p><u>row-cols-{breakpoint}-{i}</u></p>
        <p>예시) row-cols-auto, row-cols-sm-5</p>
        <!-- E: Row columns -->
        <!-- // Grid -->

        <v-divider class="my-30px" />

        <!-- Text -->
        <h1>Text</h1>
        <!-- S: Font Size -->
        <h2>Size</h2>
        <h3>{size} : 10 ~ 120</h3>
        <p><u>font-size-{breakpoint}-{size}</u></p>
        <p>예시) font-size-14, font-size-md-16</p>
        <!-- E: Font Size -->
        <br />

        <!-- S: Font Weight -->
        <h2>Weight</h2>
        <h3>
            {weight} :
            <span class="font-weight-thin">thin &nbsp; </span>
            <span class="font-weight-extralight">extralight &nbsp; </span>
            <span class="font-weight-light">light &nbsp; </span>
            <span class="font-weight-regular">regular &nbsp; </span>
            <span class="font-weight-medium">medium &nbsp; </span>
            <span class="font-weight-semibold">semibold &nbsp; </span>
            <span class="font-weight-bold">bold &nbsp; </span>
            <span class="font-weight-extrabold">extrabold &nbsp; </span>
            <span class="font-weight-black">black &nbsp; </span>
        </h3>
        <p><u>font-weight-{weight}</u></p>
        <p>예시) font-weight-light, font-weight-bold</p>
        <!-- E: Font Weight -->
        <br />
        <!-- S: Word break -->
        <h2>Word break</h2>
        <h3>{word-break} : normal, words, all, keep</h3>
        <p><u>break-{word-break}</u></p>
        <p>예시) break-normal, break-all, break-keep</p>
        <!-- E: Word break -->
        <br />

        <!-- S: Line height -->
        <h2>Line height</h2>
        <h3>{height} : 0 ~ 2</h3>
        <p><u>line-height-{breakpoint}-{height}</u></p>
        <p>예시) line-height-0, line-height-1, line-height-16, line-height-2</p>
        <!-- E: Line height -->
        <br />

        <!-- S: Letter spacing -->
        <h2>Letter spacing</h2>
        <h3>{spacing} : n05 ~ 1</h3>
        <p><u>letter-spacing-{spacing}</u></p>
        <p>예시) letter-spacing-n05, letter-spacing-0, letter-spacing-04, letter-spacing-1</p>
        <!-- E: Letter spacing -->
        <br />

        <!-- S: 말줄임 -->
        <h2>말줄임</h2>
        <v-row>
            <v-col cols="12" md="6">
                <h3>한줄</h3>
                <p><u>text-truncate</u></p>
                <p class="text-truncate">동해물과 백두산이 마르고 닳도록 하느님이 보우하사 우리나라 만세 무궁화 삼천리 화려 강산 대한 사람 대한으로 길이 보전하세</p>
            </v-col>
            <v-col cols="12" md="6">
                <h3>여러줄 / {line} : 2 ~ 10</h3>
                <p><u>text-truncate-{breakpoint}-{line}</u></p>
                <p class="text-truncate-3">
                    동해물과 백두산이 마르고 닳도록 하느님이 보우하사 우리나라 만세 무궁화 삼천리 화려 강산 대한 사람 대한으로 길이 보전하세 남산 위에 저 소나무 철갑을 두른 듯 바람 서리 불변함은 우리
                    기상일세 무궁화 삼천리 화려 강산 대한 사람 대한으로 길이 보전하세 가을 하늘 공활한데 높고 구름 없이 밝은 달은 우리 가슴 일편단심일세 무궁화 삼천리 화려 강산 대한 사람 대한으로 길이
                    보전하세 이 기상과 이 맘으로 충성을 다하여 괴로우나 즐거우나 나라 사랑하세 무궁화 삼천리 화려 강산 대한 사람 대한으로 길이 보전하세
                </p>
            </v-col>
        </v-row>
        <!-- E: 말줄임 -->
        <!-- // Text -->

        <v-divider class="my-30px" />

        <!-- Border -->
        <h1>Border</h1>
        <!-- S: 방향 -->
        <h2>방향</h2>
        <v-row>
            <v-col cols="12" md="auto">
                <h3>{direction}</h3>
                <p>
                    <b>t</b> - border-top<br />
                    <b>b</b> - border-bottom<br />
                    <b>l</b> - border-left<br />
                    <b>r</b> - border-right<br />
                    <b>x</b> - both *-left and *-right<br />
                    <b>y</b> - both *-top and *-bottom<br />
                </p>
            </v-col>
            <v-col cols="12">
                <p><u>border-{breakpoint}-{direction}</u></p>
                <p>예시) border, border-sm-y, border-l</p>
            </v-col>
        </v-row>
        <!-- E: 방향 -->
        <br />

        <!-- S: Width -->
        <h2>Width</h2>
        <h3>{width} : 1 ~ 10</h3>
        <p><u>border-{breakpoint}-{width}</u></p>
        <p>예시) border-0, border-md-2, border-8</p>
        <!-- E: Width -->
        <br />

        <!-- S: Color -->
        <h2>Color</h2>
        <p><u>border-{light|dark}</u></p>
        <!-- E: Color -->
        <!-- // Border -->

        <v-divider class="my-30px" />

        <!-- Layout -->
        <h1>Layout</h1>
        <!-- S: Clear -->
        <h2>Clear</h2>
        <h3>{clear} : both, fix</h3>
        <p><u>clear-{clear}</u></p>
        <p>예시) clear-both, clear-fix</p>
        <!-- E: Clear -->
        <br />

        <!-- S: z-index -->
        <h2>z-index</h2>
        <h3>{index} : auto, 0, 1, 2, 10~50, n1, n2 n10~n50(10씩 증가)</h3>
        <p><u>z-{breakpoint}-{z-index}</u></p>
        <p>예시) z-auto, z-n30, z-md-0 z-10</p>
        <!-- E: z-index -->
        <!-- // Layout -->

        <v-divider class="my-30px" />

        <!-- Effects -->
        <h1>Effects</h1>
        <!-- S: Opacity -->
        <h2>Opacity</h2>
        <h3>{opacity} : 0, 5, 10 ~ 100(10씩 증가)</h3>
        <p><u>opacity-{opacity}</u></p>
        <p>예시) opacity-0, opacity-5, opacity-10, opacity-20</p>
        <!-- E: Opacity -->
        <br />

        <!-- S: Elevation -->
        <h2>Elevation</h2>
        <h3>{elevation} : 0 ~ 24</h3>
        <p><u>elevation-{elevation}</u></p>
        <p>예시) elevation-0, elevation-5, elevation-10, elevation-20</p>
        <!-- E: Elevation -->
        <!-- // Effects -->

        <v-divider class="my-30px" />

        <!-- Interactivity -->
        <h1>Interactivity</h1>
        <!-- S: Cursor -->
        <h2>Cursor</h2>
        <v-row align="center">
            <v-col cols="auto">
                <h3>{cursor} :</h3>
            </v-col>
            <v-col cols="auto"><span class="cursor-auto">auto</span></v-col>
            <v-col cols="auto"><span class="cursor-none">none</span></v-col>
            <v-col cols="auto"><span class="cursor-default">default</span></v-col>
            <v-col cols="auto"><span class="cursor-pointer">pointer</span></v-col>
            <v-col cols="auto"><span class="cursor-wait">wait</span></v-col>
            <v-col cols="auto"><span class="cursor-text">text</span></v-col>
            <v-col cols="auto"><span class="cursor-move">move</span></v-col>
            <v-col cols="auto"><span class="cursor-no-drop">no-drop</span></v-col>
            <v-col cols="auto"><span class="cursor-zoom-in">zoom-in</span></v-col>
            <v-col cols="auto"><span class="cursor-zoom-out">zoom-out</span></v-col>
            <v-col cols="12">
                <p><u>cursor-{cursor}</u></p>
                <p>예시) cursor-auto, cursor-default, cursor-pointer</p>
            </v-col>
        </v-row>
        <!-- E: Cursor -->
        <br />

        <!-- S: Pointer events -->
        <h2>Pointer events</h2>
        <h3>{pointer-events} : auto, none</h3>
        <p><u>pointer-events-{pointer-events}</u></p>
        <p>예시) pointer-events-auto, pointer-events-none</p>
        <!-- E: Pointer events -->
        <br />

        <!-- S: Resize -->
        <h2>Resize</h2>
        <h3>{resize} : 공백, none, x, y</h3>
        <p><u>resize-{resize}</u></p>
        <p>예시) resize, resize-none, resize-x, resize-y</p>
        <!-- E: Resize -->
        <!-- // Interactivity -->
    </div>
</template>

<script>
import GuideApp from "./guide-app.vue";

export default {
    props: {},
    components: {
        GuideApp,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
