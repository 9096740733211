<template>
    <div>
        <!-- Parents -->
        <h1>Parents</h1>
        <p><u>txt-{parents}</u></p>
        <br />
        <v-row>
            <v-col cols="12" md="6">
                <txt-primary>내용을입력해주세요</txt-primary>
            </v-col>
            <v-col cols="12" md="6">
                <txt-secondary>내용을입력해주세요</txt-secondary>
            </v-col>
            <v-col cols="12" md="6">
                <txt-tertiary>
                    <p>내용을입력해주세요</p>
                    <p>내용을입력해주세요</p>
                    <p>내용을입력해주세요</p>
                </txt-tertiary>
            </v-col>
            <v-col cols="12" md="6">
                <txt-quarternary>
                    <p>내용을입력해주세요</p>
                    <p>내용을입력해주세요</p>
                    <p>내용을입력해주세요</p>
                </txt-quarternary>
            </v-col>
        </v-row>
        <!-- // Parents -->

        <v-divider class="my-30px" />

        <!-- Styles -->
        <h1>Styles</h1>
        <!-- S: Default -->
        <h2>Default</h2>
        <v-row>
            <v-col cols="6" md="4">
                <p><u>u-txt-default</u></p>
                <br />
                <u-txt-default>동해물과 백두산이 마르고 닳도록 하느님이 보우하사 우리나라 만세 무궁화 삼천리 화려 강산 대한 사람 대한으로 길이 보전하세</u-txt-default>
            </v-col>
        </v-row>
        <!-- E: Default -->
        <br />

        <!-- S: Medium -->
        <h2>Medium</h2>
        <v-row>
            <v-col cols="6" md="4">
                <p><u>u-txt-medium</u></p>
                <br />
                <u-txt-medium>동해물과 백두산이 마르고 닳도록 하느님이 보우하사 우리나라 만세 무궁화 삼천리 화려 강산 대한 사람 대한으로 길이 보전하세</u-txt-medium>
            </v-col>
        </v-row>
        <!-- E: Medium -->
        <!-- // Styles -->

        <v-divider class="my-30px" />

        <!-- Size -->
        <h1>Size</h1>
        <h3>{size} : xs, sm, (기본), lg, xl</h3>
        <p><u>txt--{size}</u></p>
        <br />
        <txt class="txt--xs">동해물과 백두산이 마르고 닳도록 하느님이 보우하사 우리나라 만세 무궁화 삼천리 화려 강산 대한 사람 대한으로 길이 보전하세</txt>
        <txt class="txt--sm">동해물과 백두산이 마르고 닳도록 하느님이 보우하사 우리나라 만세 무궁화 삼천리 화려 강산 대한 사람 대한으로 길이 보전하세</txt>
        <txt>동해물과 백두산이 마르고 닳도록 하느님이 보우하사 우리나라 만세 무궁화 삼천리 화려 강산 대한 사람 대한으로 길이 보전하세</txt>
        <txt class="txt--lg">동해물과 백두산이 마르고 닳도록 하느님이 보우하사 우리나라 만세 무궁화 삼천리 화려 강산 대한 사람 대한으로 길이 보전하세</txt>
        <txt class="txt--xl">동해물과 백두산이 마르고 닳도록 하느님이 보우하사 우리나라 만세 무궁화 삼천리 화려 강산 대한 사람 대한으로 길이 보전하세</txt>
        <!-- // Size -->

        <v-divider class="my-30px" />

        <!-- Color -->
        <h1>Color</h1>
        <h3>{color} : (기본), light, dark</h3>
        <p><u>txt--{color}</u></p>
        <br />
        <txt>동해물과 백두산이 마르고 닳도록 하느님이 보우하사 우리나라 만세 무궁화 삼천리 화려 강산 대한 사람 대한으로 길이 보전하세</txt>
        <txt class="txt--light">동해물과 백두산이 마르고 닳도록 하느님이 보우하사 우리나라 만세 무궁화 삼천리 화려 강산 대한 사람 대한으로 길이 보전하세</txt>
        <txt class="txt--dark">동해물과 백두산이 마르고 닳도록 하느님이 보우하사 우리나라 만세 무궁화 삼천리 화려 강산 대한 사람 대한으로 길이 보전하세</txt>
        <!-- // Color -->

        <v-divider class="my-30px" />

        <!-- 단락 -->
        <h1>단락</h1>
        <v-row>
            <v-col cols="12" md="6">
                <h2>구분이 없는 경우</h2>
                <br />
                <txt> 동해물과 백두산이 마르고 닳도록 하느님이 보우하사 우리나라 만세 무궁화 삼천리 화려 강산 대한 사람 대한으로 길이 보전하세 남산 위에 저 소나무 철갑을 두른 듯 바람 서리 불변함은 우리기상일세 무궁화 삼천리 화려 강산 대한 사람 대한으로 길이 보전하세 가을 하늘 공활한데 높고 구름 없이 밝은 달은 우리 가슴 일편단심일세 무궁화 삼천리 화려 강산 대한 사람 대한으로 길이 보전하세 이 기상과 이 맘으로 충성을 다하여 괴로우나 즐거우나 나라 사랑하세 무궁화 삼천리 화려 강산 대한 사람 대한으로 길이 보전하세 </txt>
            </v-col>
            <v-col cols="12" md="6">
                <h2>구분이 있는 경우</h2>
                <br />
                <txt>
                    <p>동해물과 백두산이 마르고 닳도록 하느님이 보우하사 우리나라 만세 무궁화 삼천리 화려 강산 대한 사람 대한으로 길이 보전하세</p>
                    <p>남산 위에 저 소나무 철갑을 두른 듯 바람 서리 불변함은 우리 기상일세 무궁화 삼천리 화려 강산 대한 사람 대한으로 길이 보전하세</p>
                    <p>가을 하늘 공활한데 높고 구름 없이 밝은 달은 우리 가슴 일편단심일세 무궁화 삼천리 화려 강산 대한 사람 대한으로 길이 보전하세</p>
                </txt>
            </v-col>
        </v-row>
        <!-- // 단락 -->
        <!-- // Text -->
    </div>
</template>

<script>
import GuideApp from "./guide-app.vue";

import TxtPrimary from "@/components/publish/parents/typography/txt-primary.vue";
import TxtSecondary from "@/components/publish/parents/typography/txt-secondary.vue";
import TxtTertiary from "@/components/publish/parents/typography/txt-tertiary.vue";
import TxtQuarternary from "@/components/publish/parents/typography/txt-quarternary.vue";

import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";

import Txt from "@/components/publish/styles/typography/txt.vue";

export default {
    props: {},
    components: {
        GuideApp,

        TxtPrimary,
        TxtSecondary,
        TxtTertiary,
        TxtQuarternary,

        UTxtDefault,
        UTxtMedium,

        Txt,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
