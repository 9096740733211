var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', _vm._b({
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, 'v-dialog', _vm.$attrs, false), [_c('v-card', {
    staticClass: "rounded-0"
  }, [_c('v-toolbar', {
    staticClass: "v-toolbar--border"
  }, [_c('v-toolbar-title', [_vm._t("tit")], 2), _c('v-spacer'), _c('v-btn', {
    staticClass: "v-size--xx-large rounded-0",
    attrs: {
      "icon": ""
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.close.apply(null, arguments);
      }
    }
  }, [_c('u-icon', [_vm._v("close")])], 1)], 1), _c('div', {
    staticClass: "pa-16px"
  }, [_vm._t("default")], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }