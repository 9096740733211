<template>
    <div v-bind="$attrs" class="header-search">
        <component :is="InputTag" v-model="searchKeyword" v-if="type != 'button'" :placeholder="Placeholder" class="header-search__input" @keydown.enter="search"></component>
        <v-btn icon variant="text" class="header-search__btn v-btn--none-active-bg" @click="search"><icon-search /></v-btn>
    </div>
</template>

<script>
import UTextFieldOutlinedPill from "@/components/publish/styles/forms/u-text-field-outlined-pill.vue";
import UTextFieldOutlinedTile from "@/components/publish/styles/forms/u-text-field-outlined-tile.vue";
import UTextFieldUnderlined from "@/components/publish/styles/forms/u-text-field-underlined.vue";

import IconSearch from "@/components/publish/styles/icons/icon-search.vue";

export default {
    props: {
        keyword: { type: String, default: null },
        type: String,
        InputTag: String,
        Placeholder: { type: String, default: "검색어를 입력하세요" },
    },
    components: {
        UTextFieldOutlinedPill,
        UTextFieldOutlinedTile,
        UTextFieldUnderlined,

        IconSearch,
    },
    data: () => ({
        searchKeyword: null,
    }),
    mounted() {
        this.init();
    },
    watch: {
        "$route.query.searchKeyword"() {
            this.init();
        },
    },
    methods: {
        init() {
            this.searchKeyword = this.$route.query.searchKeyword || null;
        },
        search() {
            let path = "/search";
            path = this.searchKeyword ? `${path}/${this.searchKeyword}` : path;
            if (this.$route.path != path) this.$router.push(path);
        },
    },
};
</script>

<style lang="scss" scoped>
.header-search {
    --header-search-color: var(--v-grey-darken4);
    --header-search-color-light: 255, 255, 255;

    ::v-deep {
        position: relative;
        .v-text-field {
            &.v-input {
                & > .v-input__control > .v-input__slot,
                &--density-comfortable > .v-input__control > .v-input__slot {
                    padding-right: var(--input-height-comfortable) !important;
                }
                &--density-compact > .v-input__control > .v-input__slot {
                    padding-right: var(--input-height-compact) !important;
                }
                &--density-default > .v-input__control > .v-input__slot {
                    padding-right: var(--input-height) !important;
                }
            }
        }
    }
    &__input {
        max-width: 200px;
        & + .header-search__btn {
            position: absolute;
            top: 50%;
            right: 0;
            -webkit-transform: translate(0, -50%);
            -ms-transform: translate(0, -50%);
            transform: translate(0, -50%);
            width: var(--input-height-comfortable) !important;
            height: 100% !important;
        }
    }
    &__btn,
    &__btn.theme--light.v-btn.v-btn--icon,
    ::v-deep .v-text-field:not(.v-input--is-focused):not(.v-input--has-state).theme--light > .v-input__control > .v-input__slot fieldset {
        color: var(--header-search-color);
    }
    ::v-deep .v-text-field.theme--light > .v-input__control > .v-input__slot:before {
        border-color: var(--header-search-color);
    }

    &--light {
        &,
        .header-search__btn,
        .header-search__btn.theme--light.v-btn.v-btn--icon,
        ::v-deep .v-text-field.theme--light > .v-input__control > .v-input__slot fieldset,
        ::v-deep .v-input.theme--light,
        ::v-deep .v-input.theme--light input,
        ::v-deep .v-input.theme--light textarea {
            color: rgb(var(--header-search-color-light)) !important;
        }
        ::v-deep {
            .v-text-field.theme--light > .v-input__control > .v-input__slot {
                &::before,
                &::after {
                    border-color: rgb(var(--header-search-color-light));
                }
            }

            input,
            textarea {
                &::placeholder {
                    color: rgba(var(--header-search-color-light), 0.7) !important;
                    opacity: 1;
                }
                &:-ms-input-placeholder {
                    color: rgba(var(--header-search-color-light), 0.7) !important;
                }
                &::-ms-input-placeholder {
                    color: rgba(var(--header-search-color-light), 0.7) !important;
                }
            }
        }
    }
}
</style>
