var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-btn', _vm._b({
    staticClass: "v-btn--arrow",
    attrs: {
      "icon": "",
      "outlined": "",
      "rounded": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('click');
      }
    },
    scopedSlots: _vm._u([_vm._l(_vm.$slots, function (_, slotName) {
      return {
        key: slotName,
        fn: function () {
          return [_vm._t(slotName)];
        },
        proxy: true
      };
    })], null, true)
  }, 'v-btn', Object.assign({
    color: _vm.color
  }, _vm.$attrs), false));

}
var staticRenderFns = []

export { render, staticRenderFns }