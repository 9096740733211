<template>
    <u-dialog-flat-rounded v-model="shows" v-bind="$attrs" transition="dialog-top-transition">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </u-dialog-flat-rounded>
</template>

<script>
import UDialogFlatRounded from "@/components/publish/styles/dialogs/u-dialog-flat-rounded.vue";

export default {
    props: {},
    components: {
        UDialogFlatRounded,
    },
    data() {
        return {
            shows: false,
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
