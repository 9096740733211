var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h1', [_vm._v("Parents")]), _vm._m(0), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('pagination-primary', {
    attrs: {
      "length": 4
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('pagination-secondary', {
    attrs: {
      "length": 4
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('pagination-tertiary', {
    attrs: {
      "length": 4
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('pagination-quarternary', {
    attrs: {
      "length": 4
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Styles")]), _c('h2', [_vm._v("Text")]), _vm._m(1), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4"
    }
  }, [_c('u-pagination-text-tile', {
    attrs: {
      "length": 4
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4"
    }
  }, [_c('u-pagination-text-rounded', {
    attrs: {
      "length": 4
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4"
    }
  }, [_c('u-pagination-text-circle', {
    attrs: {
      "length": 4
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)], 1), _c('br'), _c('h2', [_vm._v("Elevated")]), _vm._m(2), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4"
    }
  }, [_c('u-pagination-elevated-tile', {
    attrs: {
      "length": 4
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4"
    }
  }, [_c('u-pagination-elevated-rounded', {
    attrs: {
      "length": 4
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4"
    }
  }, [_c('u-pagination-elevated-circle', {
    attrs: {
      "length": 4
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)], 1), _c('br'), _c('h2', [_vm._v("Outlined")]), _vm._m(3), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4"
    }
  }, [_c('u-pagination-outlined-tile', {
    attrs: {
      "length": 4
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4"
    }
  }, [_c('u-pagination-outlined-rounded', {
    attrs: {
      "length": 4
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4"
    }
  }, [_c('u-pagination-outlined-circle', {
    attrs: {
      "length": 4
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)], 1), _c('br'), _c('h2', [_vm._v("Filled")]), _vm._m(4), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4"
    }
  }, [_c('u-pagination-filled-tile', {
    attrs: {
      "length": 4
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4"
    }
  }, [_c('u-pagination-filled-rounded', {
    attrs: {
      "length": 4
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4"
    }
  }, [_c('u-pagination-filled-circle', {
    attrs: {
      "length": 4
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)], 1), _c('br'), _c('h2', [_vm._v("Text Custom")]), _vm._m(5), _vm._m(6), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4"
    }
  }, [_c('u-pagination-text-color', {
    attrs: {
      "length": 4
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4"
    }
  }, [_c('u-pagination-text-underline', {
    attrs: {
      "length": 4
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Size")]), _c('h3', [_vm._v("{size} : x-small, small, (기본), large, x-large")]), _vm._m(7), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4"
    }
  }, [_c('v-pagination', {
    staticClass: "v-size--x-small",
    attrs: {
      "length": 4
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4"
    }
  }, [_c('v-pagination', {
    staticClass: "v-size--small",
    attrs: {
      "length": 4
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4"
    }
  }, [_c('v-pagination', {
    attrs: {
      "length": 4
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4"
    }
  }, [_c('v-pagination', {
    staticClass: "v-size--large",
    attrs: {
      "length": 4
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4"
    }
  }, [_c('v-pagination', {
    staticClass: "v-size--x-large",
    attrs: {
      "length": 4
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("State")]), _c('h2', [_vm._v("Disabled")]), _vm._m(8), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4"
    }
  }, [_c('v-pagination', {
    attrs: {
      "length": 4,
      "disabled": ""
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Rounded")]), _c('h3', [_vm._v("{rounded} : (기본), circle, 0")]), _vm._m(9), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4"
    }
  }, [_c('v-pagination', {
    attrs: {
      "length": 4
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4"
    }
  }, [_c('v-pagination', {
    staticClass: "rounded-circle",
    attrs: {
      "length": 4
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4"
    }
  }, [_c('v-pagination', {
    staticClass: "rounded-0",
    attrs: {
      "length": 4
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Variant")]), _c('h3', [_vm._v("{variant} : (기본-text), elevated, outlined, filled, text-color, text-underline")]), _vm._m(10), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4"
    }
  }, [_c('v-pagination', {
    attrs: {
      "length": 4
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4"
    }
  }, [_c('v-pagination', {
    staticClass: "v-pagination--elevated",
    attrs: {
      "length": 4
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4"
    }
  }, [_c('v-pagination', {
    staticClass: "v-pagination--outlined",
    attrs: {
      "length": 4
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4"
    }
  }, [_c('v-pagination', {
    staticClass: "v-pagination--filled",
    attrs: {
      "length": 4
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4"
    }
  }, [_c('v-pagination', {
    staticClass: "v-pagination--text-color",
    attrs: {
      "length": 4
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4"
    }
  }, [_c('v-pagination', {
    staticClass: "v-pagination--text-underline",
    attrs: {
      "length": 4
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("pagination-{parents}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-pagination-text-{}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-pagination-elevated-{}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-pagination-outlined-{}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-pagination-filled-{}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-pagination-text-color")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-pagination-text-underline")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("class=\"v-size--{size}\"")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("disabled")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("class=\"rounded-{rounded}\"")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("class=\"v-pagination--{variant}\"")])]);

}]

export { render, staticRenderFns }