<template>
    <div>
        <!-- Parents -->
        <h1>Parents</h1>
        <p><u>select-{parents}</u></p>
        <br />
        <v-row align="center">
            <v-col cols="12">
                Test : {{ selected }}
            </v-col>
            <v-col cols="4" md="2">
                <select-primary v-model="selected" :items="items" label="Select Primary" class="v-input--density-compact" />
            </v-col>
            <v-col cols="4" md="2">
                <select-secondary v-model="selected" :items="items" placeholder="Select Secondary" class="v-input--density-default" />
            </v-col>
            <v-col cols="4" md="2">
                <select-tertiary v-model="selected" :items="items" label="Select Tertiary" />
            </v-col>
            <v-col cols="4" md="2">
                <select-quarternary v-model="selected" :items="items" placeholder="Select Quarternary" />
            </v-col>
        </v-row>
        <!-- // Parents -->

        <v-divider class="my-30px" />

        <!-- Styles -->
        <h1>Styles</h1>
        <!-- S: Underlined -->
        <h2>Underlined</h2>
        <p><u>u-select-underlined</u></p>
        <br />
        <v-row align="center">
            <v-col cols="4" md="2">
                <u-select-underlined :items="items" label="Underlined Label"></u-select-underlined>
            </v-col>
            <v-col cols="4" md="2">
                <u-select-underlined :items="items" placeholder="Underlined Placeholder"></u-select-underlined>
            </v-col>
        </v-row>
        <!-- E: Underlined -->
        <br />

        <!-- S: Outlined -->
        <h2>Outlined</h2>
        <p><u>u-select-outlined-{}</u></p>
        <br />
        <v-row align="center">
            <v-col cols="4" md="2">
                <u-select-outlined-tile :items="items" label="Outlined Tile Label"></u-select-outlined-tile>
            </v-col>
            <v-col cols="4" md="2">
                <u-select-outlined-rounded :items="items" label="Outlined Rounded Label"></u-select-outlined-rounded>
            </v-col>
            <v-col cols="4" md="2">
                <u-select-outlined-pill :items="items" label="Outlined Pill Label"></u-select-outlined-pill>
            </v-col>
            <v-col cols="4" md="2">
                <u-select-outlined-tile :items="items" label="Outlined Tile Label Inner" class="v-text-field--label-inner"></u-select-outlined-tile>
            </v-col>
            <v-col cols="4" md="2">
                <u-select-outlined-rounded :items="items" label="Outlined Rounded Label Inner" class="v-text-field--label-inner"></u-select-outlined-rounded>
            </v-col>
            <v-col cols="4" md="2">
                <u-select-outlined-pill :items="items" label="Outlined Pill Label Inner" class="v-text-field--label-inner"></u-select-outlined-pill>
            </v-col>
            <v-col cols="4" md="2">
                <u-select-outlined-tile :items="items" placeholder="Outlined Tile Placeholder"></u-select-outlined-tile>
            </v-col>
            <v-col cols="4" md="2">
                <u-select-outlined-rounded :items="items" placeholder="Outlined Rounded Placeholder"></u-select-outlined-rounded>
            </v-col>
            <v-col cols="4" md="2">
                <u-select-outlined-pill :items="items" placeholder="Outlined Pill Placeholder"></u-select-outlined-pill>
            </v-col>
        </v-row>
        <!-- E: Outlined -->
        <br />

        <!-- S: Filled -->
        <h2>Filled</h2>
        <p><u>u-select-filled-{}</u></p>
        <br />
        <v-row align="center">
            <v-col cols="4" md="2">
                <u-select-filled-tile :items="items" label="Filled Tile Label"></u-select-filled-tile>
            </v-col>
            <v-col cols="4" md="2">
                <u-select-filled-rounded :items="items" label="Filled Rounded Label"></u-select-filled-rounded>
            </v-col>
            <v-col cols="4" md="2">
                <u-select-filled-pill :items="items" label="Filled Pill Label"></u-select-filled-pill>
            </v-col>
            <v-col cols="4" md="2">
                <u-select-filled-tile :items="items" placeholder="Filled Tile Placeholder"></u-select-filled-tile>
            </v-col>
            <v-col cols="4" md="2">
                <u-select-filled-rounded :items="items" placeholder="Filled Rounded Placeholder"></u-select-filled-rounded>
            </v-col>
            <v-col cols="4" md="2">
                <u-select-filled-pill :items="items" placeholder="Filled Pill Placeholder"></u-select-filled-pill>
            </v-col>
        </v-row>
        <!-- E: Filled -->
        <br />

        <!-- S: Solo -->
        <h2>Solo</h2>
        <p><u>u-select-solo-{}</u></p>
        <br />
        <v-row align="center">
            <v-col cols="4" md="2">
                <u-select-solo-tile :items="items" placeholder="Solo Tile Placeholder"></u-select-solo-tile>
            </v-col>
            <v-col cols="4" md="2">
                <u-select-solo-rounded :items="items" placeholder="Solo Rounded Placeholder"></u-select-solo-rounded>
            </v-col>
            <v-col cols="4" md="2">
                <u-select-solo-pill :items="items" placeholder="Solo Pill Placeholder"></u-select-solo-pill>
            </v-col>
        </v-row>
        <!-- E: Solo -->
        <!-- // Styles -->

        <v-divider class="my-30px" />

        <!-- Density -->
        <h1>Density</h1>
        <h3>{density} : compact, (기본-comfortable), default</h3>
        <p><u>class="v-input--density-{density}"</u></p>
        <br />
        <!-- S: Underlined -->
        <h2>Underlined</h2>
        <v-row align="center">
            <v-col cols="4" md="2">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" :items="items" label="Label" class="v-input--density-compact"></v-select>
            </v-col>
            <v-col cols="4" md="2">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" :items="items" label="Label"></v-select>
            </v-col>
            <v-col cols="4" md="2">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" :items="items" label="Label" class="v-input--density-default"></v-select>
            </v-col>
            <v-col cols="4" md="2">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" :items="items" placeholder="Placeholder" class="v-input--density-compact"></v-select>
            </v-col>
            <v-col cols="4" md="2">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" :items="items" placeholder="Placeholder"></v-select>
            </v-col>
            <v-col cols="4" md="2">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" :items="items" placeholder="Placeholder" class="v-input--density-default"></v-select>
            </v-col>
        </v-row>
        <!-- E: Underlined -->
        <br />

        <!-- S: Outlined -->
        <h2>Outlined</h2>
        <v-row align="center">
            <v-col cols="4" md="2">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" :items="items" outlined label="Label" class="v-input--density-compact"></v-select>
            </v-col>
            <v-col cols="4" md="2">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" :items="items" outlined label="Label"></v-select>
            </v-col>
            <v-col cols="4" md="2">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" :items="items" outlined label="Label" class="v-input--density-default"></v-select>
            </v-col>
            <v-col cols="4" md="2">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" :items="items" outlined placeholder="Placeholder" class="v-input--density-compact"></v-select>
            </v-col>
            <v-col cols="4" md="2">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" :items="items" outlined placeholder="Placeholder"></v-select>
            </v-col>
            <v-col cols="4" md="2">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" :items="items" outlined placeholder="Placeholder" class="v-input--density-default"></v-select>
            </v-col>
        </v-row>
        <!-- E: Outlined -->
        <br />

        <!-- S: Label Inner -->
        <h2>Label Inner</h2>
        <v-row align="center">
            <v-col cols="4" md="2">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" :items="items" outlined label="Label" class="v-input--density-compact v-text-field--label-inner"></v-select>
            </v-col>
            <v-col cols="4" md="2">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" :items="items" outlined label="Label" class="v-text-field--label-inner"></v-select>
            </v-col>
            <v-col cols="4" md="2">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" :items="items" outlined label="Label" class="v-input--density-default v-text-field--label-inner"></v-select>
            </v-col>
            <v-col cols="4" md="2">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" :items="items" outlined placeholder="Placeholder" class="v-input--density-compact v-text-field--label-inner"></v-select>
            </v-col>
            <v-col cols="4" md="2">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" :items="items" outlined placeholder="Placeholder" class="v-text-field--label-inner"></v-select>
            </v-col>
            <v-col cols="4" md="2">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" :items="items" outlined placeholder="Placeholder" class="v-input--density-default v-text-field--label-inner"></v-select>
            </v-col>
        </v-row>
        <!-- E: Label Inner -->
        <br />

        <!-- S: Filled -->
        <h2>Filled</h2>
        <v-row align="center">
            <v-col cols="4" md="2">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" :items="items" filled label="Label" class="v-input--density-compact"></v-select>
            </v-col>
            <v-col cols="4" md="2">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" :items="items" filled label="Label"></v-select>
            </v-col>
            <v-col cols="4" md="2">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" :items="items" filled label="Label" class="v-input--density-default"></v-select>
            </v-col>
            <v-col cols="4" md="2">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" :items="items" filled placeholder="Placeholder" class="v-input--density-compact"></v-select>
            </v-col>
            <v-col cols="4" md="2">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" :items="items" filled placeholder="Placeholder"></v-select>
            </v-col>
            <v-col cols="4" md="2">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" :items="items" filled placeholder="Placeholder" class="v-input--density-default"></v-select>
            </v-col>
        </v-row>
        <!-- E: Filled -->
        <br />

        <!-- S: Solo -->
        <h2>Solo</h2>
        <v-row align="center">
            <v-col cols="4" md="2">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" :items="items" solo placeholder="Placeholder" class="v-input--density-compact"></v-select>
            </v-col>
            <v-col cols="4" md="2">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" :items="items" solo placeholder="Placeholder"></v-select>
            </v-col>
            <v-col cols="4" md="2">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" :items="items" solo placeholder="Placeholder" class="v-input--density-default"></v-select>
            </v-col>
        </v-row>
        <!-- E: Solo -->
        <!-- // Density -->

        <v-divider class="my-30px" />

        <!-- Width -->
        <h1>Width</h1>
        <p><u>w-{breakpoint}-{size}</u></p>
        <p><u>v-input--width-fixed</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" :items="items" label="Custom Width" class="min-w-140px min-w-md-200px"></v-select>
            </v-col>
            <v-col cols="12">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" :items="items" label="default block"></v-select>
            </v-col>
            <v-col cols="auto">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" :items="items" label="Width Fixed" class="v-input--density-compact v-input--width-fixed"></v-select>
            </v-col>
            <v-col cols="auto">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" :items="items" label="Width Fixed" class="v-input--width-fixed"></v-select>
            </v-col>
            <v-col cols="auto">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" :items="items" label="Width Fixed" class="v-input--density-default v-input--width-fixed"></v-select>
            </v-col>
        </v-row>
        <!-- // Width -->

        <v-divider class="my-30px" />

        <!-- State -->
        <h1>State</h1>
        <!-- S: Disabled -->
        <h2>Disabled</h2>
        <p><u>disabled</u></p>
        <br />
        <v-row align="center">
            <v-col cols="6" md="3">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" :items="items" disabled label="Label"></v-select>
            </v-col>
            <v-col cols="6" md="3">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" :items="items" disabled placeholder="placeholder"></v-select>
            </v-col>
        </v-row>
        <!-- E: Disabled -->
        <br />

        <!-- S: Readonly -->
        <h2>Readonly</h2>
        <p><u>readonly</u></p>
        <br />
        <v-row align="center">
            <v-col cols="6" md="3">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" :items="items" readonly label="Label"></v-select>
            </v-col>
            <v-col cols="6" md="3">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" :items="items" readonly placeholder="placeholder"></v-select>
            </v-col>
        </v-row>
        <!-- E: Readonly -->
        <!-- // State -->

        <v-divider class="my-30px" />

        <!-- Variant -->
        <h1>Variant</h1>
        <h3>{variant} : (기본-underlined), outlined, label-inner(outlined), filled, solo</h3>
        <p>v-text-field--label-inner은 클래스로 입력해주세요. label-inner은 outlined속성과 같이 사용합니다.</p>
        <p>solo는 label 스타일이 없습니다.</p>
        <p><u>{variant}</u></p>
        <p><u>class="v-text-field--label-inner"</u></p>
        <br />
        <v-row align="center">
            <v-col cols="6" md="3">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" :items="items" label="Label"></v-select>
            </v-col>
            <v-col cols="6" md="3">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" :items="items" placeholder="Placeholder"></v-select>
            </v-col>
            <v-col cols="6" md="3">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" :items="items" outlined label="Label"></v-select>
            </v-col>
            <v-col cols="6" md="3">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" :items="items" outlined placeholder="Placeholder"></v-select>
            </v-col>
            <v-col cols="6" md="3">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" :items="items" outlined label="Label Inner" class="v-text-field--label-inner"></v-select>
            </v-col>
            <v-col cols="6" md="3">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" :items="items" outlined placeholder="Placeholder" class="v-text-field--label-inner"></v-select>
            </v-col>
            <v-col cols="6" md="3">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" :items="items" filled label="Label"></v-select>
            </v-col>
            <v-col cols="6" md="3">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" :items="items" filled placeholder="Placeholder"></v-select>
            </v-col>
            <v-col cols="6" md="3">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" :items="items" solo placeholder="Placeholder"></v-select>
            </v-col>
        </v-row>
        <!-- // Variant -->

        <v-divider class="my-30px" />

        <!-- Rounded -->
        <h1>Rounded</h1>
        <h3>{rounded} : (기본), pill, 0</h3>
        <p><u>class="rounded-{rounded}"</u></p>
        <br />
        <v-row align="center">
            <v-col cols="6" md="2">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" outlined :items="items" label="Default"></v-select>
            </v-col>
            <v-col cols="6" md="2">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" outlined :items="items" label="Pill" class="rounded-pill"></v-select>
            </v-col>
            <v-col cols="6" md="2">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" outlined :items="items" label="Tile" class="rounded-0"></v-select>
            </v-col>
            <v-col cols="6" md="2">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" filled :items="items" label="Default"></v-select>
            </v-col>
            <v-col cols="6" md="2">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" filled :items="items" label="Pill" class="rounded-pill"></v-select>
            </v-col>
            <v-col cols="6" md="2">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" filled :items="items" label="Tile" class="rounded-0"></v-select>
            </v-col>
            <v-col cols="6" md="2">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" outlined :items="items" label="Default Label Inner" class="v-text-field--label-inner"></v-select>
            </v-col>
            <v-col cols="6" md="2">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" outlined :items="items" label="Pill Label Inner" class="rounded-pill v-text-field--label-inner"></v-select>
            </v-col>
            <v-col cols="6" md="2">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" outlined :items="items" label="Tile Label Inner" class="rounded-0 v-text-field--label-inner"></v-select>
            </v-col>
        </v-row>
        <!-- // Rounded -->

        <v-divider class="my-30px" />

        <!-- Icon -->
        <h1>Icon</h1>
        <h3>{direction} : prepend, prepend-inner, append, append-outer, clear-icon</h3>
        <p>vue에서 렌더링 하는데 소모하는 시간이 있어 되도록이면 icon=""을 사용하지 않고 template v-slot으로 아이콘을 추가합니다.</p>
        <p>이벤트가 있는 아이콘은 template v-slot으로 사용하면 이벤트가 작동되지 않아 {direction}=""형태로 수정합니다. 이때 아이콘은 mdi아이콘만 사용할 수 있습니다.</p>
        <p><u>#{direction}</u></p>
        <p><u>{direction}=""</u></p>
        <br />
        <v-row align="center">
            <v-col cols="6" md="3">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" :items="items" outlined placeholder="Prepend Icon">
                    <template #prepend><u-icon>attach_file</u-icon></template>
                </v-select>
            </v-col>
            <v-col cols="6" md="3">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" :items="items" outlined placeholder="Prepend Inner Icon">
                    <template #prepend-inner><u-icon>attach_file</u-icon></template>
                </v-select>
            </v-col>
            <v-col cols="6" md="3">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" :items="items" outlined placeholder="Append Icon">
                    <template #append-outer><u-icon>attach_file</u-icon></template>
                </v-select>
            </v-col>
            <v-col cols="6" md="3">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" :items="items" outlined append-icon="mdi-chevron-down" placeholder="Append Inner Icon"></v-select>
            </v-col>
            <v-col cols="6" md="3">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" :items="items" outlined clearable placeholder="Prepend Icon Clearable">
                    <template #prepend><u-icon>attach_file</u-icon></template>
                </v-select>
            </v-col>
            <v-col cols="6" md="3">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" :items="items" outlined clearable placeholder="Prepend Inner Icon Clearable">
                    <template #prepend-inner><u-icon>attach_file</u-icon></template>
                </v-select>
            </v-col>
            <v-col cols="6" md="3">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" :items="items" outlined clearable placeholder="Append Icon Clearable">
                    <template #append-outer><u-icon>attach_file</u-icon></template>
                </v-select>
            </v-col>
            <v-col cols="6" md="3">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" :items="items" outlined clearable append-icon="mdi-chevron-down" placeholder="Append Inner Icon Clearable"></v-select>
            </v-col>
            <v-col cols="6" md="3">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" :items="items" outlined clearable clear-icon="mdi-delete" placeholder="Prepend Append Clearable Icon">
                    <template #prepend><u-icon>attach_file</u-icon></template>
                    <template #append-outer><u-icon>check</u-icon></template>
                </v-select>
            </v-col>
            <v-col cols="6" md="3">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" :items="items" outlined clearable append-icon="mdi-chevron-down" clear-icon="mdi-delete" placeholder="Prepend Append Clearable Icon">
                    <template #prepend-inner><u-icon>attach_file</u-icon></template>
                </v-select>
            </v-col>
        </v-row>
        <!-- // Icon -->

        <v-divider class="my-30px" />

        <!-- Multiple -->
        <h1>Multiple</h1>
        <p><u>multiple</u></p>
        <br />
        <v-row align="center">
            <v-col cols="6">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" v-model="value" :items="items" multiple label="Multiple"></v-select>
            </v-col>
        </v-row>
        <!-- // Multiple -->

        <v-divider class="my-30px" />

        <!-- Chips -->
        <h1>Chips</h1>
        <p><u>chips, small-chips</u></p>
        <br />
        <v-row align="center">
            <v-col cols="6">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" :items="items" chips multiple label="Chips Label"></v-select>
            </v-col>
            <v-col cols="6">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" :items="items" chips multiple placeholder="Chips Placeholder"></v-select>
            </v-col>
            <v-col cols="6">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" :items="items" small-chips multiple label="Small Chips Label"></v-select>
            </v-col>
            <v-col cols="6">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" :items="items" small-chips multiple placeholder="Small Chips Placeholder"></v-select>
            </v-col>
            <v-col cols="6">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" :items="items" chips multiple label="Chips Label Custom">
                    <template #selection="{ item }">
                        <v-chip x-small label color="primary">{{ item }}</v-chip>
                    </template>
                </v-select>
            </v-col>
            <v-col cols="6">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" :items="items" chips multiple placeholder="Chips Placeholder Custom">
                    <template #selection="{ item }">
                        <v-chip x-small label color="primary">{{ item.text }}</v-chip>
                    </template>
                </v-select>
            </v-col>
        </v-row>
        <!-- // Chips -->

        <v-divider class="my-30px" />

        <!-- Supporting Text -->
        <h1>Supporting Text</h1>
        <!-- S: Hint, Messages -->
        <h2>Hint, Messages</h2>
        <p><u>hint=""</u></p>
        <p><u>messages=""</u></p>
        <br />
        <v-row align="center">
            <v-col cols="6" md="3">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" :items="items" hint="Hint" placeholder="Hint"></v-select>
            </v-col>
            <v-col cols="6" md="3">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" :items="items" messages="Messages" placeholder="Messages"></v-select>
            </v-col>
        </v-row>
        <!-- E: Hint, Messages -->
        <br />

        <!-- S: Persistence -->
        <h2>Persistence</h2>
        <h3>{persistent} : hint, clear, placeholder</h3>
        <p><u>persistent-{persistent}</u></p>
        <p>persistent-hint - 항상 힌트가 보입니다.</p>
        <p>persistent-clear - 값이 있을 때 입력 지우기 아이콘을 항상 표시합니다.</p>
        <p>persistent-placeholder - 항상 placeholder를 표시하고 label이 자동으로 올라갑니다.</p>
        <br />
        <v-row align="center">
            <v-col cols="6" md="3">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" :items="items" persistent-hint hint="Hint" placeholder="persistent-hint"></v-select>
            </v-col>
            <v-col cols="6" md="3">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" :items="items" clearable persistent-clear placeholder="persistent-clear"></v-select>
            </v-col>
            <v-col cols="6" md="3">
                <v-select v-bind="{ ...attrs_select_common, ...$attrs }" :items="items" persistent-placeholder label="Label" placeholder="placeholder-placeholder"></v-select>
            </v-col>
        </v-row>
        <!-- E: Persistence -->
        <!-- // Supporting Text -->
    </div>
</template>

<script>
import { attrs_select_common } from "@/assets/variables";

import SelectPrimary from "@/components/publish/parents/forms/select-primary.vue";
import SelectSecondary from "@/components/publish/parents/forms/select-secondary.vue";
import SelectTertiary from "@/components/publish/parents/forms/select-tertiary.vue";
import SelectQuarternary from "@/components/publish/parents/forms/select-quarternary.vue";

import USelectOutlinedTile from "@/components/publish/styles/forms/u-select-outlined-tile.vue";
import USelectOutlinedRounded from "@/components/publish/styles/forms/u-select-outlined-rounded.vue";
import USelectOutlinedPill from "@/components/publish/styles/forms/u-select-outlined-pill.vue";
import USelectFilledTile from "@/components/publish/styles/forms/u-select-filled-tile.vue";
import USelectFilledRounded from "@/components/publish/styles/forms/u-select-filled-rounded.vue";
import USelectFilledPill from "@/components/publish/styles/forms/u-select-filled-pill.vue";
import USelectSoloTile from "@/components/publish/styles/forms/u-select-solo-tile.vue";
import USelectSoloRounded from "@/components/publish/styles/forms/u-select-solo-rounded.vue";
import USelectSoloPill from "@/components/publish/styles/forms/u-select-solo-pill.vue";
import USelectUnderlined from "@/components/publish/styles/forms/u-select-underlined.vue";

import UIcon from "@/components/publish/styles/icons/u-icon.vue";

export default {
    props: {},
    components: {
        SelectPrimary,
        SelectSecondary,
        SelectTertiary,
        SelectQuarternary,

        USelectOutlinedTile,
        USelectOutlinedRounded,
        USelectOutlinedPill,
        USelectFilledTile,
        USelectFilledRounded,
        USelectFilledPill,
        USelectSoloTile,
        USelectSoloRounded,
        USelectSoloPill,
        USelectUnderlined,

        UIcon,
    },
    data() {
        return {
            attrs_select_common,

            items: ["Select", "Select2", "Select3", "Select4"],
            value: ["Select", "Select2", "Select3", "Select4"],
            selectedItem: ["Select2"],
            selected: "Select3",
        };
    },
    mounted() {},
    computed: {
        allItem() {
            return this.selectedItem.length === this.items.length;
        },
        someItem() {
            return this.selectedItem.length > 0;
        },
    },
    methods: {
        toggle() {
            if (this.allItem) {
                this.selectedItem = [];
            } else {
                this.selectedItem = this.items.slice();
            }
        },
    },
};
</script>

<style lang="scss" scoped></style>
