<template>
    <u-btn-text-fit v-bind="$attrs" color="primary" @click="$emit('click')">
        <slot />
        <u-icon class="v-icon--right">check</u-icon>
    </u-btn-text-fit>
</template>

<script>
import UBtnTextFit from "@/components/publish/styles/buttons/u-btn-text-fit.vue";
import UIcon from "@/components/publish/styles/icons/u-icon.vue";

export default {
    props: {},
    components: {
        UBtnTextFit,
        UIcon,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
