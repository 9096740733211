<template>
    <app-primary>
        <v-container>
            <guide-common />

            <v-tabs v-model="tab" show-arrows center-active class="mt-60px mb-30px">
                <v-tab>Utilities</v-tab>
                <v-tab>Colors</v-tab>
                <v-tab>Typography</v-tab>
                <v-tab>Buttons</v-tab>
                <v-tab>Chips</v-tab>
                <v-tab>Icons</v-tab>
                <v-tab>Forms</v-tab>
                <v-tab>Tooltips</v-tab>
                <v-tab>Dividers</v-tab>
                <v-tab>Scrolldowns</v-tab>
                <v-tab>Paginations</v-tab>
                <v-tab>Ratings</v-tab>
                <v-tab>Swipers</v-tab>
                <v-tab>Tabs</v-tab>
                <v-tab>Tables</v-tab>
                <v-tab>Cards</v-tab>
                <v-tab>Menus</v-tab>
                <v-tab>Dialogs</v-tab>
                <v-tab>Groups</v-tab>
                <v-tab>Headers</v-tab>
                <v-tab>Footers</v-tab>
                <v-tab>SubHeads</v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
                <v-tab-item><Utilities /></v-tab-item>
                <v-tab-item><Colors /></v-tab-item>
                <v-tab-item><Typography /></v-tab-item>
                <v-tab-item><Buttons /></v-tab-item>
                <v-tab-item><Chips /></v-tab-item>
                <v-tab-item><Icons /></v-tab-item>
                <v-tab-item><Forms /></v-tab-item>
                <v-tab-item><Tooltips /></v-tab-item>
                <v-tab-item><Dividers /></v-tab-item>
                <v-tab-item><Scrolldowns /></v-tab-item>
                <v-tab-item><Paginations /></v-tab-item>
                <v-tab-item><Ratings /></v-tab-item>
                <v-tab-item><Swipers /></v-tab-item>
                <v-tab-item><Tabs /></v-tab-item>
                <v-tab-item><Tables /></v-tab-item>
                <v-tab-item><Cards /></v-tab-item>
                <v-tab-item><Menus /></v-tab-item>
                <v-tab-item><Dialogs /></v-tab-item>
                <v-tab-item><Groups /></v-tab-item>
                <v-tab-item><Headers /></v-tab-item>
                <v-tab-item><Footers /></v-tab-item>
                <v-tab-item><SubHeads /></v-tab-item>
            </v-tabs-items>
        </v-container>
    </app-primary>
</template>

<script>
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import GuideCommon from "./guide-common.vue";
import Utilities from "./utilities.vue";
import Colors from "./colors.vue";
import Typography from "./typography.vue";
import Buttons from "./buttons.vue";
import Chips from "./chips.vue";
import Icons from "./icons.vue";
import Forms from "./forms.vue";
import Tooltips from "./tooltips.vue";
import Dividers from "./dividers.vue";
import Scrolldowns from "./scrolldowns.vue";
import Paginations from "./paginations.vue";
import Ratings from "./ratings.vue";
import Swipers from "./swipers.vue";
import Tabs from "./tabs.vue";
import Tables from "./tables.vue";
import Cards from "./cards.vue";
import Menus from "./menus.vue";
import Dialogs from "./dialogs.vue";
import Groups from "./groups.vue";
import Headers from "./headers.vue";
import Footers from "./footers.vue";
import SubHeads from "./sub-heads.vue";

export default {
    components: {
        AppPrimary,
        GuideCommon,
        Utilities,
        Colors,
        Typography,
        Buttons,
        Chips,
        Icons,
        Forms,
        Tooltips,
        Dividers,
        Scrolldowns,
        Paginations,
        Ratings,
        Swipers,
        Tabs,
        Tables,
        Cards,
        Menus,
        Dialogs,
        Groups,
        Headers,
        Footers,
        SubHeads,
    },
    props: {},
    data() {
        return {
            tab: null,
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.row {
    margin: -6px;
    [class*="col"] {
        padding: 6px;
    }
}
</style>
