<template>
    <u-swiper-pagination-filled-outlined v-bind="$attrs" class="swiper-pagination--sm">
        <slot />
    </u-swiper-pagination-filled-outlined>
</template>

<script>
import USwiperPaginationFilledOutlined from "@/components/publish/styles/swipers/u-swiper-pagination-filled-outlined.vue";

export default {
    props: {},
    components: {
        USwiperPaginationFilledOutlined,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
