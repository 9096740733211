<template>
    <div>
        <!-- Parents -->
        <h1>Parents</h1>
        <p><u>tit-wrap-{parents}</u></p>
        <br />
        <v-row>
            <v-col cols="12" md="6">
                <tit-wrap-primary>제목을입력해주세요</tit-wrap-primary>
            </v-col>
            <v-col cols="12" md="6">
                <tit-wrap-secondary>
                    제목을입력해주세요
                    <template #TxtAdd>추가설명을입력해주세요</template>
                </tit-wrap-secondary>
            </v-col>
            <v-col cols="12" md="6">
                <tit-wrap-tertiary>
                    제목을입력해주세요
                    <template #TxtAdd>추가설명을입력해주세요</template>
                </tit-wrap-tertiary>
            </v-col>
            <v-col cols="12" md="6">
                <tit-wrap-quarternary>
                    <template #TitAdd>소제목을입력해주세요</template>
                    제목을입력해주세요
                </tit-wrap-quarternary>
            </v-col>
        </v-row>
        <!-- // Parents -->

        <v-divider class="my-30px" />

        <!-- Styles -->
        <h1>Styles</h1>
        <!-- S: Default -->
        <h2>Default</h2>
        <v-row>
            <v-col cols="12" md="6">
                <p><u>u-tit-wrap-default</u></p>
                <br />
                <u-tit-wrap-default>
                    <u-tit-default>제목을입력해주세요</u-tit-default>
                </u-tit-wrap-default>
            </v-col>
            <v-col cols="12" md="6">
                <p><u>u-tit-wrap-default</u></p>
                <br />
                <u-tit-wrap-default>
                    <u-tit-default>제목을입력해주세요</u-tit-default>
                    <u-txt-default>추가설명을입력해주세요</u-txt-default>
                </u-tit-wrap-default>
            </v-col>
        </v-row>
        <!-- E: Default -->
        <br />

        <!-- S: Line -->
        <h2>Line</h2>
        <v-row>
            <v-col cols="12" md="6">
                <p><u>u-tit-wrap-line</u></p>
                <br />
                <u-tit-wrap-line>
                    <u-tit-default>제목을입력해주세요</u-tit-default>
                </u-tit-wrap-line>
            </v-col>
            <v-col cols="12" md="6">
                <p><u>u-tit-wrap-line</u></p>
                <br />
                <u-tit-wrap-line>
                    <u-tit-default>제목을입력해주세요</u-tit-default>
                    <u-txt-default>추가설명을입력해주세요</u-txt-default>
                </u-tit-wrap-line>
            </v-col>
        </v-row>
        <!-- E: Line -->
        <br />

        <!-- S: Underline -->
        <h2>Underline</h2>
        <v-row>
            <v-col cols="12" md="6">
                <p><u>u-tit-wrap-underline</u></p>
                <br />
                <u-tit-wrap-underline>
                    <u-tit-default>제목을입력해주세요</u-tit-default>
                </u-tit-wrap-underline>
            </v-col>
            <v-col cols="12" md="6">
                <p><u>u-tit-wrap-underline</u></p>
                <br />
                <u-tit-wrap-underline>
                    <u-tit-default>제목을입력해주세요</u-tit-default>
                    <u-txt-default>추가설명을입력해주세요</u-txt-default>
                </u-tit-wrap-underline>
            </v-col>
        </v-row>
        <!-- E: Underline -->
        <!-- // Styles -->

        <v-divider class="my-30px" />

        <!-- Size -->
        <h1>Size</h1>
        <h3>{size} : sm, (기본), lg</h3>
        <p><u>tit-wrap--{size}</u></p>
        <br />
        <tit-wrap class="tit-wrap--sm">
            <tit>제목을입력해주세요</tit>
        </tit-wrap>
        <tit-wrap>
            <tit>제목을입력해주세요</tit>
        </tit-wrap>
        <tit-wrap class="tit-wrap--lg">
            <tit>제목을입력해주세요</tit>
        </tit-wrap>
        <!-- // Size -->
    </div>
</template>

<script>
import GuideApp from "./guide-app.vue";

import TitWrapPrimary from "@/components/publish/parents/typography/tit-wrap-primary.vue";
import TitWrapSecondary from "@/components/publish/parents/typography/tit-wrap-secondary.vue";
import TitWrapTertiary from "@/components/publish/parents/typography/tit-wrap-tertiary.vue";
import TitWrapQuarternary from "@/components/publish/parents/typography/tit-wrap-quarternary.vue";
import TitPrimary from "@/components/publish/parents/typography/tit-primary.vue";
import TitSecondary from "@/components/publish/parents/typography/tit-secondary.vue";
import TitTertiary from "@/components/publish/parents/typography/tit-tertiary.vue";
import TitQuarternary from "@/components/publish/parents/typography/tit-quarternary.vue";
import TxtPrimary from "@/components/publish/parents/typography/txt-primary.vue";
import TxtSecondary from "@/components/publish/parents/typography/txt-secondary.vue";
import TxtTertiary from "@/components/publish/parents/typography/txt-tertiary.vue";
import TxtQuarternary from "@/components/publish/parents/typography/txt-quarternary.vue";

import UTitWrapDefault from "@/components/publish/styles/typography/u-tit-wrap-default.vue";
import UTitWrapLine from "@/components/publish/styles/typography/u-tit-wrap-line.vue";
import UTitWrapUnderline from "@/components/publish/styles/typography/u-tit-wrap-underline.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTitSubtit from "@/components/publish/styles/typography/u-tit-subtit.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";

import TitWrap from "@/components/publish/styles/typography/tit-wrap.vue";
import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";

export default {
    props: {},
    components: {
        GuideApp,

        TitWrapPrimary,
        TitWrapSecondary,
        TitWrapTertiary,
        TitWrapQuarternary,
        TitPrimary,
        TitSecondary,
        TitTertiary,
        TitQuarternary,
        TxtPrimary,
        TxtSecondary,
        TxtTertiary,
        TxtQuarternary,

        UTitWrapDefault,
        UTitWrapLine,
        UTitWrapUnderline,
        UTitDefault,
        UTitSubtit,
        UTxtDefault,
        UTxtMedium,

        TitWrap,
        Tit,
        Txt,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
