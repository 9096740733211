<template>
    <div>
        <v-tabs v-model="tab" grow class="mb-30px">
            <v-tab>Control</v-tab>
            <v-tab>Text field</v-tab>
            <v-tab>Textarea</v-tab>
            <v-tab>File input</v-tab>
            <v-tab>Select</v-tab>
        </v-tabs>

        <v-window v-model="tab">
            <v-window-item><FormsControl /></v-window-item>
            <v-window-item><FormsTextField /></v-window-item>
            <v-window-item><FormsTextarea /></v-window-item>
            <v-window-item><FormsFileInput /></v-window-item>
            <v-window-item><FormsSelect /></v-window-item>
        </v-window>
    </div>
</template>

<script>
import FormsControl from "./forms-control.vue";
import FormsTextField from "./forms-text-field.vue";
import FormsTextarea from "./forms-textarea.vue";
import FormsFileInput from "./forms-file-input.vue";
import FormsSelect from "./forms-select.vue";

export default {
    props: {},
    components: {
        FormsControl,
        FormsTextField,
        FormsTextarea,
        FormsFileInput,
        FormsSelect,
    },
    data() {
        return {
            tab: null,
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
