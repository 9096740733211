<template>
    <u-scrolldown-mouse v-bind="$attrs" arrow>
        <template v-slot:arrow>
            <icon-arrow-rounded size="small" direction="down" />
        </template>
    </u-scrolldown-mouse>
</template>

<script>
import UScrolldownMouse from "@/components/publish/styles/scrolldowns/u-scrolldown-mouse.vue";
import IconArrowRounded from "@/components/publish/styles/icons/icon-arrow-rounded.vue";

export default {
    props: {},
    components: {
        UScrolldownMouse,
        IconArrowRounded,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
