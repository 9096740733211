import { render, staticRenderFns } from "./u-swiper-pagination-around-outlined.vue?vue&type=template&id=147b9959&scoped=true&"
import script from "./u-swiper-pagination-around-outlined.vue?vue&type=script&lang=js&"
export * from "./u-swiper-pagination-around-outlined.vue?vue&type=script&lang=js&"
import style0 from "./u-swiper-pagination-around-outlined.vue?vue&type=style&index=0&id=147b9959&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "147b9959",
  null
  
)

export default component.exports