<template>
    <swiper-btn-wrap v-bind="$attrs" class="swiper-btn-wrap--bottom swiper-btn-wrap--right">
        <slot />
    </swiper-btn-wrap>
</template>

<script>
import SwiperBtnWrap from "@/components/publish/styles/swipers/swiper-btn-wrap.vue";

export default {
    props: {},
    components: {
        SwiperBtnWrap,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
