<template>
    <div>
        <!-- Parents -->
        <h1>Parents</h1>
        <p><u>header-{parents}</u></p>
        <br />
        <v-row align="center">
            <v-col cols="12">
                <header-primary></header-primary>
            </v-col>
        </v-row>
        <!-- // Parents -->

        <v-divider class="my-30px" />

        <!-- Fixed -->
        <h1>Fixed</h1>
        <p>스크롤시에도 헤더가 상단에 위치합니다.</p>
        <p><u>u-header-fixed</u></p>
        <!-- // Fixed -->
    </div>
</template>

<script>
import HeaderPrimary from "@/sets/parents/headers/header-primary.vue";

export default {
    props: {},
    components: {
        HeaderPrimary,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
// 가이드 작업을 위해 넣어 놓은 스타일입니다.
::v-deep {
    .header {
        position: relative !important;
    }
}
</style>
