var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "v-rating-wrap"
  }, [_c('v-rating', _vm._b({
    attrs: {
      "half-increments": "",
      "color": "primary",
      "background-color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('click');
      }
    }
  }, 'v-rating', _vm.$attrs, false)), this.$slots['default'] ? _c('span', {
    staticClass: "v-rating-wrap__txt"
  }, [_vm._t("default")], 2) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }