var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-primary', {
    scopedSlots: _vm._u([{
      key: "contentsHead",
      fn: function () {
        return [_c('sub-head-primary', {
          attrs: {
            "itemTitle": "가이드",
            "tabActive": "예시페이지",
            "bg": "/images/test.jpg"
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_vm._v("가이드")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('page-section-primary', {
    staticClass: "page-section--first",
    attrs: {
      "titWrapClass": "text-center"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v(" 섹션 타이틀 ")];
      },
      proxy: true
    }])
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('btn-primary', {
    staticClass: "v-btn--width-fixed",
    attrs: {
      "to": "/guide"
    }
  }, [_vm._v("Guide")])], 1)], 1), _vm._v(" 컨텐츠영역 ")], 1), _c('page-section-secondary', {
    staticClass: "grey lighten-5",
    attrs: {
      "titWrapClass": "text-center"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v(" 섹션 타이틀 ")];
      },
      proxy: true
    }])
  }, [_vm._v(" 컨텐츠영역 ")]), _c('page-section-primary', {
    staticClass: "page-section--last primary",
    attrs: {
      "titWrapClass": "text-center"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v(" 섹션 타이틀 ")];
      },
      proxy: true
    }])
  }, [_vm._v(" 컨텐츠영역 ")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }