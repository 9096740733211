<template>
    <u-swiper-pagination-line-connected v-bind="$attrs">
        <slot />
    </u-swiper-pagination-line-connected>
</template>

<script>
import USwiperPaginationLineConnected from "@/components/publish/styles/swipers/u-swiper-pagination-line-connected.vue";

export default {
    props: {},
    components: {
        USwiperPaginationLineConnected,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
